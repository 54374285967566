
  <div class="logout">
    <div *ngIf="data.isShowLoadingIcon" class="w-100 text-center">
      <img src="assets/icons/svg/pending.svg" width="100" alt="">
    </div>
    <p>{{data.msg}}</p>
    <div class="btn text-center w-100 d-flex justify-content-end p-0 m-0">
      <button *ngIf="!data.hideNoBtn" mat-raised-button color="accent" class="me-2" (click)="onSubmit('no')">{{data?.cancelBtnLabel ? data.cancelBtnLabel : 'No'}}</button>
      <button mat-raised-button color="primary" class="ms-2" (click)="onSubmit('yes')">{{data?.confirmBtnLabel ? data.confirmBtnLabel : 'Yes'}}</button>
    </div>
  </div>
