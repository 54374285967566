<div class="notify-criteria-container">
  <div class="row">
    <div class="col-10 align-self-center">
      <h6 class="dialog-header pt-2">Select notification criteria</h6>
    </div>
    <div class="col-2">
      <button class="btn-close cursor-pointer" (click)="closeModal()"></button>
    </div>
  </div>

  <form [formGroup]="notifyCriteriaForm" class="nc-form">
    <div class="row">

      <div class="col-md-6 border-right">
        <div class="row gap-3">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Notify staff with in radius (Miles)</mat-label>
              <input matInput formControlName="notify_radius" maxlength="6">

              <mat-error class="help-block err"
                *ngIf="notifyCriteriaForm.get('notify_radius').errors?.pattern && notifyCriteriaForm.get('notify_radius').touched">
                Should be valid number
              </mat-error>

            </mat-form-field>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>#To be notified</mat-label>
              <input matInput formControlName="to_be_notify" maxlength="3">

              <mat-error class="help-block err"
                *ngIf="notifyCriteriaForm.get('to_be_notify').errors?.pattern && notifyCriteriaForm.get('to_be_notify').touched">
                Should be valid number
              </mat-error>
            </mat-form-field>
          </div>
         
        </div>
       </div>

      <div class="col-md-6">
        <div class="row">
        
       <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
            <mat-form-field appearance="outline" floatLabel="always" class="example-full-width custom-search">
              <mat-label>Distribution</mat-label>
              <mat-select formControlName="distribution" multiple (selectionChange)="onDistributionChanged($event)">
                <mat-option>
                  <ngx-mat-select-search [placeholderLabel]="'Search Here...'"
                    [noEntriesFoundLabel]="'Record not found'"
                    [formControl]="distributionSeachInput"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let option of filteredDistributions" [value]="option.distributionCode">
                  {{option.distributionName }}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center m-1">
                OR
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
          <staff-list-multi-select
           [placeholder]="''"
           [ctrl_staff]="notifyCriteriaForm.controls.ctrl_staff"
           (selectionChanged)="getSelectedStaff($event)">
          </staff-list-multi-select>
          </div>

        </div>
      </div>

      <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <mat-checkbox formControlName="self_signup">
                    Self Signup
                </mat-checkbox>
            </div> -->

    </div>
  </form>

  <div class=" footer mt-3 d-flex offset-md-8 justify-content-end">
    <button class="me-3" mat-raised-button color="primary" (click)="save()">
      Notify
    </button>
  </div>

</div>