import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StStaffDetailComponent } from './st-staff-detail/st-staff-detail.component';
import { MatIconModule } from '@angular/material/icon';
import { NotifyCriteriaFormComponent } from './notify-criteria-form/notify-criteria-form.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StaffListSelectComponent } from '../components/staff-list-select/staff-list-select.component';
import { StaffListMultiSelectComponent } from './staff-list-multi-select/staff-list-multi-select.component';



@NgModule({
  declarations: [
    StStaffDetailComponent,
    NotifyCriteriaFormComponent,
    StaffListSelectComponent,
    StaffListMultiSelectComponent,
    StaffListMultiSelectComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    NgxMatSelectSearchModule
  ],
  exports :[
    StStaffDetailComponent,
    NotifyCriteriaFormComponent,
    StaffListSelectComponent,
    StaffListMultiSelectComponent
  ]
})
export class SharedComponentsModule { }
