<div class="container-fluid main-content-bg">
  
  <div class="row">
    <div class="gig-loader" *ngIf="common?.progressLoader">
      <img *ngIf="!common?.percentageLoader" src="assets/gig_loader.png" alt="">
      <img *ngIf="common?.percentageLoader" src="assets/loader-percentage.gif" alt="">
      <p *ngIf="common?.progressText">{{common?.progressText}}</p>
    </div>

    <div class="col p-0 side-nav-w" [class.isMenuOpen]="isMenuOpen" [class.isMobileMenuOpen]="isMobileMenuOpen" [class.isMobileMenuClose]="!isMobileMenuOpen" [class.isMenuClose]="userType !== 'Staff' && userType !== 'Customer' && !isWebAppPermission">
      <!-- <div class="w-100 sticky-header">
        <app-header></app-header>
      </div> -->
      <ng-container *ngIf="userType === 'Staff' || userType == 'Customer'">
        <mat-icon class="menuToggleIc" [class.menuTogglePos]="!isMenuOpen"  (click)="toggleSidebarMenu()" aria-hidden="false" fontIcon="menu"></mat-icon>
        <mat-icon class="menuToggleIc1" (click)="toggleSidebarMenuMobile()" aria-hidden="false" fontIcon="menu"></mat-icon>
        <div class="w-100 menu-content" [class.isMobileMenuOpen]="isMobileMenuOpen" [class.isMobileMenuClose]="!isMobileMenuOpen">
          <app-my-nav
          [toggleMenuVal]="isMenuOpen"
          [isMobileMenuOpen]="isMobileMenuOpen"
          (evt_mobileCloseMenu)="toggleSidebarMenuMobile()">
          </app-my-nav>
        </div>
      </ng-container>
      <ng-container *ngIf="userType !== 'Staff' && userType !== 'Customer' && isWebAppPermission">
        <mat-icon class="menuToggleIc" [class.menuTogglePos]="!isMenuOpen" (click)="toggleSidebarMenu()" aria-hidden="false" fontIcon="menu"></mat-icon>
        <mat-icon class="menuToggleIc1" (click)="toggleSidebarMenuMobile()" aria-hidden="false" fontIcon="menu"></mat-icon>
        <div class="w-100 menu-content" [class.isMobileMenuOpen]="isMobileMenuOpen" [class.isMobileMenuClose]="!isMobileMenuOpen">
          <app-my-nav
          [toggleMenuVal]="isMenuOpen"
          [isMobileMenuOpen]="isMobileMenuOpen"
          (evt_mobileCloseMenu)="toggleSidebarMenuMobile()">
          </app-my-nav>
        </div>
      </ng-container> 
    </div>

    <div class="col p-0 main-content-container" [class.isMenuOpen]="isMenuOpen" [class.isMobileMenuClose]="!isMobileMenuOpen"  [class.isMobileMenuOpen]="isMobileMenuOpen" [class.isMenuClose]="userType !== 'Staff' && userType !== 'Customer' && !isWebAppPermission">
      
      <ng-template [ngIf]="common?.successMessage">
      <div class="success message">
        <div class="messageBody" autoClose [style.width]="common?.success_warning_error_BodyWidth">
          <div class="message-logo">
            <a class="btn-close cursor-pointer float-end" (click)="closeSuccess()"></a>
            <div class="mat-ico m-1"> <i class="material-icons success-logo">
                done </i> </div>
            <span color="black">Success!</span>
          </div>
          <div class="text-center success-msg ps-2 pe-2">
            <small>{{common?.message}}</small>
          </div>
          <button mat-raised-button class="cursor-pointer mt-4 mb-4" (click)="closeSuccess()">Ok</button>
        </div>
      </div>
      </ng-template>

      <ng-template [ngIf]="common?.warningMessage">
        <div class="success message">
          <div class="alertBody" autoClose [style.width]="common?.success_warning_error_BodyWidth">
            <div class="message-logo">
              <a class="btn-close cursor-pointer float-end" (click)="closeWarning()"></a>
              <div class="mat-ico m-1"> <i class="material-icons warning-logo">
                report_problem </i> </div>
              <span color="black">Alert!</span>
            </div>
            <div class="text-center success-msg ps-2 pe-2">
              <small>{{common?.message}}</small>
            </div>
            <button mat-raised-button class="cursor-pointer mt-4 mb-4" (click)="closeWarning()">Ok</button>
          </div>
        </div>
        </ng-template>

      <ng-template [ngIf]="common?.errorMessage">
      <div class="success message">
        <div class="err-msg-body" autoClose [style.width]="common?.success_warning_error_BodyWidth">
          <div class="message-logo">
            <a class="btn-close cursor-pointer float-end" (click)="closeError()"></a>
            <div class="mat-ico m-1 text-center"> <i class="material-icons
                err-logo"> clear </i> </div>
            <p class="display-6" style="font-size: 16px;">Error!</p>
          </div>
          <div class="text-center success-msg ps-2 pe-2">
            <small>{{common?.message}}</small>
          </div>
          <button mat-raised-button class="cursor-pointer mt-4 mb-4" (click)="closeError()">Ok</button>
        </div>
      </div>
    </ng-template>

      <div class="w-100">
        <div class="padd-section">

          <div class="cust-flex">

            <div class="header">
              <app-header></app-header>
            </div>

            <!-- [class.cl-fullView]="!(router.url !== '/dashboard'
         && userType?.toLowerCase() !== 'staff' &&
          userType?.toLowerCase() !== 'customer' )" -->

          <!-- [class.cl-fullView]="!(userType?.toLowerCase() !== 'staff' &&
          userType?.toLowerCase() !== 'customer' )" -->

         <div class="card-look">
            <router-outlet></router-outlet>
         </div>

         <ng-template [ngIf]="userType?.toLowerCase() !== 'staff' &&
           userType?.toLowerCase() !== 'customer' ">
          <div class="footer-shortcuts">
            <app-short-cuts [iconsGrid]="true"></app-short-cuts>
          </div>
          </ng-template>

          </div>
              
        </div>          
      </div> 
      
      
  <!-- <div class="shortCuts-sticky-footer">
    <app-short-cuts></app-short-cuts>
  </div> -->
     

      <!-- <ng-template [ngIf]="controlPermission.viewPermission === true">
      <button *ngIf="common?.organizationStatus !== 'Pending'" mat-fab color="primary" title="Chat" class="filter-icon forChat"
        (click)="showChat()">
        <span class="globalchatcount" *ngIf="common?.globalChatCount > 0">{{common?.globalChatCount}}</span>
        <i class="material-icons"> chat </i>
      </button>
      </ng-template> -->

    </div>
  </div>
 

</div>


 