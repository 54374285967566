import { Component, OnInit, ViewChild, ElementRef, Inject, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
// import { RxStompService } from "@stomp/ng2-stompjs";
import { RxStompService } from 'src/app/rx-stomp.service';
import { ChatService } from './chat.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatGroupComponent } from './chat-group/chat-group.component';
//import { BroadcastMessageComponent } from '../broadcast-message/broadcast-message.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment-timezone';
import { DialogData } from '../skills/skill-list/skill-list.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() recivedDatafromPage: any;
  dialogReceived: any;

  public receivedMessages: string[] = [];
  // data;
  messages = [];
  chatForm: FormGroup;
  submitted = true;
  orgCode: any;
  adminCode: any;
  allMarshals = [];
  searchallMarshals = [];
  selectedMarshal;
  newMessage;
  chatData = [];
  @ViewChild('msg', { static: true }) msg;
  topicName = 'chat_';
  page = 0;
  limit = 20;
  count = 0;
  searchedMarshal = [];
  searchVal;
  searchCount;
  selectedId;
  msgIds = [];
  userType;
  marshalName;
  showPopupId;
  isTypeing = false;
  typingName: any;
  groupIcon: any;
  groupsIconAll: any;
  messageLoad = false;

  private topicChatSubscription: Subscription;
  private destroyObs = new Subject<string>();

  constructor(
    private rxStompService: RxStompService,
    public service: ChatService,
    public dialogRef: MatDialogRef<ChatComponent>,
    public dialog: MatDialog,
    public translate: TranslateService,
    public common: CommonService,
    public session: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.translate.setDefaultLang(this.common.local.get('language'));
  }


  ngAfterViewInit(): void {


    /**
     * for mobile view 
     */
    // const toggleButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.toggle-chat-btn');
    // const leftChatContainer: HTMLElement | null = document.querySelector('.left-chat-container');
    // const rightChatContainer: HTMLElement | null = document.querySelector('.right-chat-container');
    // const openChat : HTMLElement | null =  document.querySelector('.mobile-view-open-chat');

    // toggleButtons.forEach((toggleButton: HTMLButtonElement) => {
    //   toggleButton.addEventListener('click', () => {
    //     if (leftChatContainer) {
    //       leftChatContainer.classList.toggle('show');
    //     }
 
    //     if (rightChatContainer) {
    //       rightChatContainer.classList.toggle('hide');
    //     }
    //     toggleButtons.forEach((btn: HTMLButtonElement) => {
    //       btn.classList.toggle('left-chat-toggle');
    //     });
    //   });
    // });


  }


  ngOnInit() {
    if (this.data) {
      this.dialogReceived = this.data && (JSON.parse(JSON.stringify(this.data)));
    }

    if (this.recivedDatafromPage) {
      //console.log('RecivedDatafromPage_1: ', this.recivedDatafromPage)
      this.data = this.recivedDatafromPage;
    }

    ////console.log('chat');
    //console.log(this.data);
    //console.log('RecivedDatafromPage_2: ', this.data)
    this.topicSub();
    if (this.common.allGroupResponse && this.common.allGroupResponse && this.common.allGroupResponse.responsecode == 200) {
      this.getLoadAllData(this.common.allGroupResponse);
    } else {
      this.getGroups();
    }
    this.chatForm = new FormGroup({
      messages: new FormControl(null)
    });
    this.userType = this.session.get('user_type')
    this.getIsTyping();
    // ////console.log(this.msg);
  }

  /* Name:Shailesh
 use: for getting all department*/
  getIsTyping() {
    try {
      this.rxStompService.watch('/topic/isTyping').subscribe((message) => {
        ////console.log(message);
        let body = JSON.parse(message.body);
        ////console.log(body);
        const index = this.allMarshals.findIndex((data) => data.groupDetailsCode == body.groupCode);
        ////console.log(index, body.fromName);
        if (index !== -1 && this.session.get('userCode') !== body.from) {
          this.allMarshals[index].isTyping = true;
        }
        if (body.groupCode == this.selectedMarshal && this.session.get('userCode') !== body.from && body.message !== '') {
          ////console.log('isTypeing!!!!');
          this.isTypeing = true;
          this.typingName = body.fromName;
        } else {
          this.isTypeing = false;
          this.typingName = null;
        }

        setTimeout(() => {
          this.isTypeing = false;
          this.typingName = null;
          if (index !== -1) {
            this.allMarshals[index].isTyping = false;
          }
        }, 5000);
      });
    } catch (error) {
      //console.log(error)
    }

  }

  onKeyUp(event) {
    // ////console.log(event.target.value, this.session.get('userCode'), this.selectedMarshal);
    this.rxStompService.publish({ destination: '/topic/isTyping', body: JSON.stringify({ type: 'Dashboard', groupCode: this.selectedMarshal, from: this.session.get('userCode'), fromName: this.session.get('username'), orgCode: this.session.get('orgCode'), message: event.target.value }) });

  }


  getGroups() {
    this.orgCode = this.session.get('orgCode');
    this.adminCode = this.session.get('super_admin_code');
    // let groupUrl;
    if (this.data && this.data['arrayList']) {
      this.getLoadAllData(this.data['arrayList']);
    }
    else {
      try {
        this.common.progressLoader = true;
        this.service.adminGetAllGroupByOrganizationCode(this.orgCode, this.adminCode, (response) => {
          //console.log(response);
          this.common.progressLoader = false;
          this.getLoadAllData(response);
        })
      } catch (error) {
        this.common.progressLoader = false;
        //console.log(error)
      }

    }

  }


  /* Name:Shailesh
 use: for getting all groups by org code*/
  // getGroups() {
  //   this.orgCode = this.session.get('orgCode');
  //   this.adminCode = this.session.get('userCode');
  //   let groupUrl;
  //   if (this.data && this.data['groupDetailsCode']) {
  //     //console.log(this.data,'line 130!!!');
  //     // this.getLoadAllData(this.data['arrayList']);

  //     groupUrl = this.adminCode + '/' + this.data['groupDetailsCode'];
  //     try {
  //       this.service.adminGetAllGroupByOrganizationCode(this.orgCode, groupUrl, (response) => {
  //       ////console.log(response);
  //       this.getLoadAllData(response);
  //     })
  //     } catch (error) {
  //       //console.log(error)
  //     }

  //   } else if (this.data && this.data['arrayList']) {
  //     this.getLoadAllData(this.data['arrayList']);
  //   } else {
  //     groupUrl = this.adminCode;
  //     try {
  //       this.service.adminGetAllGroupByOrganizationCode(this.orgCode, groupUrl, (response) => {
  //       //console.log(response);
  //       this.getLoadAllData(response);
  //     }) 
  //     } catch (error) {
  //       //console.log(error)
  //     }

  //   }

  // }

  /* Name:Shailesh
 use: for getting all clinician list*/
  getLoadAllData(response) {
    //console.log(response,'line 163!!')
    if (response.responsecode == 200) {

      response.data.forEach(element => {
        element.image = element.groupIcon !== null ? this.service.service.getSuperAdmin() + '/downloadFile/' + element.groupIcon : element.groupIcon;

      });

      this.allMarshals = response.data;
      this.messageLoad = true;
      this.searchallMarshals = this.allMarshals;
      this.searchedMarshal = this.allMarshals;

      // this.searchallMarshals = response.data;
      // this.searchedMarshal = response.data;

      // if (this.data && this.data['groupDetailsCode']) {
      //   const index = response.data.findIndex(groups => groups.groupDetailsCode == this.data['groupDetailsCode']);
      //   ////console.log(index);
      //   if (index !== -1) {
      //     this.openChat(response.data[index]);
      //   }
      // } 

      if (this.dialogReceived?.groupDetailsCode && this.allMarshals) {
        const openChat = this.allMarshals.find(item => item.groupDetailsCode === this.dialogReceived.groupDetailsCode);
        this.openChat(openChat);
      } else {

        if (this.data && this.data['user_or_worker_code']) {
          // const index = response.data.findIndex(groups => groups.groupDetailsCode == this.data['groupDetailsCode']);        
          const index = response.data.findIndex(outerEle =>
            !outerEle.isGroup && outerEle.groupDetailMembersList.find(innerEle => innerEle.userCode === this.data['user_or_worker_code'])
          );

          if (index !== -1) {
            this.openChat(response.data[index]);
            this.allMarshals.unshift(this.allMarshals.splice(index, 1)[0]);
          } else {
            this.openChat(response.data[0]);
          }
        } else {
          this.openChat(response.data[0]);
        }
      }
    } else {
      this.allMarshals = [];
      this.messageLoad = true;
    }
  }

  /* Name:Shailesh
 use: to send message*/
  onSendMessage() {
    ////console.log(this.msg);
    ////console.log(this.chatForm);
    if (this.chatForm.value.messages && this.chatForm.value.messages != '') {
      this.submitted = false;
      const message = this.chatForm.value.messages;
      ////console.log(this.chatForm.value.messages);
      // let topicName = this.selectedMarshal;

      var today = new Date();
      var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + ' ' + time;

      dateTime = moment(dateTime).utc().format('YYYY-MM-DD HH:mm');
      ////console.log(dateTime);

      let isAdmin;

      if (this.userType == 'Health Technician') {
        isAdmin = false;
      } else {
        isAdmin = true;
      }

      // const admin_code : string = this.session.get('userCode')
      const admin_code: string = this.session.get('super_admin_code') || '';

      const data = {
        "from": admin_code,
        "fromName": this.session.get('username'),
        "groupCode": this.selectedMarshal,
        "isAdmin": isAdmin,
        "message": this.chatForm.value.messages,
        "isApp": false
      }

      try {
        this.service.saveMessage(data, (res) => {
          if (res.responsecode == 200) {
            this.rxStompService.publish(
              {
                destination: '/topic/' + this.topicName,
                body: JSON.stringify(
                  {
                    message: message,
                    type: 'Dashboard',
                    groupCode: this.selectedMarshal,
                    isAdmin: isAdmin,
                    from: this.session.get('super_admin_code'),
                    messageTime: dateTime,
                    isRead: false,
                    fromName: this.session.get('username'),
                    id: res.data.id,
                    orgCode: this.session.get('orgCode'),
                    fromDashboard: true
                  })
              });
            // res.data.finalDate = moment.utc(res.data.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A');

            if (this.session.get('timeZone')) {
              res.data.finalDate = moment.utc(res.data.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A');
            }
            else {
              res.data.finalDate = moment.utc(res.data.messageTime).format('YYYY-MM-DD hh:mm A');
            }


            res.data.message = message;
            this.chatData.push(res.data);
            setTimeout(() => {
              var objDiv = document.getElementById("chats");
              objDiv.scrollTop = objDiv.scrollHeight;
            })
          } else {
            this.common.message = res.message;
            this.common.errorMessage = true;
          }
        })
      } catch (error) {
        //console.log(error);
      }


      this.chatForm = new FormGroup({
        messages: new FormControl(null)
      });
    } else {
      this.submitted = false;
    }
  }


  /* Name:Shailesh
 use: for getting message*/
  topicSub() {
    // console.log("from chat comp sub start", '/topic/' + this.topicName + this.session.get('userCode'));
    try {
      //console.log("chat subs", '/topic/' + this.topicName + this.session.get('super_admin_code'));

      this.topicChatSubscription = this.rxStompService.watch('/topic/' + this.topicName + this.session.get('super_admin_code'))
        .pipe(takeUntil(this.destroyObs)).subscribe((message) => {
          //console.log(message);
          ////console.log(this.selectedMarshal);
          let body = JSON.parse(message.body);
          // console.log("from chat comp sub data", '/topic/' + this.topicName + this.session.get('userCode'));

          // body.finalTime = moment.utc(body.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A');
          body.finalTime = this.session.get('timeZone') ?
            moment.utc(body.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A')
            : moment.utc(body.messageTime).format('YYYY-MM-DD hh:mm A');

          let isAdmin;

          if (this.userType == 'Health Technician') {
            isAdmin = body.isAdmin;
          } else {
            isAdmin = !body.isAdmin;
          }
          if (body.groupCode == this.selectedMarshal && isAdmin) {
            this.isTypeing = false;
            this.typingName = null;

            // body.finalDate = moment.utc(body.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A');
            ////console.log(body);

            body.finalDate = this.session.get('timeZone') ?
              moment.utc(body.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A')
              : moment.utc(body.messageTime).format('YYYY-MM-DD hh:mm A');

            const index = this.groupsIconAll.findIndex(groupData => groupData.userCode === body.from);
            if (index !== -1) {
              body.image = this.groupsIconAll[index].userIcon !== null ? this.service.service.getSuperAdmin() + '/downloadFile/' + this.groupsIconAll[index].userIcon : this.groupsIconAll[index].userIcon;
              body.fromName = this.groupsIconAll[index].userName;
            }

            this.chatData.push(body);
            setTimeout(() => {
              var objDiv = document.getElementById("chats");
              objDiv.scrollTop = objDiv.scrollHeight;
            });
            this.updateMsg(body.id, true);
          } else {
            const updateCount = this.allMarshals.findIndex(element => element.groupDetailsCode == body.groupCode);
            ////console.log(updateCount);
            ////console.log(this.selectedMarshal);
            ////console.log(body.groupCode);
            if (updateCount != -1 && this.selectedMarshal != body.groupCode) {
              this.allMarshals[updateCount].unReadCount++;
              ////console.log(this.allMarshals);
            }
          }
          setTimeout(() => {
            this.allMarshals.forEach((element) => {
              if (element.groupDetailsCode == body.groupCode) {
                element.lastMessage = body.message;
                element.lastMessageDateTime = body.finalTime.split(' ')[1] + ' ' + body.finalTime.split(' ')[2];
              }
            });
          }, 500);
        });
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Vivek Chauhan
  use: to open selected user popup by using user object*/
  openChat(user) {
    // viv
    // console.log('OpenSelectedChat:', user);
    this.marshalName = user.groupName;
    this.selectedMarshal = user.groupDetailsCode;
    this.newMessage = '';
    this.groupIcon = user.groupIcon !== null ? user.image : user.groupIcon;
    this.groupsIconAll = user.groupDetailMembersList;
    this.isTypeing = false;
    this.typingName = null;
    this.resetSearch(null);

  
    this.toggleChat();  // for mobile view opening chat;

    ////console.log(this.msg);
    setTimeout(() => {
      if (this.dialogReceived?.groupDetailsCode) {
        this.page = 0;
        this.limit = 20;
        this.getChat(this.page, this.limit);
        this.scrollToSelectedChat(user);
        this.dialogReceived = null;
      }
      user.unReadCount = 0;
      document.getElementById(this.selectedMarshal).focus();
    }, 500);
  }

  scrollToSelectedChat(chatItem) {
    // console.log('ScrollToSelectedChat', chatItem);
    if (chatItem) {
      window.document.getElementById('chat_id_' + chatItem.groupDetailsCode).scrollIntoView();
    }
  }

  /* Name:Shailesh
 use: for getting chat list of selected clinician*/
  getChat(page, limit, scroll = null, search = null, id = null) {
    // console.log('SelectedMarshal: ', this.selectedMarshal);
    try {
      this.service.getMessageListByGroupCodeAndUserCode(this.selectedMarshal, this.session.get('userCode'), search, id, page, limit, (data) => {
        if (data.responsecode == 200) {
          ////console.log(data.data);
          if (scroll) {
            let arrayData = this.chatData;
            data.data.msgResList.reverse();
            data.data.msgResList.forEach(element => {
              const index = this.groupsIconAll.findIndex(groupData => groupData.userCode === element.from);
              if (index !== -1) {
                element.image = this.groupsIconAll[index].userIcon !== null ? this.service.service.getSuperAdmin() + '/downloadFile/' + this.groupsIconAll[index].userIcon : this.groupsIconAll[index].userIcon;
                element.fromName = this.groupsIconAll[index].userName;
              }
              // element.finalDate = moment.utc(element.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A');

              if (this.session.get('timeZone') && element?.messageTime) {
                //console.log(moment.utc(element?.messageTime).tz(getLocalTimeZone).format('YYYY-MM-DD hh:mm A'))
                element.finalDate = moment.utc(element?.messageTime).tz(this.session.get('timeZone')).format('YYYY-MM-DD hh:mm A');
              }

              arrayData.unshift(element);
            });

            setTimeout(() => {
              this.chatData = arrayData;
              //console.log(this.chatData);
              document.getElementById(data.data.msgResList[0].id).scrollIntoView({ behavior: 'smooth' });

              this.msgIds.forEach((id) => {
                const otherHigh = document.getElementById(id);
                if (otherHigh != null) {
                  if (!otherHigh.classList.contains('span')) {
                    var inputText = document.getElementById(id);
                    ////console.log(inputText);
                    if (inputText != null) {
                      var innerHTML = inputText.innerHTML;
                      var innerHTMLOg = inputText.innerHTML.toLowerCase();
                      var index = innerHTMLOg.indexOf(this.searchVal.toLowerCase());
                      ////console.log(this.searchVal);
                      ////console.log(innerHTML);
                      ////console.log(index);
                      ////console.log(this.searchVal.length);
                      if (index >= 0) {
                        innerHTML = innerHTML.substring(0, index) + "<span class='highlight' >" + innerHTML.substring(index, index + this.searchVal.length) + "</span>" + innerHTML.substring(index + this.searchVal.length);
                        inputText.innerHTML = innerHTML;
                      }
                    }
                  }
                }
              })
            }, 1000);

          } else {
            this.chatData = [];
            this.chatData = data.data.msgResList;
            this.count = data.data.count;
            this.chatData.forEach((element) => {
              const index = this.groupsIconAll.findIndex(groupData => groupData.userCode === element.from);
              //console.log(index);
              if (index !== -1) {
                element.image = this.groupsIconAll[index].userIcon !== null ? this.service.service.getSuperAdmin() + '/downloadFile/' + this.groupsIconAll[index].userIcon : this.groupsIconAll[index].userIcon;
                element.fromName = this.groupsIconAll[index].userName;
              }
              // //console.log('GetEachChat: ', element.id, element);
              if (element?.isRead === false || element?.isRead === 'false') {
                // //console.log('SetAsReadChat: ', element.id, element);
                this.updateMsg(element.id, true);
              }

              let getLocalTimeZone = this.session.get('timeZone');

              if (getLocalTimeZone && element?.messageTime) {
                //console.log(moment.utc(element?.messageTime).tz(getLocalTimeZone).format('YYYY-MM-DD hh:mm A'))
                element.finalDate = moment.utc(element?.messageTime).tz(getLocalTimeZone).format('YYYY-MM-DD hh:mm A');
              }
            });
            setTimeout(() => {
              //console.log(this.chatData);
              var objDiv = document.getElementById("chats");
              objDiv.scrollTop = objDiv.scrollHeight;
            }, 1000)
          }
          if (search) {
            setTimeout(() => {
              ////console.log(data.data.messageIDList[0] + ' ' + search);
              if (data.data && data.data.messageIDList) {
                this.searchCount = data.data.searchCount;
                this.page = data.data.selectedPage;
                this.msgIds = data.data.messageIDList;

                var isMobileVersion = document.getElementsByClassName('highlight');

                ////console.log(isMobileVersion.length);

                if (isMobileVersion.length > 0) {
                  for (var i = 0; i < isMobileVersion.length; i++) {
                    var container = isMobileVersion[i].parentNode;
                    var text = isMobileVersion[i].innerHTML;
                    ////console.log(container);
                    ////console.log(text);
                    ////console.log((<HTMLElement>container).innerHTML);
                    // (<HTMLElement>container).innerHTML.replace('/<span.*class=.highlight.*[\n]+.*?</span>/g',text);
                    // var redata = (<HTMLElement>container).innerHTML.replace(/<span class="highlight">[\s\S]+<\/span>/,text);
                    (<HTMLElement>container).innerHTML = (<HTMLElement>container).innerHTML.replace(/<\/?span[^>]*>/g, "");
                    // container.removeChild(isMobileVersion[i]);
                  }
                }

                setTimeout(() => {
                  if (isMobileVersion.length == 0 && data.data.messageIDList.length > 0) {
                    data.data.messageIDList.forEach((ele) => {
                      var inputText = document.getElementById(ele);
                      ////console.log(inputText);
                      if (inputText != null) {
                        var innerHTML = inputText.innerHTML;
                        var innerHTMLOg = inputText.innerHTML.toLowerCase();
                        var index = innerHTMLOg.indexOf(this.searchVal.toLowerCase());
                        ////console.log(this.searchVal);
                        ////console.log(innerHTML);
                        ////console.log(index);
                        ////console.log(this.searchVal.length);
                        if (index >= 0) {
                          innerHTML = innerHTML.substring(0, index) + "<span class='highlight'>" + innerHTML.substring(index, index + this.searchVal.length) + "</span>" + innerHTML.substring(index + this.searchVal.length);
                          inputText.innerHTML = innerHTML;
                        }
                      }
                    });
                    ////console.log(this.selectedId);
                    if (this.selectedId == undefined) {
                      this.selectedId = data.data.messageIDList[0];
                    }
                    ////console.log(this.selectedId);
                    const highLightBlack = document.getElementById(this.selectedId);
                    if (highLightBlack != null) {
                      this.highLiightSelectedId(this.selectedId);
                    }
                  }
                }, 2000);
              }
            }, 2000);
          }
        } else {
          if (!scroll && !search) {
            this.chatData = [];
          }
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  highLiightSelectedId(id) {
    document.getElementById(id).scrollIntoView();
    var highlight = document.getElementById(id).querySelectorAll(".highlight") as unknown as HTMLCollectionOf<HTMLElement>;
    highlight[0].classList.add("highBlack");
    ////console.log(highlight);
  }

  removeHighLight() {
    const highlight = Array.from(document.querySelectorAll(".highlight"));
    ////console.log(highlight);
    highlight.forEach(element => {
      element.classList.remove("highBlack");
    });
  }

  /* Name:Shailesh
   use: for updating message */
  updateMsg(id, status) {
    const read = {
      "isRead": status,
      "userCode": this.session.get('super_admin_code')
    }
    // //console.log('updateMsg: ', id, read);
    try {
      // Find the object with id and update isRead to true
      const message = id && this.chatData && this.chatData?.length > 0 && this.chatData.find(item => item.id === id);
      if (message) {
        message.isRead = true;
      }
      this.service.updateMessage(id, read, (res) => {
        if (res.responsecode == 200) {
          const updateCount = this.allMarshals.findIndex(element => element.groupDetailsCode == this.selectedMarshal);
          ////console.log(updateCount);
          if (updateCount != -1) {
            this.allMarshals[updateCount].unReadCount = 0;
            ////console.log(this.allMarshals[updateCount].unReadCount);
            ////console.log(this.allMarshals);
            this.common.globalChatCount = this.common.globalChatCount - 1;
          }
        } else {
          if (message) {
            message.isRead = false;
          }
          // this.common.message = res.message;
          // this.common.errorMessage = true;
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  closeChat(): void {
    this.selectedMarshal = '';
    this.dialogRef.close();
  }

  scrolledUp(event) {
    ////console.log('scroll');
    ////console.log(event);
    ////console.log(this.searchVal);
    this.page = this.page + 1;
    if (this.searchVal) {
      this.getChat(this.page, this.limit, 'scroll', this.searchVal);
    } else {
      this.getChat(this.page, this.limit, 'scroll');
    }
  }

  // scrolledDown(event){
  //   ////console.log('scrollevent');
  //   ////console.log(event);
  //   if(this.page > 0 && this.searchVal){
  //     this.chatData = [];
  //     this.page = this.page - 1;
  //     this.getChat(this.page, this.limit, 'scroll', this.searchVal);  
  //   }
  // }

  searchUp(arrow) {
    let index = this.msgIds.indexOf(this.selectedId);
    ////console.log(index);
    if (arrow == 'up') {
      if (index >= 0 && index < this.msgIds.length - 1) {
        this.removeHighLight();
        let nextItem = this.msgIds[index + 1];
        ////console.log(nextItem);
        this.selectedId = nextItem;
        const id = document.getElementById(this.selectedId);
        if (id == null) {
          this.getChat(this.page, this.limit, null, this.searchVal, nextItem);
        } else {
          this.highLiightSelectedId(nextItem);
        }
      }
    } else {
      if (index > 0 && index <= this.msgIds.length) {
        this.removeHighLight();
        let nextItem = this.msgIds[index - 1];
        ////console.log(nextItem);
        this.selectedId = nextItem;
        const id = document.getElementById(this.selectedId);
        if (id == null) {
          this.getChat(this.page, this.limit, null, this.searchVal, nextItem);
        } else {
          this.highLiightSelectedId(nextItem);
        }
      }
    }
  }

  searchMarshal(search) {
    ////console.log(search);
    ////console.log(this.allMarshals);
    if (search) {
      this.allMarshals = this.searchallMarshals.filter(ele => ele.groupName.toLowerCase().includes(search.toLowerCase()));
    } else {
      this.allMarshals = this.searchedMarshal;
    }
  }

  searchMsg(msg) {
    ////console.log(msg);
    this.searchVal = msg;
    if (msg.length > 1) {
      this.page = -1;
      this.selectedId = null;
      this.getChat(this.page, this.limit, null, msg);
    } else {
      this.page = 0;
      this.limit = 20;
      this.getChat(this.page, this.limit);
    }
  }

  resetSearch(cancel) {
    this.selectedId = undefined;
    this.searchVal = '';
    this.searchCount = '';
    // this.chatData = [];
    const searchVal = document.getElementById('searchVal') as HTMLInputElement;
    //console.log(searchVal);
    if (searchVal != null) {
      searchVal.value = null;
    }
    this.page = 0;
    this.limit = 20;
    //console.log(cancel);
    if (!cancel) {
      //console.log('Inside');
      this.getChat(this.page, this.limit);
    }
  }

  addGroup() {

    const dialogRef = this.dialog.open(ChatGroupComponent, {
      width: '650px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      this.getGroups();
    });
  }

  // sendBrdCstMsg(): void {
  //   const dialogRef = this.dialog.open(BroadcastMessageComponent, {
  //     width: '550px',
  //     data: {},
  //     disableClose: true
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     // ////console.log('The dialog was closed');  
  //   });
  // }

  /* Name:Shailesh
 use: for deleting message*/
  deleteMessage(messageId) {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete this message?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.deleteMessageById(messageId, (res) => {
            if (res.responsecode == 200) {
              // this.common.message = res.message;
              // this.common.successMessage = true;
              this.getChat(this.page, this.limit);

            } else {
              this.common.message = res.message;
              this.common.errorMessage = true;
            }
          })
        } catch (error) {
          //console.log(error)
        }

      }
    });

  }

  geteditData(msgId) {
    //console.log('element');
    ////console.log(this.showPopup);
    if (this.showPopupId == msgId) {
      this.showPopupId = '';
    } else {
      this.showPopupId = msgId;
    }

  }

  messageMarkAsRead(chat: any) {
    // console.log('messageMarkAsRead: ', chat);
    if (chat && !chat.isRead) {
      this.updateMsg(chat.id, true);
      return chat.isRead = true;
    } else {
      this.updateMsg(chat.id, false);
      return chat.isRead = false;
    }
  }

  ngOnDestroy(): void {
    if (this.topicChatSubscription) {
      this.topicChatSubscription.unsubscribe();
    }

    this.destroyObs.next('');
    this.destroyObs.complete();
  }

  toggleChat() {
   // const toggleButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.toggle-chat-btn');
    const leftChatContainer: HTMLElement | null = document.querySelector('.left-chat-container');
    const rightChatContainer: HTMLElement | null = document.querySelector('.right-chat-container');
   // const openChat : HTMLElement | null =  document.querySelector('.mobile-view-open-chat');

 
        if (leftChatContainer) {
          leftChatContainer.classList.toggle('show');
        }
 

        if (rightChatContainer) {
          rightChatContainer.classList.toggle('hide');
        }

   }

  //  backToChatList()
  //  {

  //  }


}