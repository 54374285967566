<div class="w-100">
  <div class="col-md-12" *ngIf="popupDetailViewData.isStaffDetailOpenInPopup">
    <div class="row justify-content-end">
      <button class="btn-close cursor-pointer" (click)="closeDialog()"></button>
    </div>
  </div>
  <div class="row">
      <div class="col-md-3 mb-4">
          <div class="profile-card">
               <div class="pb-3">
                  <div class="profile-inner-card py-4">
                      <div class="profile-image">
                          <img class="avatarImg" [src]="fileUrl ? fileUrl : 'assets/common_images/profile.jpg'"
                           width="130" height="130" alt=""
                           onerror="this.src='assets/common_images/profile.jpg';">
                          <!-- <input class="profile-img" type="file" enctype="multipart/form-data" (change)="onFileChange($event)" #fileInput> -->
                          <!-- <img src="assets/images/profile-edit.svg" class="profile-edit cursor-pointer" width="40" height="40" alt="" appImageCropper (croppedImage)="onCroppedImage($event)">                           -->
                      </div>
                  </div>
                  <h5 class="profile-name">{{(userName ? userName : '') | capitalize}}</h5>
              </div>
             
              <!-- <ng-template [ngIf]="!New_ProfessionalForm.disabled"> -->
                <ng-template [ngIf]="userType != 'Staff'">
              <div class="profile-radio-sec ">
                  <div class="col-auto select-radio radio-border p-3">
                      <input id="userStatusActive" type="radio" name="userStatus" [checked]="isUserActive" (click)="!isUserActive && setActiveInactiveStaff(userName, true, 'userStatusActive')">
                      <label for="userStatusActive"><span></span>Active</label>
                  </div>

                  <div class="col-auto select-radio p-3">
                      <input id="userStatusInactive" type="radio" name="userStatus" (click)="isUserActive && setActiveInactiveStaff(userName, false, 'userStatusInactive')" [checked]="!isUserActive">
                      <label for="userStatusInactive"><span></span>Inactive</label>
                  </div>
              </div>
            </ng-template>
          
          </div>

          <div class="w-100 mt-4">

            <mat-accordion>
              
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   Distribution List
                  </mat-panel-title>
                 </mat-expansion-panel-header>
                <div>
                  <assigned-distribution-list-for-staff [workerCode]="workerCode" [listOpenFrom]="'Staff Edit'" [isHeaderHide]="true"></assigned-distribution-list-for-staff>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   Uploaded Documents
                  </mat-panel-title>
                 </mat-expansion-panel-header>
                <div>
                  <ul class="list">
                
                    <ng-container *ngIf="workersAllUploadedDocs?.workerCertificationResList?.length > 0">
                      <label class="ssd-doc-subheading">Certificates:</label>
                      <ng-container *ngFor="let doc of workersAllUploadedDocs.workerCertificationResList">
                        <div class="d-flex flex-column">
                        <span class="ssd-doc-doc-name">{{doc.certificationName}}</span> 
                
                        <div *ngIf="doc?.certificateFileName && doc?.certificateFileName?.length > 0">
                          <li *ngFor="let file of doc?.certificateFileName; let i = index" style="cursor: pointer;"
                            (click)="viewFiles(file)">
                            {{i+1+'.'}}&nbsp;
                            <span>{{formateFile(file)}}</span>
                          </li>
                        </div>
                         </div>
                      </ng-container>
                    </ng-container>
                
                    <ng-container *ngIf="workersAllUploadedDocs?.workerLicenceResList?.length > 0">
                      <label class="ssd-doc-subheading">Licence:</label>
                      <ng-container *ngFor="let doc of workersAllUploadedDocs.workerLicenceResList">
                        <div class="d-flex flex-column">
                        <span class="ssd-doc-doc-name">{{doc.licenceName}}</span> 
                        <div *ngIf="doc?.licenceFileName && doc?.licenceFileName?.length > 0">
                          <li *ngFor="let file of doc?.licenceFileName; let i = index" style="cursor: pointer;"
                            (click)="viewFiles(file)">
                            {{i+1+'.'}}&nbsp;<span>{{formateFile(file)}}</span>
                          </li>
                        </div>
                        </div>
                      </ng-container>
                    </ng-container>
                
                   
             <!-- <ng-container *ngIf="workersAllUploadedDocs?.workerCertificationResList?.length > 0">
              <label class="ssd-doc-subheading">Certificates:</label>
            <li *ngFor="let doc of workersAllUploadedDocs.workerCertificationResList" style="cursor: pointer;"
            (click)="viewFiles(doc.certificateFileName)">
             <span>{{doc.certificationName}}</span>
            </li>
            </ng-container>

            <ng-container *ngIf="workersAllUploadedDocs?.workerLicenceResList?.length > 0">
              <label class="ssd-doc-subheading">License:</label>
            <li *ngFor="let doc of workersAllUploadedDocs.workerLicenceResList" style="cursor: pointer;"
            (click)="viewFiles(doc.licenceFileName)">
             <span>{{doc.licenceName}}</span>
            </li>
            </ng-container> -->

                  </ul>
                  <div class="noRecordFound"
                    *ngIf="(!workersAllUploadedDocs?.workerCertificationResList ||workersAllUploadedDocs?.workerCertificationResList?.length === 0) 
                                   && (!workersAllUploadedDocs?.workerLicenceResList || workersAllUploadedDocs?.workerLicenceResList?.length)">
                    <span>No documents found.</span>
                  </div>
                </div>
              </mat-expansion-panel>

            </mat-accordion>

          
          </div>

      </div>
      <div class="col-md-9">
         
<div class="w-100">
 
  <mat-tab-group
    dynamicHeight
    #tabGroup
    class="tab-button-auto-width"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Personal">
      <ng-container *ngIf="activeTab === 'Personal'">
        <div class="mt-3 {{popupDetailViewData.isStaffDetailOpenInPopup ? 'mb-5 pb-5' : ''}}">
          <form [formGroup]="personalform" id="tabone" (ngSubmit)="onSubmit()">

            <div class="row">
              <div class="col-md-6">
                <mat-label>First Name</mat-label>
                  <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                    <input title="{{technicianService.personalWorker.firstName}}" matInput class="form-control" formControlName="name" minlength="1" maxlength="50"
                      [(ngModel)]="technicianService.personalWorker.firstName">
                  </mat-form-field>
                  <span class="help-block err"
                    *ngIf="personalform.get('name').errors?.required && (!submitted || personalform.get('name').touched)">
                    First name is required
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('name').errors?.pattern && (!submitted || personalform.get('name').touched)">
                     Should be only alphabets.
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('name').errors?.minlength && (!submitted || personalform.get('name').touched)">
                    <!-- {{ 'Technicians.Validators.Minlength is 2' | translate }}. -->
                    Char should be 1 or more.
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('name').errors?.maxlength && (!submitted || personalform.get('name').touched)">
                    {{ 'Technicians.Validators.Maxlength is 50' | translate }}.
                  </span>
              </div>
              
              <div class="col-md-6">
                <mat-label>Last Name</mat-label>
                  <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                    <input title="{{technicianService.personalWorker.lastName}}" matInput class="form-control" formControlName="lastName" minlength="1" maxlength="50" [(ngModel)]="technicianService.personalWorker.lastName">
                  </mat-form-field>
                  <span class="help-block err"
                    *ngIf="personalform.get('lastName').errors?.required && (!submitted || personalform.get('lastName').touched)">
                    Last name is required
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('lastName').errors?.pattern && (!submitted || personalform.get('lastName').touched)">
                     Should be only alphabets.
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('lastName').errors?.minlength && (!submitted || personalform.get('lastName').touched)">
                    <!-- {{ 'Technicians.Validators.Minlength is 2' | translate }}. -->
                    Char should be 1 or more.
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('lastName').errors?.maxlength && (!submitted || personalform.get('lastName').touched)">
                    {{ 'Technicians.Validators.Maxlength is 50' | translate }}.
                  </span>
              </div>
              
            </div>

            <div class="row">                   
      
              <div class="col-md-6">
                <mat-label>{{ 'Technicians.labels.Email' | translate }}</mat-label>  
                  <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">  
                    <input title="{{technicianService.personalWorker.email}}" matInput class="form-control" #email formControlName="email"
                      [(ngModel)]="technicianService.personalWorker.email" readonly>
                  </mat-form-field>
                  <span class="help-block err"
                    *ngIf="personalform.get('email').errors?.required && (!submitted || personalform.get('email').touched)">
                    {{ 'Technicians.Validators.Email Address is required' | translate }}
                  </span>
                  <span class="help-block err"
                    *ngIf="personalform.get('email').errors?.email && (!submitted || personalform.get('email').touched)">
                    {{ 'Technicians.Validators.Please enter valid email address' | translate }}
                  </span>

                        <span class="help-block err"
                          *ngIf="personalform.get('email').errors?.invalid && (!submitted || personalform.get('email').touched)">
                          Email id is already present
                        </span>
                    </div>    
                    
                    <div class="col-md-6">
                      <mat-label>{{ 'Technicians.labels.Employee Type' | translate }}</mat-label> 
                        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width" >       
                          <mat-select formControlName="employmentType" placeholder=""
                            [(ngModel)]="technicianService.personalWorker.employmentTypeCode">
                            <mat-option *ngFor="let lang of employeeType" [value]="lang.employmentTypeCode">
                              {{lang.employmentTypeName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <span class="help-block err"
                          *ngIf="personalform.get('employmentType').errors?.required && (!submitted || personalform.get('employmentType').touched)">
                          {{ 'Technicians.Validators.Employee Type is required' | translate }}
                        </span>
                    </div>
                    
                  </div>
            
                  <div class="row">
            
                    <!-- <div class="col-md-12">
                      <mat-label>Permanent Address</mat-label>
                        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                          <textarea class="form-control" matInput placeholder="" formControlName="parmanentAddress"
                            ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                            (keypress)="handlePermanentAddressChange($event)" (onAddressChange)="handlePermanentAddressChange($event)" [(ngModel)]="technicianService.personalWorker.parmanentAddress"></textarea>
                        </mat-form-field>
                    </div> -->
            
                  <div class="col-md-6 col-sm-12 col-xm-12">
                    <div class="row">
                      <div class="col-md-6">
                        <app-country-code [withoutFloatLabel]="true" class="ccode" (setCountryCode)="getCountryCode($event,'workerCountryCode')"
                          [setCode]="workerCountryCode" [placeholder]="'Country Code'"></app-country-code>
                      </div>
            
            
                      <div class="col-md-6">
                        <mat-label>Mobile No</mat-label>
                        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width no-form">
                          <input class="form-control" minlength="10" maxlength="12" matInput placeholder="" formControlName="mobileNo"
                            [(ngModel)]="technicianService.personalWorker.mobileNo">
                        </mat-form-field>
                        <span class="help-block err"
                          *ngIf="!personalform.get('homeCountryCode').valid && (!submitted || personalform.get('homeCountryCode').touched)">
                          {{ 'Technicians.Validators.Mobile country code is required' | translate }}</span>
                        <span class="help-block err"
                          *ngIf="personalform.get('mobileNo').errors?.required && (!submitted || personalform.get('mobileNo').touched)">
                          {{ 'Technicians.Validators.Mobile number is required' | translate }}
                        </span>
                        <span class="help-block err"
                          *ngIf="personalform.get('mobileNo').errors?.minlength && (!submitted || personalform.get('mobileNo').touched)">
                          {{ 'Technicians.Validators.Mobile number length should be 10' | translate }}
                        </span>
                        <span class="help-block err"
                          *ngIf="personalform.get('mobileNo').errors?.maxlength && (!submitted || personalform.get('mobileNo').touched)">
                          Mobile number length should be 12
                        </span>
                        <span class="help-block err"
                          *ngIf="personalform.get('mobileNo').errors?.pattern && (!submitted || personalform.get('mobileNo').touched)">
                          {{ 'Technicians.Validators.Mobile number must be in number' | translate }}
                        </span>
                      </div>
            
                    </div>
                  </div>
            
                  <div class="col-md-6">
                    <div class="row">
            
                      <div class="col-md-6">
                        <app-country-code [withoutFloatLabel]="true" class="ccode" (setCountryCode)="getCountryCode($event,'homeCountryCode')"
                          [setCode]="homeCountryCode" [placeholder]="'Country Code'"></app-country-code>
                      </div>
            
                      <div class="col-md-6">
                        <label class="center-block primary-color"></label>
                        
                        <mat-label>Emergency No</mat-label>
                        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width no-form ">
                          <input matInput class="form-control" maxlength="12" placeholder="" formControlName="emergencyNo"
                            [(ngModel)]="technicianService.personalWorker.emergencyNo">
                        </mat-form-field>
                        <span class="help-block err"
                          *ngIf="!personalform.get('workerCountryCode').valid && (!submitted || personalform.get('workerCountryCode').touched)">
                          {{ 'Technicians.Validators.Country code is required' | translate }}</span>
                        <span class="help-block err"
                          *ngIf="personalform.get('emergencyNo').errors?.required && (!submitted || personalform.get('emergencyNo').touched)">
                          {{ 'Technicians.Validators.Emergency number is required' | translate }}
                        </span>
                        <span class="help-block err"
                          *ngIf="personalform.get('emergencyNo').errors?.pattern && (!submitted || personalform.get('emergencyNo').touched)">
                          {{ 'Technicians.Validators.Emergency number must be in number' | translate }}
                        </span>
                        <span class="help-block err"
                          *ngIf="personalform.get('emergencyNo').errors?.minlength && (!submitted || personalform.get('emergencyNo').touched)">
                          {{ 'Technicians.Validators.Emergency number length should be 10' | translate }}
                        </span>
                        <span class="help-block err"
                          *ngIf="personalform.get('emergencyNo').errors?.maxlength && (!submitted || personalform.get('emergencyNo').touched)">
                          Emergency number length should be 12
                        </span>
                      </div>
            
                    </div>
                  </div>
                  </div>
            
                  <div class="row">

                    <div class="col-md-12 col-sm-12">
                      <mat-label>Address</mat-label>
                        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width  p-0 ">
                          <textarea class="form-control" matInput placeholder="Enter a address" formControlName="address"
                            ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                            (keypress)="handleAddressChange($event)" (onAddressChange)="handleAddressChange($event)"></textarea>
            
                        </mat-form-field>
                        <span class="help-block err"
                          *ngIf="personalform.get('address').errors?.required && (!submitted || personalform.get('address').touched)">Address is required</span>
                        <span class="help-block err"
                          *ngIf="personalform.get('address').errors?.invalid && (!submitted || personalform.get('address').touched)">
                          {{ 'Technicians.Validators.Enter valid Address' | translate }} </span>

                          </div>

                       <div class="col-md-6">
                          <mat-label>{{ 'Technicians.labels.Apt/Unit no' | translate }}</mat-label>
                            <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                              <input [(ngModel)]="technicianService.personalWorker.apartmentNo" formControlName="apartmentNo"
                                matInput class="form-control" placeholder="" (keyup)="getAppartmentNumber($event)">
                            </mat-form-field>
                            <span class="help-block err"
                              *ngIf="!personalform.get('apartmentNo').valid && (!submitted || personalform.get('apartmentNo').touched)">{{ 'Technicians.Validators.Apartment/Unit no is required' | translate }}</span>
                        </div>

                        <div class="col-md-6">
                          <mat-label>{{ 'Technicians.labels.Street Address' | translate }}</mat-label>
                            <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                              <input [(ngModel)]="technicianService.personalWorker.address" formControlName="fullAddress" matInput
                                class="form-control" placeholder="" (keyup)="getAppartmentNumber($event)">
                            </mat-form-field>
                            <span class="help-block err"
                              *ngIf="!personalform.get('fullAddress').valid && (!submitted || personalform.get('fullAddress').touched)">{{ 'Customers.validators.Street address is required' | translate }}</span>
                        </div>
                      
                       <div class="col-md-6">
                          <mat-label>{{ 'Technicians.labels.City' | translate }}</mat-label>
                            <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                              <input matInput class="form-control" formControlName="city"
                                [(ngModel)]="technicianService.personalWorker.city">
                            </mat-form-field>
                            <span class="help-block err"
                              *ngIf="personalform.get('city').errors?.required && (!submitted || personalform.get('city').touched)">
                              {{ 'Technicians.Validators.City is required' | translate }}</span>
                            <span class="help-block err"
                              *ngIf="personalform.get('city').errors?.invalid && (!submitted || personalform.get('city').touched)">
                              {{ 'Technicians.Validators.City is required' | translate }}</span>
                            <span class="help-block"
                              *ngIf="personalform.get('city').errors?.pattern && (!submitted || personalform.get('city').touched)">
                              {{ 'Technicians.Validators.City must be letter or space' | translate }}.
                            </span>
                        </div>
            
                        <div class="col-md-6">
                          <mat-label>{{ 'Technicians.labels.State' | translate }}</mat-label>
                          <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                            <input matInput class="form-control" formControlName="state"
                              [(ngModel)]="technicianService.personalWorker.state">
                          </mat-form-field>
                          <span class="help-block err"
                            *ngIf="personalform.get('state').errors?.required && (!submitted || personalform.get('state').touched)">
                            {{ 'Technicians.Validators.State is required' | translate }}
                          </span>
                          <span class="help-block err"
                            *ngIf="personalform.get('state').errors?.invalid && (!submitted || personalform.get('state').touched)">
                            {{ 'Technicians.Validators.State is required' | translate }}
                          </span>
                        </div>
            
                        <div class="col-md-6">
                          <mat-label>{{ 'Technicians.labels.Country' | translate }}</mat-label>
                            <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                              <input matInput class="form-control" minlength="2" maxlength="50" formControlName="country"
                                [(ngModel)]="technicianService.personalWorker.country">
                            </mat-form-field>
                            <span class="help-block err"
                              *ngIf="personalform.get('country').errors?.required && (!submitted || personalform.get('country').touched)">
                              {{ 'Technicians.Validators.Country is required' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('country').errors?.invalid && (!submitted || personalform.get('country').touched)">
                              {{ 'Technicians.Validators.Country is required' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('country').errors?.minlength && (!submitted || personalform.get('country').touched)">
                              {{ 'Technicians.Validators.Country minlength is 2' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('country').errors?.maxlength && (!submitted || personalform.get('country').touched)">
                              {{ 'Technicians.Validators.Country minlength is 50' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('country').errors?.pattern && (!submitted || personalform.get('country').touched)">
                              {{ 'Technicians.Validators.Postcode pattern must be letter or number or space and must have more than 4 characters' | translate }}.
                            </span>
                        </div>

                        <div class="col-md-6">
                          <mat-label>{{ 'Technicians.labels.Postcode' | translate }}</mat-label>
                            <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                              <input matInput class="form-control" minlength="4" maxlength="8" formControlName="postCode"
                                [(ngModel)]="technicianService.personalWorker.postCode">
                            </mat-form-field>
                            <span class="help-block err"
                              *ngIf="personalform.get('postCode').errors?.required && (!submitted || personalform.get('postCode').touched)">
                              {{ 'Technicians.Validators.Postcode is required' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('postCode').errors?.invalid && (!submitted || personalform.get('postCode').touched)">
                              {{ 'Technicians.Validators.Postcode is required' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('postCode').errors?.minlength && (!submitted || personalform.get('postCode').touched)">
                              {{ 'Technicians.Validators.Postcode minlength is 4' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('postCode').errors?.maxlength && (!submitted || personalform.get('postCode').touched)">
                              {{ 'Technicians.Validators.Postcode maxlength is 8' | translate }}
                            </span>
                            <span class="help-block err"
                              *ngIf="personalform.get('postCode').errors?.pattern && (!submitted || personalform.get('postCode').touched)">
                              {{ 'Technicians.Validators.Post code pattern must be letter or number or space and must have more than 4 characters' | translate }}.
                            </span>
                        </div>
                       
                  </div>

                  <!-- <div class="row">
                    <div class="col-12">
                      <mat-label>Notes</mat-label>
                        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                         <textarea matInput  formControlName="notes" [(ngModel)]="technicianService.personalWorker.notes"></textarea>
                        </mat-form-field>
                    </div>
                  </div> -->

            <div class="d-flex justify-content-end mt-3">
              <span class="headerNoteTitleBtn me-3" style="padding-top: 6px;">
                <span class="cursor-pointer" (click)="technicianService?.personalWorker?.noteList ? common.addUpdateNotes(technicianService.personalWorker.noteList) : common.addUpdateNotes()">
                  <span class="notebtnContainer" *ngIf="workerCode && technicianService?.personalWorker?.noteList?.length > 0"><img class="p-1 notebtnIc" src="assets/images/note-icon-active.svg" /><span class="ps-1 noteBtnTxtActive">Note</span></span>
                  <span class="notebtnContainer" *ngIf="!workerCode || technicianService?.personalWorker?.noteList?.length === 0"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" /><span class="ps-1 noteBtnTxtInactive">Note</span></span>
                </span>
              </span>
              <ng-template [ngIf]="userType !== 'Staff'">
              <a *ngIf="personal" href="javascript:void(0)" class="btn btn-cancel px-4 me-2" (click)="popupDetailViewData.isStaffDetailOpenInPopup ? closeDialog() : cancelPopup('personal')">Cancel</a>
            </ng-template>            
              <a href="javascript:void(0)" class="btn btn-create px-4 me-2" (click)="workerCode && changePassword(workerCode)">Reset Password</a>
              <a href="javascript:void(0)" class="btn btn-create px-4 " (click)="onSubmit()">Save Changes</a>
          </div>
      
          </form>


        
        </div>
      </ng-container>
    </mat-tab>
    
    <ng-container *ngIf="!popupDetailViewData.isStaffDetailOpenInPopup">
     
      <mat-tab label="Professional">

        <ng-container *ngIf="activeTab === 'Professional'"> 
        <worker-prof-skills
         [workerCode]="workerCode"
         [checkForFormStatus]="checkForFormStatus"
         (getFormData)="getProfessionForm($event)"
         (DocFileDelete)="handleFileDelete($event)"
         ></worker-prof-skills>

         <div class="d-flex justify-content-end mt-4">
          <!-- <ng-template [ngIf]="!New_ProfessionalForm.disabled"> -->
            <ng-template [ngIf]="userType != 'Staff'">
            <a *ngIf="personal" href="javascript:void(0)" class="btn btn-cancel px-4 me-3"
              (click)="cancelPopup('professional')">Cancel</a>

            <a href="javascript:void(0)" class="btn btn-create px-4 " (click)="saveProfTabData()">Save
              Changes</a>
          </ng-template> 

        </div>

      </ng-container>

        <!-- <div class="mt-3">
          <div class="col-md-12">
            <div class="row">
              <form class="w-100" [formGroup]="New_ProfessionalForm">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ng-container formArrayName="SkillArray">
                        <div class="w-100 mb-2"
                          *ngFor="let outerElement of skillFormsArray.controls; let i=index ; let last = last;"
                          [formGroupName]="i">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-6 mt-4">
                                <mat-label>{{ 'Technicians.labels.Skills' | translate }}</mat-label>
                                <mat-form-field appearance="outline" floatLabel="none"
                                  class="example-full-width custom-search">
    
                                  <mat-select formControlName="skill" (selectionChange)="onCustomerChange($event,i)">
                                    <mat-option>
                                      <ngx-mat-select-search [placeholderLabel]="'Search Skill'"
                                        [noEntriesFoundLabel]="'Record not found'"
                                        [formControl]="skillSeachInput"></ngx-mat-select-search>
                                    </mat-option>
                                    <ng-container *ngFor="let option of filteredOptionsSkill | async">
                                      <mat-option [disabled]="showOption(option)" [value]="option">
                                        {{option.skillName}}
                                      </mat-option>
                                    </ng-container>
                                  </mat-select>
    
                                  <mat-error
                                    *ngIf="skillFormsArray.controls[i].get('skill').errors?.required && (is_FormSubmitted || skillFormsArray.controls[i].get('skill').touched)">
                                    Field can't be blank
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-6 mt-4">
                                <div class="row justify-content-end">
                                  <div class="w-100 ms-auto mb-3 mt-4 float-end">
                                    <ng-template [ngIf]="!New_ProfessionalForm.disabled">
                                      <a href="javascript:void(0)" class="btn btn-skills px-4 float-end"
                                        (click)="createSkillForm(null)">+ Add More Skills</a>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
    
    
                          <div class="col-md-12">
                            <hr class="mt-0">
                          </div>
    
                          <div class="w-100 ps-3 pe-3">
                            <ng-template [ngIf]="skillFormsArray?.controls[i]?.value?.skill?.skillCode">
                              <div class="row mb-2 mt-2 inner-forms">
    
                                <div class="col-md-6" formArrayName="certificationMapList">
                                  <ng-template [ngIf]="outerElement.get('certificationMapList')['controls']?.length > 0">
                                    <div class="row pe-2">
                                      <div class="upload-field w-100">
                                        <h6 class="dialog-header">Upload Certificates</h6>
                                        <div class="mb-2"
                                          *ngFor="let innerEle_certificate of outerElement.get('certificationMapList').controls; let j = index"
                                          [formGroupName]="j">
    
                                          <div class="row mb-3">
                                            <div class="w-100 ps-3 pe-3 mb-3">
                                              <div class="d-flex upload-inner-border">
                                                <div class="col-lg-3">
                                                  <div class="upload-inner-icon w-100">
                                                    <input type="file" #Cert_fileupload
                                                      (change)="OnCertificateFileChange($event,'certificate',i,j)"
                                                      style="display: none;">
                                                    <img *ngIf="innerEle_certificate.value.certificateFileName"
                                                      [src]="img_serverPath+innerEle_certificate.value.certificateFileName">
                                                  </div>
                                                </div>
                                                <div class="col-lg-8">
                                                  <span style="font-size: 16px;" class="d-flex pe-auto">
                                                    {{innerEle_certificate.value.certificateName}} &nbsp;&nbsp;
    
                                                    <ng-template [ngIf]="!New_ProfessionalForm.disabled">
                                                      <button (click)="Cert_fileupload.click()"
                                                        class="browse">Browse</button>
                                                    </ng-template>
                                                  </span>
                                                  <div
                                                    *ngIf="innerEle_certificate.get('certificateFileName').errors?.required"
                                                    class="help-block">
                                                    Please click above to Upload file
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
    
                                            <div class="row g-1">
                                              <div class="col-4" *ngIf="innerEle_certificate.get('issuedBy') !== null">
                                                <mat-label>Issued By</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="none">
                                                  <input matInput formControlName="issuedBy">
                                                </mat-form-field>
                                                <div *ngIf="innerEle_certificate.get('issuedBy').errors?.required"
                                                  class="help-block">
                                                  This is required field
                                                </div>
                                              </div>
    
                                              <div class="col-3" *ngIf="innerEle_certificate.get('numberValue') !== null">
                                                <mat-label>Number</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="none">
                                                  <input matInput formControlName="numberValue">
                                                </mat-form-field>
                                                <div *ngIf="innerEle_certificate.get('numberValue').errors?.required"
                                                  class="help-block">
                                                  This is required field
                                                </div>
                                              </div>
    
                                              <div class="col-5"
                                                *ngIf="innerEle_certificate.get('certificateExpiryDate') !== null">
                                                <mat-label>Expiry date</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="none">
                                                  <input matInput [matDatepicker]="cert_Job_Date" [min]="minDate"
                                                    formControlName="certificateExpiryDate">
                                                  <mat-datepicker-toggle matIconSuffix
                                                    [for]="cert_Job_Date"></mat-datepicker-toggle>
                                                  <mat-datepicker #cert_Job_Date></mat-datepicker>
                                                </mat-form-field>
                                                <div class="help-block"
                                                  *ngIf="innerEle_certificate.get('certificateExpiryDate').errors?.required">
                                                  Please choose expiry date
                                                </div>
                                              </div>
    
    
                                            </div>
                                          </div>
    
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                </div>
    
                                <div class="col-md-6" formArrayName="licenceMapList">
                                  <ng-template [ngIf]="outerElement.get('licenceMapList')['controls']?.length > 0">
                                    <div class="row ps-2">
                                      <div class="upload-field w-100">
                                        <h6 class="dialog-header">Upload License</h6>
                                        <div class="mb-2"
                                          *ngFor="let innerEle_license of outerElement.get('licenceMapList').controls; let k = index"
                                          [formGroupName]="k">
    
                                          <div class="row mb-3">
                                            <div class="w-100 ps-3 pe-3 mb-3">
                                              <div class="d-flex upload-inner-border">
                                                <div class="col-lg-3">
                                                  <div class="upload-inner-icon w-100">
                                                    <input type="file" #Linc_fileupload
                                                      (change)="OnCertificateFileChange($event,'license',i,k)"
                                                      style="display: none;">
                                                    <img *ngIf="innerEle_license.value.licenceFileName"
                                                      [src]="img_serverPath + innerEle_license.value.licenceFileName">
                                                  </div>
                                                </div>
                                                <div class="col-lg-8">
                                                  <span style="font-size: 16px;" class="d-flex pe-auto"
                                                    (click)="Linc_fileupload.click()">
                                                    {{innerEle_license.value.licenceName}}&nbsp;&nbsp;
                                                    <ng-template [ngIf]="!New_ProfessionalForm.disabled">
                                                      <button class="browse">Browse</button>
                                                    </ng-template>
                                                  </span>
                                                  <div *ngIf="innerEle_license.get('licenceFileName').errors?.required"
                                                    class="help-block">
                                                    Please click above to Upload file
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
    
                                            <div class="row g-1">
                                              <div class="col-4" *ngIf="innerEle_license.get('issuedBy') !== null">
                                                <mat-label>Issued By</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="none">
                                                  <input matInput formControlName="issuedBy">
                                                </mat-form-field>
                                                <div *ngIf="innerEle_license.get('issuedBy').errors?.required"
                                                  class="help-block">
                                                  This is required field
                                                </div>
    
                                              </div>
    
                                              <div class="col-3" *ngIf="innerEle_license.get('numberValue') !== null">
                                                <mat-label>Number</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="none">
                                                  <input matInput formControlName="numberValue">
                                                </mat-form-field>
                                                <div *ngIf="innerEle_license.get('numberValue').errors?.required"
                                                  class="help-block">
                                                  This is required field
                                                </div>
    
                                              </div>
    
                                              <div class="col-5" *ngIf="innerEle_license.get('licenceExpiryDate') !== null">
                                                <mat-label>Expiry date</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="none">
                                                  <input matInput [matDatepicker]="lic_Job_Date" [min]="minDate"
                                                    formControlName="licenceExpiryDate">
                                                  <mat-datepicker-toggle matIconSuffix
                                                    [for]="lic_Job_Date"></mat-datepicker-toggle>
                                                  <mat-datepicker #lic_Job_Date></mat-datepicker>
                                                </mat-form-field>
                                                <div class="help-block"
                                                  *ngIf="innerEle_license.get('licenceExpiryDate').errors?.required">
                                                  Please choose expiry date
                                                </div>
    
                                              </div>
                                            </div>
                                          </div>
    
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                </div>
    
                              </div>
                            </ng-template>
    
                            <ng-template [ngIf]="!New_ProfessionalForm.disabled">
                              <div class="w-100 text-end mt-3">
                                <img class="curser-pointer" *ngIf="skillFormsArray.controls.length > 1"
                                  (click)="deleteFromSkillForm(i)" title="Delete skill" src="assets/images/delete-icon.svg"
                                  alt="Delete skill">
                              </div>
                            </ng-template>
    
                          </div>
    
                          <div class="col-md-12- mt-2">
                            <hr>
                          </div>
    
                        </div>
                      </ng-container>
                    </div>
    
                    <div class="d-flex justify-content-end mt-2">
                      <ng-template [ngIf]="!New_ProfessionalForm.disabled">
                        <a *ngIf="personal" href="javascript:void(0)" class="btn btn-cancel px-4 "
                          (click)="cancelPopup('professional')">Cancel</a>
    
                        <a href="javascript:void(0)" class="btn btn-create px-4 " (click)="saveProfTabData()">Save
                          Changes</a>
                      </ng-template>
    
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> -->

      </mat-tab>
   
    </ng-container>

<ng-container *ngIf="userType !== 'Staff' && !popupDetailViewData.isStaffDetailOpenInPopup">
     <mat-tab label="Exceptions">
        <ng-container *ngIf="activeTab === 'Exceptions'">
          <div class="row">
            <div class="col-12 text-end mt-2" *ngIf="userType !== 'Staff'">
              <button mat-button (click)="backToList()">Cancel</button>
              <!-- <a  href="javascript:void(0)" class="btn btn-cancel px-4 " (click)="backToList()">Cancel</a> -->
            </div>
             
          <app-staff-exception [staffCode]="workerCode"></app-staff-exception>
          </div>
        </ng-container>
      </mat-tab>
  </ng-container>
 
  </mat-tab-group>
</div>
      </div>
  </div>
</div>












 