import { Component, OnInit, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment';
import { Observable, Subscription, map, timer } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import * as internal from 'stream';

type DateTimeMin = {
  date: string,
  time: string
}

@Component({
  selector: 'current-date-time',
  templateUrl: './current-date-time.component.html',
  styleUrls: ['./current-date-time.component.scss']
})
export class CurrentDateTimeComponent implements OnInit {
  date: any;
  time: any;
  dateTimeInterval: any;
  selectedTimezone: any;
  protected orgTimeZone: string;

  timer$: Observable<DateTimeMin>;

  constructor(
    public common: CommonService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private sessionStorage : SessionStorageService,
    private commonAPIService : CommonApiService
  ) {
    // Detach the change detection for this component
    this.cdr.detach();
  }

  ngOnInit() {

    if(!this.sessionStorage.get("timeZone"))
    {
      this.getTimeZone();
    }
    else{
      this.ngZone.runOutsideAngular(() => {
        this.timer$ = this.createTimer(1000, this.sessionStorage.get("timeZone"));
        
         // Manually trigger change detection for this component only
        this.timer$.subscribe((res)=> this.cdr.detectChanges())
        
         
      });
    }

    

    this.common.timeZoneObs$.subscribe((res => {
      this.getTimeZone();
    }));

  }

    //this.getSettingCode();

    // setTimeout(() => { 
    //   this.common.getSettingBySettingName('timeZone', this.common.local.get('orgCode'), (response)=>{
    //     if(response.responsecode == 200){
    //       console.log('TimeZone_1: ', response);
    //     } else {

    //     }
    //   })
    //   this.selectedTimezone = this.common.local.get('timeZone');
    //   console.log('TimeZone: ', this.selectedTimezone, this.common.local.get('orgCode'), this.common.local.get('timeZone'));

    //   this.dateTimeInterval = setInterval(() => {
    //     this.date = moment
    //       .utc(new Date(), "MM-DD-YYYY")
    //       .tz("America/New_York")
    //       .format("ddd, Do MMM YYYY");

    //     this.time = moment
    //       .utc(new Date(), "hh:mm:ss A")
    //       .tz("America/New_York")
    //       .format("hh:mm:ss A");
    //     }, 1000);
    // }, 5000);    

  

  // getSettingCode()
  // {
  //   this.common.getSettingBySettingName('timeZone', this.common.local.get('orgCode'), (response)=>{
  //     if(response.responsecode == 200)
  //       {
  //        // console.log("getSettingBySettingName", response);
  //         this.getTimeZone(response.settingValue);
  //       }  
  //   })
  // }

  // getTimeZone() {


  //   const userType:string = this.sessionStorage.get('user_type');
  //   let code : string ;

  //   if(userType && userType === 'Staff')
  //   {
  //     code = this.sessionStorage.get('userCode');
  //   }
  //   else
  //   {
  //     code = this.sessionStorage.get('orgCode');
  //   }

  //   this.common.getTimeZoneByCode(
  //     code
  //   ).subscribe((res: any) => {
  //     //console.log("getTimeZoneByCode", res);
  //     if (res.responsecode == 200) {
  //       //this.orgTimeZone = res.settingValue;
  //       this.common.local.set('timeZone', res.settingValue);

  //       // Run the timer outside Angular's zone to avoid triggering change detection globally
  //       this.ngZone.runOutsideAngular(() => {
  //         this.timer$ = this.createTimer(1000, res.settingValue);
          
  //          // Manually trigger change detection for this component only
  //         this.timer$.subscribe((res)=> this.cdr.detectChanges())
          
           
  //       });

  //     }

  //   })
  // }

  createTimer(interval: number, timeZone: string): Observable<DateTimeMin> {
    return timer(0, interval).pipe(
      map(() => {
        return {
          date: moment().tz(timeZone).format("ddd, Do MMM YYYY"),
          time: moment().tz(timeZone).format('hh:mm:ss A')
        }
      })
    );
  }


  
  getTimeZone()
  {
    const userType:string = this.sessionStorage.get('user_type');
  
    if(userType && userType === 'Staff')
     this.getStaffTimeZone();
     else
     this.getOrganizationTimeZone();
    
  }

  getOrganizationTimeZone()
  {
   this.commonAPIService.getTimeZoneByOrgCode(
    this.sessionStorage.get("orgCode")
   ).subscribe((res: any) => {
    if (res.responsecode == 200) {
     this.sessionStorage.set('timeZone', res.settingValue);
     // Run the timer outside Angular's zone to avoid triggering change detection globally
     this.ngZone.runOutsideAngular(() => {
      this.timer$ = this.createTimer(1000, res.settingValue);
      
       // Manually trigger change detection for this component only
      this.timer$.subscribe((res)=> this.cdr.detectChanges())
   });
    }
  });
  }

  getStaffTimeZone()
  {
    this.commonAPIService.getTimeZoneByStaffCode(
      this.sessionStorage.get("userCode")
    ).subscribe((res: any) => {
      if (res.responsecode == 200) {
       this.sessionStorage.set('timeZone', res.settingValue);
       // Run the timer outside Angular's zone to avoid triggering change detection globally
       this.ngZone.runOutsideAngular(() => {
        this.timer$ = this.createTimer(1000, res.settingValue);
        
         // Manually trigger change detection for this component only
        this.timer$.subscribe((res)=> this.cdr.detectChanges())
        
         
      });
      }
    });
  }


}
