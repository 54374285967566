<div class="w-100 chat-container">
  <div class="float-end pe-1">
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="closeChat()">
    </button> -->
    <mat-icon class="btn-close" (click)="closeChat()">highlight_off</mat-icon>
  </div>
  <h4 class="title my-2 ps-3">{{ 'chat.labels.Chat' | translate }}</h4>
  <!-- <div class="w-100" *ngIf="allMarshals.length > 0"> -->
  <div class="w-100">
    <div class="inbox_msg">
      <div class="inbox_people left-chat-container">
        <div class="headind_srch">
          <div class="row w-100">
            <div class="recent_heading">
              <h4>{{ 'chat.labels.Recent' | translate }}</h4>

              <!-- <button type="button" class="btn-close toggle-chat-btn" aria-label="Close" (click)="closeChat()"></button> -->
              <mat-icon class="btn-close toggle-chat-btn" (click)="closeChat()">highlight_off</mat-icon>
              <!-- <mat-icon  aria-hidden="false" fontIcon="menu" class="toggle-chat-btn" (click)="toggleChat()"></mat-icon> -->
            </div>
 
          </div>


          <div class="srch_bar row w-100">
            <div class="stylish-input-group w-100 ps-2 col-md-12">
              <span class="input-group-addon d-inline-block">
                <button type="button"> <i class=" material-icons" aria-hidden="true">search</i> </button>
              </span>
              <input type="text" autocomplete="off" class="search-bar
                d-inline-block" (keyup)="searchMarshal($event.target.value)"
                placeholder="{{ 'chat.labels.Search' | translate }}">
            </div>
          </div>
        </div>
        <div class="inbox_chat">

          <div class="no-users chat_list" *ngIf="allMarshals.length == 0 && messageLoad">
            <div class="text-center "></div>
            No users present for chat
          </div>
          <ng-container *ngFor="let marshal of allMarshals">
            <div class="chat_list" [id]="'chat_id_'+marshal.groupDetailsCode" [ngClass]="selectedMarshal ==
                marshal.groupDetailsCode?'active_chat':''" (click)="openChat(marshal)">
              <div class="d-flex">
                <div class="col-2 p-0">
                  <div *ngIf="marshal.groupIcon">
                    <img alt="No Image" class="name-initials" [src]="marshal.image" />
                  </div>
                  <div *ngIf="!marshal.groupIcon">
                    <div *ngIf="marshal.groupName.split(' ').length >= 2" class="name-initials">
                      {{marshal.groupName.split('
                      ')[0].charAt(0).toUpperCase()}}{{marshal.groupName.split('
                      ')[1].charAt(0).toUpperCase()}}
                    </div>
                    <div *ngIf="marshal.groupName.split(' ').length == 1" class="name-initials">
                      {{marshal.groupName.charAt(0).toUpperCase()}}
                    </div>
                  </div>
                </div>
                <div class="col-10 p-0 align-self-center">
                  <div class="chat_people">
                    <div class="chat_ib w-100 d-flex d-flex align-items-center
                        justify-content-between">
                      <h5 class="m-0">{{marshal.groupName}}</h5>
                      <span class="chat_date" *ngIf="marshal.unReadCount != 0">{{marshal.unReadCount}}</span>
                    </div>
                  </div>
                  <div class="d-flex mt-2 justify-content-between last-messages" *ngIf="marshal.lastMessage">
                    <div class="text-truncate" style="width: 180px;" *ngIf="!marshal.isTyping">
                      {{marshal.lastMessage}}
                    </div>
                    <span *ngIf="marshal.isTyping" class="marshalTyping"><img src="assets/typint_dots.gif"></span>
                    <div>
                      {{marshal.lastMessageDateTime}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="main-message right-chat-container">
        <div class="searchMsg">
          <div class="marshal-field">

            <div class="arrow_back toggle-chat-btn" (click)="toggleChat()" title="Back">
              <mat-icon>arrow_back_ios</mat-icon>
            </div>

            <p class="marshalname mb-0">{{marshalName}}</p>
            <!-- <mat-icon  aria-hidden="false" fontIcon="menu" class="toggle-chat-btn"></mat-icon> -->
          </div>
          
          <!-- <i class="material-icons searchmsg-search d-inline-block ms-3" aria-hidden="true">search</i>
          <input type="text" [disabled]="allMarshals.length == 0" autocomplete="off" mat-input
            (keyup)="searchMsg($event.target.value)" id="searchVal"
            placeholder="{{ 'chat.labels.Search Message' | translate }}">
          <div class="arrows mt-3" *ngIf="searchCount">
            <div class="me-2 d-inline count">{{searchCount}}</div>
            <mat-icon (click)="searchUp('down')">keyboard_arrow_down</mat-icon>
            <mat-icon (click)="searchUp('up')">keyboard_arrow_up</mat-icon>
            <div class="ms-2 d-inline reset cursor-pointer" (click)="resetSearch('cancel')">
              {{ 'chat.labels.Cancel' | translate }}</div>
          </div> -->

        </div>
        <ng-container *ngFor="let marshal of allMarshals; let i= index">
          <div class="mesgs" *ngIf="selectedMarshal === marshal.groupDetailsCode">
            <div [ngClass]="'msg_history'+i" class="msg_history" id="chats">
              <div *ngIf="chatData.length> 0">
                <div *ngFor="let chat of chatData; let chatloop = index">

                  <div *ngIf="userType !== 'Staff' && userType !== 'Customer'">
                    <div class="incoming_msg" *ngIf="chat.isAdmin == false">
                      <div class="received_msg d-flex">
                        <div class="col-2 p-0 align-self-start text-center
                      chat-profile-icon">
                          <div *ngIf="chat.image">
                            <img alt="No Image" class="name-initials" [src]="chat.image" />
                          </div>
                          <div *ngIf="!chat.image">
                            <div *ngIf="chat.fromName.split(' ').length >= 2" class="name-initials">
                              {{chat.fromName.split('
                              ')[0].charAt(0).toUpperCase()}}{{chat.fromName.split('
                              ')[1].charAt(0).toUpperCase()}}
                            </div>
                            <div *ngIf="chat.fromName.split(' ').length == 1" class="name-initials">
                              {{chat.fromName.charAt(0).toUpperCase()}}
                            </div>
                          </div>

                          <!-- <span class="time_date"> {{chat.finalDate.split(' ')[1]}}
                          {{chat.finalDate.split(' ')[2]}}</span> -->

                        </div>
                        <div class="col-10 p-0">
                          <div class="received_withd_msg">
                            <label class="chat-name">{{chat.fromName}} </label>
                            <p [id]="chat.id" class="txtMsgBody">{{chat.message}}</p>
                            <span class="time_date"> {{chat.finalDate}}
                              <span *ngIf="chat?.isRead" class="readIc _read" title="Click for Mark As Unread"
                                (click)="messageMarkAsRead(chat)"><mat-icon>done_all</mat-icon></span>
                              <span *ngIf="!chat?.isRead" class="readIc" title="Click for Mark As Read"
                                (click)="messageMarkAsRead(chat)"><mat-icon>done</mat-icon></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="outgoing_msg pe-2" *ngIf="chat.isAdmin == true">
                      <div class="sent_msg d-flex">
                        <div class="col-10 p-0">
                          <label class="chat-name">{{chat.fromName}}</label>
                          <p [id]="chat.id" class="hide-del txtMsgBody">{{chat.message}}
                            <!-- <span title="More options" class="float-end dots-icon"
                            [ngClass]="{'show-dot-icon': showPopupId == chat.id}">
                            <i class="material-icons cursor-pointer menu-icon" (click)="geteditData(chat.id)">
                              more_vert
                            </i>
                          </span> -->

                          </p>
                          <span class="time_date"> {{chat.finalDate}}</span>
                          <!-- <div title="More options" class="col-md-1 text-end" (click)="geteditData(chat.id)">
                        
                      </div> -->

                          <div *ngIf="showPopupId == chat.id" class="edit-open col-md-9">
                            <ul>
                              <li (click)="deleteMessage(chat.id,marshal)">
                                <span class="delete text-end float-end material-icons">
                                  delete_outline
                                </span> Remove
                              </li>

                            </ul>
                          </div>


                        </div>

                        <div class="col-2 p-0 align-self-start text-center
                      chat-profile-icon">
                          <div *ngIf="chat.image">
                            <img alt="No Image" class="name-initials" [src]="chat.image" />
                          </div>
                          <div *ngIf="!chat.image">

                            <div *ngIf="chat.fromName.split(' ').length >= 2" class="name-initials">
                              {{chat.fromName.split('
                              ')[0].charAt(0).toUpperCase()}}{{chat.fromName.split('
                              ')[1].charAt(0).toUpperCase()}}
                            </div>
                            <div *ngIf="chat.fromName.split(' ').length == 1" class="name-initials">
                              {{chat.fromName.charAt(0).toUpperCase()}}
                            </div>
                          </div>

                          <!-- <div class="time_date">{{chat.finalDate.split(' ')[1]}}
                          {{chat.finalDate.split(' ')[2]}}</div> 
                        <div class="time_date"> {{chat.finalDate}}
                        </div>-->
                        </div>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="userType === 'Staff'">
                    <div class="incoming_msg" *ngIf="chat.isAdmin == true">
                      <div class="received_msg d-flex">
                        <div class="col-2 p-0 align-self-start text-center
                      chat-profile-icon">
                          <div *ngIf="chat.image">
                            <img alt="No Image" class="name-initials" [src]="chat.image" />
                          </div>
                          <div *ngIf="!chat.image">
                            <div *ngIf="chat.fromName.split(' ').length >= 2" class="name-initials">
                              {{chat.fromName.split('
                              ')[0].charAt(0).toUpperCase()}}{{chat.fromName.split('
                              ')[1].charAt(0).toUpperCase()}}
                            </div>
                            <div *ngIf="chat.fromName.split(' ').length == 1" class="name-initials">
                              {{chat.fromName.charAt(0).toUpperCase()}}
                            </div>
                          </div>

                          <!-- <span class="time_date"> {{chat.finalDate.split(' ')[1]}}
                          {{chat.finalDate.split(' ')[2]}}</span> -->

                        </div>
                        <div class="col-10 p-0">
                          <div class="received_withd_msg">
                            <label class="chat-name">{{chat.fromName}} </label>
                            <p [id]="chat.id" class="txtMsgBody">{{chat.message}}</p>
                            <span class="time_date"> {{chat.finalDate}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="outgoing_msg pe-2" *ngIf="chat.isAdmin == false">
                      <div class="sent_msg d-flex">
                        <div class="col-10 p-0">
                          <label class="chat-name">{{chat.fromName}}</label>
                          <p [id]="chat.id" class="hide-del txtMsgBody">{{chat.message}}
                            <span title="More options" class="float-end dots-icon"
                              [ngClass]="{'show-dot-icon': showPopupId == chat.id}">
                              <i class="material-icons cursor-pointer menu-icon" (click)="geteditData(chat.id)">
                                more_vert
                              </i>
                            </span>
                          </p>
                          <span class="time_date"> {{chat.finalDate}} </span>
                          <!-- <div title="More options" class="col-md-1 text-end" (click)="geteditData(chat.id)">
                        
                      </div> -->

                          <div *ngIf="showPopupId == chat.id" class="edit-open col-md-9">
                            <ul>
                              <li (click)="deleteMessage(chat.id,marshal)">
                                <span class="delete text-end float-end material-icons">
                                  delete_outline
                                </span> Remove
                              </li>

                            </ul>
                          </div>


                        </div>

                        <div class="col-2 p-0 align-self-start text-center
                      chat-profile-icon">
                          <div *ngIf="chat.image">
                            <img alt="No Image" class="name-initials" [src]="chat.image" />
                          </div>
                          <div *ngIf="!chat.image">

                            <div *ngIf="chat.fromName.split(' ').length >= 2" class="name-initials">
                              {{chat.fromName.split('
                              ')[0].charAt(0).toUpperCase()}}{{chat.fromName.split('
                              ')[1].charAt(0).toUpperCase()}}
                            </div>
                            <div *ngIf="chat.fromName.split(' ').length == 1" class="name-initials">
                              {{chat.fromName.charAt(0).toUpperCase()}}
                            </div>
                          </div>

                          <!-- <div class="time_date">{{chat.finalDate.split(' ')[1]}}
                          {{chat.finalDate.split(' ')[2]}}</div> -->

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="2"
                [infiniteScrollThrottle]="50" (scrolledUp)="scrolledUp($event)"
                [infiniteScrollContainer]="'.msg_history'+i" [fromRoot]="true"></div>
            </div>
            <div class="type_msg">
              <span *ngIf="isTypeing" class="search-chat-typing"> {{typingName}}
                is typing <img src="assets/typint_dots_black.gif"></span>
              <form [formGroup]="chatForm" (ngSubmit)="onSendMessage()" autocomplete="off">
                <div class="row">
                  <div class="col-10">
                    <div class="input_msg_write">
                      <!-- <mat-form-field appearance="outline" floatLabel="always"  appearance="outline" class="w-100 p-0">
                  <mat-label>{{ 'chat.labels.Type a message' | translate }}</mat-label>
                  <input [id]="marshal.groupDetailsCode" matInput placeholder="" #msg formControlName="messages"
                    (keyup)="onKeyUp($event)">
                  <button mat-icon-button class="msg_send_btn" type="submit" title="Send Message"><i
                      class="material-icons"> send </i></button>
                </mat-form-field> -->

                      <div class="w-100  text-start">
                        <input type="text" class="form-control" autocomplete="off"
                          placeholder="{{ 'chat.labels.Type a message' | translate }}" name="type-msg"
                          [id]="marshal.groupDetailsCode" #msg formControlName="messages" (keyup)="onKeyUp($event)">

                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-center">
                    <button mat-icon-button class="msg_send_btn" type="submit" title="Send Message">
                      <i class="material-icons" style="padding: 8px !important;"> send </i></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- <div class="no-users" *ngIf="allMarshals.length == 0 && messageLoad">No users present for chat</div> -->
</div>
 