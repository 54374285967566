import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray, FormBuilder, ValidationErrors } from '@angular/forms';
import { TechnicianService } from '../technician.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable, of, Subject } from 'rxjs';
import { startWith, map, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
 
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { IApiResponse } from 'src/app/shared/interface/core.interface';
  
const moment = _moment;
declare var google;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd DD MMMM YYYY',
    monthYearLabel: 'ddd DD MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'ddd DD MMMM YYYY',
  },
};

 

@Component({
  selector: 'app-technician-edit',
  templateUrl: './technician-edit.component.html',
  styleUrls: ['./technician-edit.component.scss'],
  providers: [DatePipe]
  // providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  //   { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
  // encapsulation: ViewEncapsulation
})
export class TechnicianEditComponent implements OnInit, OnDestroy , AfterViewInit{

  @ViewChild('email', { static: false }) email;
  workerCode;
  personalform: FormGroup;
  professionalForm: FormGroup;
  approvalStatus: FormGroup;
  New_ProfessionalForm : FormGroup;
  submitted = true;
  skills = [];
  certifications = [];
  dropdownSettings = {};
  dropdownSettings1 = {};
  private _unsubscribeAll = new Subject<void>();
  skillsList = [];
  certificationsList = [];
  chipSkills = [];
  chipCertificaitons = [];
  personalSubmitted;
  userName;
  userDesignation;
  isUserActive: boolean = false;
  selectedDept;
  isActivated;

  filteredOptionsSkill: Observable<any>;
  filteredCertificateOptions: Observable<any>;
  skillSelected = [];
  certificationsSelected = [];

  imageupload = false;
  fileUrl: any;
  filetitle: any;

  personal = true;
  professional = false;

  userSkill: any;
  userProfession: any;

  @ViewChild('password', { static: true }) password;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options = { types: [], componentRestrictions: { country: ["us", 'in'] } };
  lat: any;
  lng: any;
  filteredOptions: Observable<any>;
  homeCountryCode = this.technicianService.common.CountryCode;
  workerCountryCode = this.technicianService.common.CountryCode;
  empTypeCode: any;
  empTypeName: any;
  selectedLang = 'English';
  currenYear = new Date();
  extendedYear;
  breakYear;
  minDate: string;
  maxDate;
  employeeType: any;
  selectedEmpType;
  updatedAddress: any;

  @ViewChild('mapDis', { static: true }) mapDis;
  directionsService;
  directionsDisplay;
  map;
  isZoom: boolean = false;
  // marker;
  myLatlng = new google.maps.LatLng(32.483, 16.084);

  currentOrganization: any = {
    'dateFormat':'',
    'inactiveReason' : '',
    'inactiveTime' : '',
     'isApproved':'',
     'isActivated':''
  };
  
  //currentOrganization: any 
  date = new Date()
  formatedDate = moment(this.date).format('YYYY-MM-DD');
  certificatExpired = [
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' },
  ]
  certificatedate = [];
  cancelPersoanl = false;
  cancelProfesional = false;
   img_serverPath ;
   is_FormSubmitted : boolean = false;
   skillSeachInput:FormControl = new FormControl();
  // isFileUploading: boolean[][] = [[], []]; 

 // routeNavigationTitle:string;
  proffTab_Interval;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  activeTab:string = "Personal" ;
  
  protected userType:string;
  checkForFormStatus : string = '' ;

  workersAllUploadedDocs : any;
  
  constructor(
    public dialogRef: MatDialogRef<TechnicianEditComponent>,
    @Inject(MAT_DIALOG_DATA) public popupDetailViewData: any,
    public technicianService: TechnicianService,
    public router: Router, public route: ActivatedRoute,
    public datepipe: DatePipe, public common: CommonService, 
    public translate: TranslateService, private dialog: MatDialog, 
     )
     {
    this.translate.setDefaultLang(this.common.local.get('language'))
    this.technicianService.common.sub = this.technicianService.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.technicianService.common.showConfirmPopup();
        }
      });
  }

  /* Name:Shailesh
      use: for getting current location*/
  getCurrentLocation(callback) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      ////console.log(position);
      callback(position);

    }, function () {
      // handleLocationError(true, infoWindow, map.getCenter());
    });
  }

  /* Name:Shailesh
      use: for getting current location of clinician */
  initialize(latitude = null, longitude = null) {
    let newLatLang;

    if (latitude && longitude) {

      //console.log(this.personalform.value.address);
      newLatLang = new google.maps.LatLng(latitude, longitude)
    } else {

      this.getCurrentLocation((response) => {
        ////console.log(response);
        newLatLang = new google.maps.LatLng(response.coords.latitude, response.coords.longitude);
      })
    }
    setTimeout(() => {
      ////console.log(newLatLang)
      this.isZoom = false;
      this.map = new google.maps.Map(document.getElementById('map'),
        {
          zoom: 18,
          center: newLatLang,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true
        });


      let marker = new google.maps.Marker({
        map: this.map,
        position: newLatLang,
        draggable: true,
        icon: { url: './../../assets/location_1.png' },
      });
      let geocoder = new google.maps.Geocoder();
      let infowindow = new google.maps.InfoWindow();
      if(this.personalform.value.address) {
        let addressName = '';
        if(!this.technicianService.personalWorker.apartmentNo && this.technicianService.personalWorker.address){
          addressName += this.technicianService.personalWorker.address+','+this.technicianService.personalWorker.city+','+this.technicianService.personalWorker.state+','+this.technicianService.personalWorker.country+','+this.technicianService.personalWorker.postCode;
        } 
        if(!this.technicianService.personalWorker.apartmentNo && !this.technicianService.personalWorker.address) {
          addressName += this.technicianService.personalWorker.city+','+this.technicianService.personalWorker.state+','+this.technicianService.personalWorker.country+','+this.technicianService.personalWorker.postCode;
        }
        if (this.technicianService.personalWorker.apartmentNo && !this.technicianService.personalWorker.address){
          addressName += this.technicianService.personalWorker.apartmentNo +','+this.technicianService.personalWorker.city+','+this.technicianService.personalWorker.state+','+this.technicianService.personalWorker.country+','+this.technicianService.personalWorker.postCode;
        }
        if (this.technicianService.personalWorker.apartmentNo && this.technicianService.personalWorker.address){
          addressName += this.technicianService.personalWorker.apartmentNo +','+this.technicianService.personalWorker.address+','+this.technicianService.personalWorker.city+','+this.technicianService.personalWorker.state+','+this.technicianService.personalWorker.country+','+this.technicianService.personalWorker.postCode;
        }
        infowindow.setContent(addressName);
        infowindow.open(this.map, marker);
      } else {
              geocoder.geocode({ 'latLng': newLatLang }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                  //console.log(results);
                  //console.log(this.personalform.value.address);
                  if (results[0]) {
                    infowindow.setContent(results[0].formatted_address);
                    infowindow.open(this.map, marker);
                  }
                }
              });

      }

      this.getMarkerData(marker, geocoder, infowindow, (responseData) => {
        this.handleAddressChange(responseData);
      })
    }, 1000);


  }

  /* Name:Shailesh
      use: for getting clinician marker data */
  getMarkerData(marker, geocoder, infowindow, callback) {
    google.maps.event.addListener(marker, 'dragend', function () {
      ////console.log(marker);
      ////console.log(marker.getPosition());
      geocoder.geocode({ 'latLng': marker.getPosition() }, function (results, status) {
        ////console.log(results, status);
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            infowindow.setContent(results[0].formatted_address);
            infowindow.open(this.map, marker);
            callback(results[0]);
          }
        }
      });
    });
  }

 
  ngOnDestroy() {
    this.technicianService.common.sub.unsubscribe();
     
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    clearInterval(this.proffTab_Interval);
  }

  ngOnInit() {
   // console.log('PopupDetailViewData: ', this.popupDetailViewData.staffCode, this.popupDetailViewData.isStaffDetailOpenInPopup);
    this.img_serverPath = this.technicianService.service.getSuperAdmin() + '/downloadFile/';
    
    // comment_code : professional_skills_001
    // this.InitializeProfessionalForm()

    this.resetFormData();
    this.extendedYear = this.currenYear.getFullYear();
    this.extendedYear = this.extendedYear + 20;
    const data = this.extendedYear - 38;
    this.breakYear = data + '-12-31';
    this.maxDate = this.extendedYear + '-12-31';
    this.minDate = new Date().toISOString().split('T')[0];
    this.isActivated = this.technicianService.personalWorker.organizationWiseApprovalList[0].isActivated

    this.dropdownSettings = {
      singleSelection: false,
      text: "Skills List",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      // badgeShowLimit: 5,
      autoPosition: true,
    };

    this.dropdownSettings1 = {
      singleSelection: false,
      text: "Certification List",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      // badgeShowLimit: 5,
      autoPosition: true,
    };

    this.personalform.valueChanges.subscribe((val) => {
      ////console.log(val);
      this.cancelPersoanl = true;
    });

    this.professionalForm.valueChanges.subscribe((val) => {
     // //console.log(val);
      this.cancelProfesional = true;
    });


    this.userType = this.common.local.get("user_type");

  }

  // confirmEmailAvailability(control: FormControl): Promise<any> | Observable<any> {
  //   const emailavaiable = new Promise<any>(
  //     (resolve, reject) => {

  //       this.technicianService.getEmailAvailabilityForUpdate(control.value, this.workerCode, (response) => {
  //         //////console.log(response);
  //         if (response == false) {

  //           resolve({ invalid: true });
  //         } else {
  //           if (control.parent.get('adminemail')) {
  //             if (control.value !== this.email.nativeElement.value) {
  //               resolve(null);
  //             } else {
  //               resolve({ invalid: true });
  //             }
  //           } else {
  //             resolve(null);
  //           }
  //         }
  //       });
  //     });
  //   return emailavaiable;
  // }
 


  confirmEmailAvailability(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null); // Skip validation if no value is entered
    }
  
    return of(control.value).pipe(
      switchMap((value) => {
        return this.technicianService.getEmailAvailabilityForUpdate(value, this.workerCode).pipe(
          map((response) => {

            if (response == false) {

              return { invalid: true };
            }
            else {
              if (control.parent.get('adminemail')) {
                if (control.value !== this.email.nativeElement.value) {
                  return null;
                } else {
                  return { invalid: true };
                }
              } else {
                return null;
              }
            }
       }),
          catchError(() => of(null)) // Handle any errors during API call
        );
      })
    );
  }





  /* Name:Shailesh
      use: for resetting form data and declaring all form control */
  resetFormData() {
    this.technicianDataReset();

    this.professionalForm = new FormGroup({
      'skills': new FormControl(null),
      'skillsSearch': new FormControl(null, Validators.required),
      'certifications': new FormControl(null),
      'certificationsSearch': new FormControl(null),
      'employmentType': new FormControl(null, Validators.required),
    })

    this.personalform = new FormGroup({
      'workerLogo': new FormControl(null),
      // 'name': new FormControl(null, [Validators.required,Validators.minLength(3), Validators.pattern(this.technicianService.common.onlyNameWithSpace)]),
      'name': new FormControl(null, [Validators.required,Validators.minLength(1)]),
      'lastName': new FormControl(null, [Validators.required,Validators.minLength(1)]),
      'email': new FormControl(null, [Validators.required, Validators.email], this.confirmEmailAvailability.bind(this)),
      'password': new FormControl(null),
      'cpassword': new FormControl(null),
      'mobileNo': new FormControl(null, [Validators.required, Validators.pattern(this.technicianService.common.phonenumber)]),
      'emergencyNo': new FormControl(null, [Validators.pattern(this.technicianService.common.phonenumber)]),
      'address': new FormControl(null),
      // 'city': new FormControl(null, [Validators.required, Validators.pattern(this.technicianService.common.onlyNameWithSpace)], this.apartmentValid.bind(this)),
      // 'state': new FormControl(null, [Validators.required, Validators.pattern(this.technicianService.common.onlyNameWithSpace)], this.apartmentValid.bind(this)),
      // 'postCode': new FormControl(null, [Validators.required, Validators.pattern(this.technicianService.common.postCode)], this.apartmentValid.bind(this)),
      // 'country': new FormControl(null, [Validators.required, Validators.pattern(this.technicianService.common.onlyNameWithSpace)], this.apartmentValid.bind(this)),
      'city': new FormControl(null, [Validators.pattern(this.technicianService.common.onlyNameWithSpace)]),
      'state': new FormControl(null, [Validators.pattern(this.technicianService.common.onlyNameWithSpace)]),
      'postCode': new FormControl(null, [Validators.pattern(this.technicianService.common.postCode)]),
      'country': new FormControl(null, [Validators.pattern(this.technicianService.common.onlyNameWithSpace)]),
      'workerCountryCode': new FormControl(this.workerCountryCode, Validators.required),
      
      'homeCountryCode': new FormControl(this.homeCountryCode, Validators.required),
      "dob": new FormControl(null),
      'employmentType': new FormControl(null),
      // 'fullAddress': new FormControl(null, [Validators.required], this.apartmentValid.bind(this)),
      // 'apartmentNo': new FormControl(null, [Validators.required], this.apartmentValid.bind(this)),
      'fullAddress': new FormControl(null),
      'apartmentNo': new FormControl(null,),

      'departmentCode': new FormControl(null),
      'isActivated': new FormControl(null),
      'inactiveReason': new FormControl(null),
      'legalName': new FormControl(null),
      'parmanentAddress': new FormControl(null),
      'notes' : new FormControl(null)
    })

    this.approvalStatus = new FormGroup({
      'isApproved': new FormControl(null, Validators.required)
    })
    this.getAllSkillsByOrgCode();
    this.getAllCertificationByOrgCode();
    this.getAllEmploymentType();
    this.technicianService.personalWorker.homeCountryCode = this.homeCountryCode;

    if(this.popupDetailViewData && this.popupDetailViewData?.staffCode && this.popupDetailViewData.isStaffDetailOpenInPopup){
      this.workerCode = this.popupDetailViewData.staffCode;
      // console.log('data_1: ', this.workerCode, this.popupDetailViewData);
      this.getUserPersonalData();
      this.get_staffAllUploadedDocs();
    }

    /* Name:Shailesh
      use: for getting wokrer personal and professional detail */
    try {
      this.route.queryParams.subscribe((data) => {
        if (data.workerCode) {
          this.workerCode = data.workerCode;         
          if(this.workerCode){
            this.getUserPersonalData();
            this.get_staffAllUploadedDocs();
            // this.getDistributionUserListByWorkerCode(this.workerCode);
          }

          // comment_code : professional_skills_001
          // this.getWorkerProfileProfessionalData();

          // try {
          //   this.technicianService.getWorkerProfileProfessionalTabforMobileByworkerCode(this.workerCode, (response) => {
          //     //console.log(response);
          //     if (response.responsecode == 200) {
          //       this.technicianService.professionalWorker = response.data;
          //       if (response.data.skilmaster.length > 0) {
          //         this.professionalForm.controls['skillsSearch'].setValue(JSON.stringify(response.data.skilmaster));
          //       }
          //       if (response.data.certificationmasterResList && response.data.certificationmasterResList.length > 0) {

          //         this.professionalForm.controls['certifications'].setValue(JSON.stringify(response.data.certificationmaster));
          //         this.certificationsSelected = response.data.certificationmasterResList;
          //         this.technicianService.professionalWorker.certificationmaster = response.data.certificationmasterResList;
          //         this.technicianService.professionalWorker.certificationmaster.forEach((ele, i) => {
          //           ele.viewDate = moment(ele.certificationExpirationDate);
          //           //console.log(ele.viewDate)
          //           let date = new FormControl(ele.viewDate)
          //           this.certificatedate.push(date.value);
          //         })
          //       } else {
          //         this.technicianService.professionalWorker.certificationmaster = [];
          //       }
          //     }
          //     setTimeout(() => {
          //       this.cancelProfesional = false;
          //     }, 500);
          //   })
          // } catch (error) {
          //   //console.log(error);
          // }
 

        }
      })
    } catch (error) {
      //console.log(error);
    }

 
    // this.filteredCertificateOptions = this.professionalForm.controls['certifications'].valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(name => this._filter(name, 'certificate'))
    //   );

  }


  ngAfterViewInit() {
    this.route.queryParams.subscribe((data) => {
      if(data.activeTab && data.activeTab.toLowerCase() === 'exception')
      {
         this.tabGroup.selectedIndex = 2;
         this.activeTab = "Exceptions" ;
      }
    })
  }


  getUserPersonalData(){
    if(this.workerCode){
      this.technicianService.common.progressLoader = true;
      this.technicianService.getWorkerProfilePersonalTabforMobileByworkerCode(this.workerCode, this.common.local.get('orgCode'), (response) => {
        this.technicianService.common.progressLoader = false;
        //console.log(response);
        if (response.responsecode == 200) {
          this.technicianService.personalWorker = response.data;

          if(this.technicianService.personalWorker?.organizationWiseApprovalList
            &&  this.technicianService.personalWorker?.organizationWiseApprovalList?.length > 0
          )
          {
            this.technicianService.personalWorker.organizationWiseApprovalList.forEach((elementData) => {
              if (elementData.organizationCode == this.technicianService.local.get('orgCode')) {
                this.currentOrganization = elementData;
              }
           });
          }
        
          const fullName :  string = response.data.name ?
                                     response.data.name
                                     : `${response.data.firstName} ${response.data.lastName}`

          // this.userName = response.data.name;
          this.userName = fullName;
          this.isUserActive = response.data.isActive;

          if (response.data.latitude && response.data.longitude) {
            this.technicianService.personalWorker.location = response.data.location;
            this.personalform.controls['address'].setValue(response.data.location);
           // this.initialize(response.data.latitude, response.data.longitude);
           

          } 
          // else {
          //   this.initialize();
          // }

          if (this.technicianService.personalWorker.homeCountryCode) {
            this.homeCountryCode = this.technicianService.personalWorker.homeCountryCode;
            this.personalform.controls['homeCountryCode'].setValue(this.homeCountryCode);
          }
          if (this.technicianService.personalWorker.workerCountryCode) {
            this.workerCountryCode = this.technicianService.personalWorker.workerCountryCode;
            this.personalform.controls['workerCountryCode'].setValue(this.workerCountryCode);
          } else {
            this.technicianService.personalWorker.workerCountryCode = this.workerCountryCode;
          }

          if (response.data.workerLogo) {
            this.fileUrl = this.technicianService.service.getSuperAdmin() + '/downloadFile/' + response.data.workerLogo;
            this.technicianService.professionalWorker.workerLogo = response.data.workerLogo;
            this.imageupload = true;
          }
          if (response.data.dob) {
            let dob = response.data.dob.split(' ')[0];
            const time = '00';
            let hours = '00';
            const month = dob.split('-');
            const newDate = new Date(+month[0], +month[1] - 1, +month[2]);
            this.personalform.controls['dob'].setValue(moment(newDate));
            this.technicianService.personalWorker.dob = +dob.split('-')[1] + '/' + +dob.split('-')[0] + '/' + +dob.split('-')[2];
          }
        }
        setTimeout(() => {
          this.cancelPersoanl = false;
        }, 1000)
      })
    }
  }

  /* Name:Shailesh
      use: for getting lat and long */
  codeLatLng(response, callback) {
    let geocoder = new google.maps.Geocoder();

    let newLatLang = new google.maps.LatLng(response.data.latitude, response.data.longitude)

    geocoder.geocode({ 'latLng': newLatLang }, function (results, status) {
      callback(results);
    });
  }

 

  /* Name:Shailesh
      use: for checking confirm password*/
  apartmentValid(control: AbstractControl): Promise<any> | Observable<any> {
    const confirmPassword = new Promise<any>(
      (resolve, reject) => {
        if (control.value.trim()) {
          resolve(null);
        } else {
          resolve({
            invalid: true
          });
        }
      });
    return confirmPassword;
  }


  onSubmit() {
    // //console.log('onSubmit_1:', this.technicianService.personalWorker);
    // //console.log('onSubmit_2:', this.personalform);
    try {
       if (this.personalform.valid) {      
        this.technicianService.personalWorker.noteRequest = this.technicianService?.common?.noteRequest ? this.technicianService.common.noteRequest : null;
        this.technicianService.personalWorker.firstName = this.technicianService.personalWorker.firstName.trim();
        this.technicianService.personalWorker.lastName = this.technicianService.personalWorker.lastName.trim();
        this.technicianService.personalWorker.name = this.technicianService.personalWorker?.name?.trim();

        if(!this.personalform.controls['address'].value) {
          this.technicianService.personalWorker.location = '';
        } 

        if(!this.personalform.controls['parmanentAddress'].value) {
          this.technicianService.personalWorker.parmanentAddress = '';
        }

      this.technicianService.personalWorker.dob = this.datepipe.transform(this.personalform.value.dob, 'yyyy-MM-dd');
     
      this.technicianService.common.progressLoader = true;
      this.submitted = true;
     
      this.technicianService.updateworkerProfilePersonelTabByworkerCodeNew(this.workerCode, this.technicianService.personalWorker, (response) => {
        if (response.responsecode == 200) {
          this.technicianService.personalWorker.noteRequest = null;
          this.technicianService.common.resetNotesRequestForm();
          this.technicianService.common.message = response.message;
          this.technicianService.common.progressLoader = false;
          this.technicianService.common.successMessage = true;
          // this.popupDetailViewData.isStaffDetailOpenInPopup ? this.closeDialog() : '';   Code Use close popup on success
          // this.router.navigate(['/dashboard/technician/technician-list']);
          // this.router.navigate(['/dashboard/staffmember/member-list']);
          this.getUserPersonalData();
           
        } else {
          this.technicianService.common.message = response.message;
          this.technicianService.common.progressLoader = false;
          this.technicianService.common.errorMessage = true;
        }
      })

    } else {
      this.submitted = false;
    }
    } catch (error) {

        //console.log(error)
    }
   

  }

  /* Name:Shailesh
       use: for updating clinician detail */
  onSubmitPro() {
    try {
      if (this.professionalForm.valid) {
        this.technicianService.common.progressLoader = true;
        this.submitted = true;
        let isCertificate = true;        
        this.technicianService.professionalWorker.certificationmaster.forEach((element) => {
          if (!element.certificationUrl) {
            isCertificate = false;
          }
        });
        setTimeout(() => {
          if (isCertificate) {
            this.technicianService.updateWorkerProfileProfessionalTabforMobileByworkerCode(this.workerCode, this.technicianService.professionalWorker, (res) => {
              this.technicianService.common.progressLoader = false;
              if (res.responsecode == 200) {
                this.technicianService.common.message = res.message;
                this.technicianService.common.successMessage = true;
              } else {
                this.technicianService.common.message = res.message;
                this.technicianService.common.errorMessage = true;
              }
            })
          } else {
            this.technicianService.common.progressLoader = false;
            this.technicianService.common.message = 'Please upload certificate';
            this.technicianService.common.errorMessage = true;
          }
        }, 500);
      } else {
        this.submitted = false;
      }
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Shailesh
        use: for getting skill list*/
  getAllSkillsByOrgCode() {
    try {
      this.technicianService.common.getSkillsByOrgCode(this.technicianService.common.local.get('orgCode'), (data) => {
      if (data.responsecode = 200) {
        this.skills = data.data;

         
    this.filteredOptionsSkill = this.skillSeachInput.valueChanges
    .pipe(takeUntil(this._unsubscribeAll),
      startWith(''),
      map(name => this._filter(name, 'skill'))
    );


      }
    });
    } catch (error) {
      //console.log(error)
    }
    
  }

  /* Name:Shailesh
      use: for getting certificate list */
  getAllCertificationByOrgCode() {
    try {
        this.technicianService.common.getCertificationByOrgCode(this.technicianService.common.local.get('orgCode'), (data) => {
      if (data.responsecode = 200) {
        //console.log(data.data);
        this.certifications = data.data;
      }
    });
    } catch (error) {
      //console.log(error)
    }
  
  }

  /* Name:Shailesh
      use: for getting all employment type */
  getAllEmploymentType() {
    try {
      this.technicianService.getAllEmploymentType((response) => {
      if (response.responsecode == 200) {
        this.employeeType = response.data;
        setTimeout(() => {
          if (!this.technicianService.personalWorker.employmentTypeCode) {
            this.technicianService.personalWorker.employmentTypeCode = this.employeeType[0].employmentTypeCode;
          }
        }, 500);

      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

  displayFn(skill): string | undefined {
    return skill ? skill.skillName : undefined;
  }

  certificatedisplayFn(certification): string | undefined {
    return certification ? certification.certificationName : undefined;
  }

  /* Name:Shailesh
      use: for searching skill list */
  private _filter(name, field) {
    //console.log(name, field);
    if (field == 'skill') {
      if (name && name != '') {
        const filterValue = name;
        return this.skills.filter(option => option.skillName.toLowerCase().indexOf(filterValue) === 0);

      } else {
        //console.log('name');
        return this.skills;
      }
    } else {
      if (name) {
        //console.log('IN');
        const filterValue = name;
        return this.certifications.filter(option => option.certificationName.toLowerCase().indexOf(filterValue) === 0);

      } else {
        //console.log('OUT');
        return this.certifications;
      }
    }
  }


  /* Name:Shailesh
      use: for selecting skill and certificate */
  selectedOption(event, field) {
    if (field === 'skill') {
      const index = this.technicianService.professionalWorker.skilmaster.findIndex(fruit => fruit.skillName === event.option.value.skillName);
      if (index == -1) {
        this.professionalForm.controls['skillsSearch'].setValue(event.option.value);
        this.technicianService.professionalWorker.skilmaster.push(event.option.value);
        this.professionalForm.controls['skills'].setValue(null);
      } else {
        this.technicianService.common.message = event.option.value.skillName + ' is already selected';
        this.technicianService.common.errorMessage = true;
      }
    } else {
      const index = this.certificationsSelected.findIndex(fruit => fruit.certificationName === event.option.value.certificationName);
      if (index == -1) {
        this.professionalForm.controls['certificationsSearch'].setValue(event.option.value);
        this.technicianService.professionalWorker.certificationmaster.push(event.option.value);
        this.professionalForm.controls['certifications'].setValue(null);
      } else {
        this.technicianService.common.message = event.option.value.certificationName + ' is already selected';
        this.technicianService.common.errorMessage = true;
      }
    }


  }

  /* Name:Shailesh
      use: for deleting skill and certificate */
  removeOrg(index, field) {
    if (field === 'skill') {
      this.technicianService.professionalWorker.skilmaster.splice(index, 1);
      if (this.technicianService.professionalWorker.skilmaster.length == 0) {
        this.professionalForm.controls['skillsSearch'].setValue(null);
      }
    } else {
      this.technicianService.professionalWorker.certificationmaster.splice(index, 1);
      if (this.technicianService.professionalWorker.certificationmaster.length == 0) {
        this.professionalForm.controls['certificationsSearch'].setValue(null);
      }
    }
  }

  /* Name:Shailesh
      use: for setting dialer code */
  getNumber(event = null, countryCode) {
    this.personalform.controls[countryCode].setValue(event.dialCode);
    this.technicianService.personalWorker[countryCode] = event.dialCode;
  }

  /* Name:Shailesh
      use: for selecting addressing */
  handleAddressChange(address) {
    //console.log(address);
    if (address.geometry) {
      this.updateServiceAddress(address);
    }
  }

  handlePermanentAddressChange(address){
    //console.log(address);
    if(address.geometry){
      let places: any;
      const componentForm = {
        route: 'long_name',
        locality: 'long_name',
        political: 'long_name',
        administrative_area_level_1: 'long_name',
        postal_code: 'short_name',
        country: 'long_name',
        street_number: 'long_name',
        sublocality:'long_name',
        sublocality_level_2:'long_name'
      };
      let addressName = '';
      for (let i = 0; i < address.address_components.length; i++) {
        const addressType = address.address_components[i].types[0];
        if (componentForm[addressType]) {
          ////console.log(addressType);
          if(addressType === 'sublocality' || addressType === 'sublocality_level_2') {
            const val = address.address_components[i][componentForm[addressType]];
            //console.log(val);
           
            addressName = val;
          }
          if (addressType === 'route') {
            const val = address.address_components[i][componentForm[addressType]];
            addressName = val;
          }
          if (addressType === 'street_number') {
            const val = address.address_components[i][componentForm[addressType]];
            addressName += ','+val;
          }

          if (addressType === 'political') {
            const val = address.address_components[i][componentForm[addressType]];
            // this.technicianService.personalWorker.address = val;
          }
          if (addressType === 'postal_code') {
            const val = address.address_components[i][componentForm[addressType]];
            addressName += ','+ val;
          }
          if (addressType === 'locality' || addressType === 'postal_town') {
            const val = address.address_components[i][componentForm[addressType]];
            addressName += ','+val;
          }
          if (addressType === 'administrative_area_level_1') {
            const val = address.address_components[i][componentForm[addressType]];
            addressName += ','+val;
          }
          if (addressType === 'country') {
            const val = address.address_components[i][componentForm[addressType]];
            addressName += ','+val;
          }
        }
      }
      this.technicianService.personalWorker.parmanentAddress = addressName;
    }
  }

  /* Name:Shailesh
      use: for validating the address */
  addressValidation(control: AbstractControl): Promise<any> | Observable<any> {
    const addressValidation = new Promise<any>(
      (resolve, reject) => {
        let addr = control.value;
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            if (addr === results[0].formatted_address) {
              resolve(null);
            } else {
              resolve({
                invalid: true
              });
            }
            // show an error if it's not
          } else {
            // //////console.log('invalid');
            resolve({
              invalid: true
            });
            //////console.log(control);
          }
        });
      });
    return addressValidation;
  }

  getAppartmentNumber(event){
    //console.log(event);
    if(event.target.value){
      //this.initialize(this.technicianService.personalWorker.latitude,this.technicianService.personalWorker.longitude);
    }
  }
  /* Name:Shailesh
      use: for updating worked address */
  updateServiceAddress(address) {
    //console.log(address)

    if (address) {

      this.technicianService.personalWorker.address = '',
        this.technicianService.personalWorker.city = '',
        this.technicianService.personalWorker.postCode = "",
        this.technicianService.personalWorker.country = "",
        this.technicianService.personalWorker.latitude = "",
        this.technicianService.personalWorker.longitude = ""
      // this.technicianService.personalWorker. = "",
      // this.technicianService.personalWorker.zipCode = ""
      this.technicianService.personalWorker.apartmentNo = '';

      let places: any;
      const componentForm = {
        route: 'long_name',
        locality: 'long_name',
        political: 'long_name',
        administrative_area_level_1: 'long_name',
        postal_code: 'short_name',
        country: 'long_name',
        street_number: 'long_name',
        sublocality:'long_name',
        sublocality_level_2:'long_name'
      };
      let addressName = '';

      for (let i = 0; i < address.address_components.length; i++) {
        const addressType = address.address_components[i].types[0];
        if (componentForm[addressType]) {
          ////console.log(addressType);
          if(addressType === 'sublocality' || addressType === 'sublocality_level_2') {
            const val = address.address_components[i][componentForm[addressType]];
            //console.log(val);
            this.personalform.controls['fullAddress'].setValue(val);
            this.technicianService.personalWorker.address = val;
          }
          if (addressType === 'route') {
            const val = address.address_components[i][componentForm[addressType]];
            ////console.log(val);
            // if (!this.technicianService.personalWorker.address) {
            this.personalform.controls['fullAddress'].setValue(val);
            this.technicianService.personalWorker.address = val;
            // }
            // this.technicianService.personalWorker.address = val;
          }
          if (addressType === 'street_number') {
            const val = address.address_components[i][componentForm[addressType]];
            ////console.log(val);
            // if (!this.technicianService.personalWorker.apartmentNo) {
            this.personalform.controls['apartmentNo'].setValue(val);
            this.technicianService.personalWorker.apartmentNo = val;
            // }
            // this.technicianService.personalWorker.address = val;
          }

          if (addressType === 'political') {
            const val = address.address_components[i][componentForm[addressType]];
            // this.technicianService.personalWorker.address = val;
          }
          if (addressType === 'postal_code') {
            const val = address.address_components[i][componentForm[addressType]];
            this.personalform.controls['postCode'].setValue(val);
            this.technicianService.personalWorker.postCode = val;
          }
          if (addressType === 'locality' || addressType === 'postal_town') {
            const val = address.address_components[i][componentForm[addressType]];
            this.personalform.controls['city'].setValue(val);
            this.technicianService.personalWorker.city = val;
          }
          if (addressType === 'administrative_area_level_1') {
            const val = address.address_components[i][componentForm[addressType]];
            // if (!this.technicianService.personalWorker.state) {
            this.personalform.controls['state'].setValue(val);
            this.technicianService.personalWorker.state = val;
            // }
          }
          if (addressType === 'country') {
            const val = address.address_components[i][componentForm[addressType]];
            // if (!this.technicianService.personalWorker.country) {
            this.personalform.controls['country'].setValue(val);
            this.technicianService.personalWorker.country = val;
            // }
          }
        }
      }
      // //////console.log(address.geometry.location.lat())
      this.technicianService.personalWorker.latitude = address.geometry.location.lat();
      this.technicianService.personalWorker.longitude = address.geometry.location.lng();
      // //////console.log(this.technicianService.personalWorker);
      // this.showMapmarker(this.service.workerHome.latitude ,this.service.workerHome.longitude);
      if(address.name){
        this.personalform.controls['address'].setValue(address.name);
        this.technicianService.personalWorker.location = address.name;
      } else {

        if(this.technicianService.personalWorker.apartmentNo && this.technicianService.personalWorker.address){
          addressName += this.technicianService.personalWorker.apartmentNo +','+ this.technicianService.personalWorker.address;
        } else {
          addressName += this.technicianService.personalWorker.address;
        }
        this.personalform.controls['address'].setValue(addressName);
        this.technicianService.personalWorker.location = addressName;
      }
      
      ////console.log(this.personalform.value.address);
      //this.initialize(this.technicianService.personalWorker.latitude, this.technicianService.personalWorker.longitude);
      // this.personalform.controls['address'].setValue(this.technicianService.personalWorker.address);
    }

  }

  /* Name:Shailesh
      use: for validating confirm password */
  confirmPassword(control: FormControl): Promise<any> | Observable<any> {
    const confirmPassword = new Promise<any>(
      (resolve, reject) => {
        // //////console.log(control.value, this.password.nativeElement.value)
        if (control.value !== this.password.nativeElement.value && this.password.nativeElement.value) {
          resolve({
            invalid: true
          });
        } else {
          resolve(null);
        }
      });
    return confirmPassword;
  }

  /* Name:Shailesh
      use: for tab change */
  tabChange(tab) {
    // //////console.log(tab);
    if (tab.index == '0') {
      if (this.personal == true) {
        this.personal = false;
      } else {
        this.personal = true;
        this.professional = false;
      }
      // this.personal = true;
    }
    if (tab.index == '1') {
    //  this.getWorkerProfileProfessionalData();
      if (this.professional == true) {
        this.professional = false;
      } else {
        this.professional = true;
        this.personal = false;
      }
      // this.professional = true;
      
    }
  }

  onCroppedImage(croppedImageFile: string): void {
    // console.log('CroppedImage: ', croppedImageFile);
    this.onFileChange(croppedImageFile)
  }


  onFileChange(event) {  
    const file = event.name && event.size ? event : event.target?.files?.[0];
    const resetInput = (message) => {
      this.technicianService.common.message = message;
      this.technicianService.common.errorMessage = true;
      if (event.target) event.target.value = '';
    };
  
    if(file){
      const formData = new FormData();
      formData.append('file', file)
      this.technicianService.common.progressLoader = true;
      this.technicianService.common.uploadImage(formData, (res) => {
        this.technicianService.common.progressLoader = false;
        if (res.responsecode === 200) {
          this.saveStaffLogo(res.filename);
        } else {
          resetInput('Failed to upload file');
        }
      });
    };    
  }
  
  

  /* Name:Shailesh
      use: for certificate upload validation*/
  onCertificateUpload(certificate, event) {
    //////console.log(event);
    // //////console.log(certificate);
    // //////console.log(this.technicianService.professionalWorker.certificationmaster);

    if (event.target.files.length > 0) {

      let allowedExtension = ['jpeg', 'jpg', 'png', 'pdf'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      let isValidFile = false;
      //////console.log(fileExtension);
      for (let index in allowedExtension) {
        if (fileExtension === allowedExtension[index] && event.target.files[0].size <= 2000000) {
          isValidFile = true;

          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          this.technicianService.common.uploadImage(formData, (res) => {
            //////console.log(res);
            this.technicianService.professionalWorker.certificationmaster.forEach(element => {
              if (element.certificationName == certificate.certificationName) {
                element.certificationUrl = res.filename;
              }
            });
          });
          break;
        }
      }

      if (!isValidFile) {
        //////console.log(isValidFile);
        this.technicianService.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 2MB';
        this.technicianService.common.errorMessage = true;
        //event.srcElement.value = '';
        event.target.value = '';
      }
    }

  }

  /* Name:Shailesh
      use: for activating worker */
  changeActive(event) {
    //////console.log(event);

    this.technicianService.personalWorker.organizationWiseApprovalList.forEach((elementData) => {
      if (elementData.organizationCode == this.technicianService.local.get('orgCode')) {
        // this.currentOrganization = elementData;
        elementData.isApproved = event.value;
      }

    })
    this.technicianService.professionalWorker.organizationWiseApprovalList.forEach((elementData) => {
      if (elementData.organizationCode == this.technicianService.local.get('orgCode')) {
        // this.currentOrganization = elementData;
        elementData.isApproved = event.value;
      }

    })
    this.technicianService.common.progressLoader = true;
    // let formData = [];technicianService.personalWorker.organizationWiseApprovalList
    try {
       this.technicianService.adminSetOrgApprovalStatusByTechnicianCode(this.workerCode, this.technicianService.personalWorker.organizationWiseApprovalList, (response) => {
      ////console.log(response);
      if (response.responsecode == 200) {
        this.technicianService.common.message = response.message;
        this.technicianService.common.progressLoader = false;
        this.technicianService.common.successMessage = true;
      } else {
        this.technicianService.common.message = response.message;
        this.technicianService.common.progressLoader = false;
        this.technicianService.common.errorMessage = true;
      }
    })
    } catch (error) {
      //console.log(error)
    }
   

  }

  getCountryCode(event, formControlName) {
    //////console.log(event, formControlName);
    this.personalform.controls[formControlName].setValue(event);
    this.technicianService.personalWorker[formControlName] = event;
  }

  /* Name:Shailesh
      use: for cancel popup  */
  cancelPopup() {
     
    if (this.cancelPersoanl || this.cancelProfesional) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          //this.resetFormData();
           // this.router.navigate(['/dashboard/technician/technician-list']);
          // this.router.navigate(['/dashboard/staffmember/member-list']);
          this.redirectAsPer_LogedInUser();
        }
      });
    } 
    else
     {
       // this.router.navigate(['/dashboard/technician/technician-list']);
       //this.router.navigate(['/dashboard/staffmember/member-list']);
       this.redirectAsPer_LogedInUser();
    }
  }


  redirectAsPer_LogedInUser()
  {
    this.router.navigate(['/dashboard/staffmember/member-list']);

    //  if(this.routeNavigationTitle && this.routeNavigationTitle === "staff_login_self_update")
    //  {
    //   // in case of Staff Login
    //   this.router.navigate(['/dashboard-technician']);
    //  }
    //  else{
    //   // in case of Admin Login
    //   this.router.navigate(['/dashboard/staffmember/member-list']);
    //  }
  }

  /* Name:Shailesh
      use: for checking active or not */
  isActive(admin) {
    //console.log(admin);
    this.technicianService.personalWorker.organizationWiseApprovalList.forEach((elementData) => {
      if (elementData.organizationCode == this.technicianService.local.get('orgCode')) {
        // this.currentOrganization = elementData;
        elementData.isActivated = admin.checked;
      }

    })

  }

  /* Name:Shailesh
      use: for to check certificate expire*/
  expirCertDate(date, event) {
    //console.log(event);
    this.technicianService.professionalWorker.certificationmaster.forEach(element => {
      element.certificationExpirationDate = moment(event.value).format("YYYY-MM-DD");
      //console.log(element.viewDate);
    });
  }

  technicianDataReset() {
    this.technicianService.personalWorker = {
      "firstName": '',
      "lastName": '',
      "address": ' ',
      "location":'',
      "city": ' ',
      'apartmentNo': '',
      "state": ' ',
      "country": ' ',
      "cpassword": ' ',
      "dob": ' ',
      "email": ' ',
      "emergencyNo": ' ',
      "employmentTypeCode": ' ',
      "employmentTypeName": ' ',
      "homeCountryCode": ' ',
      "latitude": ' ',
      "longitude": ' ',
      "mobileNo": ' ',
      "name": ' ',
      'legalName':'',
      'parmanentAddress':'',
      "organizationWiseApprovalList": [
        {
          "dateFormat": ' ',
          "inactiveReason": ' ',
          "inactiveTime": ' ',
          "isActivated": true,
          "isApproved": true,
          "organizationCode": ' '
        }
      ],
      "password": ' ',
      "postCode": ' ',
      "workerCountryCode": ' ',
      "workerLogo": ' ',
      "departmentCode": '',
       "notes" : '',
      "noteRequest": null,
      "noteList" : ''
    };

    this.technicianService.professionalWorker = {
      "certificationmaster": [
        {
          "certificationCode": "",
          "certificationName": "",
          "certificationUrl": "",
          "isAssigned": true,
          "organizationCode": "",
          "organizationName": "",
          "skillCode": "",
          "skillName": "",
          "certificationExpirationDate": "",
          "isCertificateExpired": '',
          "viewDate": moment()
        }
      ],
      "employmentTypeCode": "",
      "organizationWiseApprovalList": [
        {
          "isApproved": true,
          "organizationCode": ""
        }
      ],
      "skilmaster": [
        {
          "skillCode": "",
          "skillName": ""
        }
      ],
      "workerLogo": ""
    };
  }

  
  /** 
   *  comment_code : professional_skills_001   commented from
   *  use above code to find out related commented codes
   * /

//   /* Professional Tab Work  New UI Code */

//   getWorkerProfileProfessionalData(){
//     try {
//       this.technicianService.getWorkerProfileProfessionalTabforMobileByworkerCode(this.workerCode, (response) => {
//         //console.log(response);
//         if (response.responsecode == 200) {
//           this.technicianService.professionalWorker = response.data;

//          this.proffTab_Interval =  setInterval(()=>{
//                    if(this.skills.length > 0)
//                    {
//                     this.BindWorkerProfessionForm(response.data);
//                    }
//           },1000)
//           // this.BindWorkerProfessionForm(response.data);
//         }
//         setTimeout(() => {
//           this.cancelProfesional = false;
//         }, 500);
//       })
//     } catch (error) {
//       //console.log(error);
//     }
//   }
  
//   BindWorkerProfessionForm(data:any)
//   {
    
//  clearInterval(this.proffTab_Interval);

//     this.New_ProfessionalForm.reset();
//   this.New_ProfessionalForm.controls.employmentType.setValue(data.employmentTypeCode);

//     if(data.workerSkillResposeList.length > 0)
//     {
       
//      /*clearing form array*/
//     this.skillFormsArray.clear();
//       data.workerSkillResposeList.forEach((element:any , parent_index) => {
//          this.createSkillForm(element);

//           if(element.workerCertificationMappingRequestList.length > 0)
//           {
//             // element.workerCertificationMappingRequestList.forEach((cert_ele, index) =>{
//             //    this.addCertificationArrayControls(cert_ele,index)
//             // })
//             this.addCertificationArrayControls(element.workerCertificationMappingRequestList,parent_index,'edit_data')
//           }
//           if(element.workerLicenceMappingRequestList.length > 0)
//           {
//             //  element.workerLicenceMappingRequestList.forEach((lins_ele, index) =>{
//             //    this.addLicenseArrayControls(lins_ele,index);
//             // })
//             this.addLicenseArrayControls(element.workerLicenceMappingRequestList,parent_index,'edit_data');
//           }
 
//          });
         
//     }
 

//     //  this.routeNavigationTitle =   this.route.snapshot.data['title'];

//     // if(this.routeNavigationTitle && this.routeNavigationTitle === "staff_login_self_update")
//     // {
//     //   // disabling "Professional Tab" form in case of Staff Login 
//     //   // View Only access
//     //   this.New_ProfessionalForm.disable();
//     // }


    

//     if(this.userType&& this.userType === "Staff")
//     {
//       // disabling "Professional Tab" form in case of Staff Login 
//       // View Only access
//       this.New_ProfessionalForm.disable();
//     }

//   } 

//   InitializeProfessionalForm()
//   {
//     this.New_ProfessionalForm = this.fb.group({
//       'employmentType' : new FormControl('', Validators.required),
//       'SkillArray': this.fb.array([]),
//    });
//     this.createSkillForm(null);
//   }

//   createSkillForm(value){
//    let form ;
//     if(value){
//     const index =  this.skills.findIndex(ele => ele.skillCode === value.skillCode);
//       //this.skills[index];
//       form = this.fb.group({
//         'orgWorkerSkillCode' : new FormControl(value.orgWorkerSkillCode),
//         // 'skill': new FormControl(value.skillCode),
//         'skill': new FormControl(this.skills[index] , [Validators.required]),
//         'certificationMapList' : this.fb.array([]),
//         'licenceMapList' :  this.fb.array([])
//       });
//     }
//     else{
//       form = this.fb.group({
//         'orgWorkerSkillCode' : new FormControl(null),
//         'skill': new FormControl('', [Validators.required]),
//         'certificationMapList' : this.fb.array([]),
//         'licenceMapList' :  this.fb.array([])
//       });
//     }
//    this.skillFormsArray.push(form);  
//   }

//   deleteFromSkillForm(i:number){
//     this.skillFormsArray.removeAt(i);
//   }
  
//   get skillFormsArray() {
//     return this.New_ProfessionalForm.get('SkillArray') as FormArray ;
//   }

//   getCertificateFormError(parent_index , child_index){
//     return  this.New_ProfessionalForm.get(`SkillArray.${parent_index}.certificationMapList`)['controls'][child_index]
//     //this.New_ProfessionalForm.get(`SkillArray.${index}.certificationMapList`) as FormArray ;
//     //this.New_ProfessionalForm.get(`SkillArray.${0}.certificationMapList`).controls[0].get('certificateFileName').errors?.required
//   }
//   getLicenseFormError(parent_index , child_index){
//     return  this.New_ProfessionalForm.get(`SkillArray.${parent_index}.licenceMapList`)['controls'][child_index]
//    }
 
//   // OnSkillSelect(event:any , index:number){
//   //  //console.log(event);
//   //  if(event.option.value){
//   //    if(event.option.value.certificationMapList.length > 0 )
//   //    {
//   //     this.addCertificationArrayControls(event.option.value.certificationMapList , index)
//   //    }
//   //    if(event.option.value.licenceMapList.length > 0){
//   //     this.addLicenseArrayControls(event.option.value.licenceMapList , index)
//   //    }
//   //  }

//   // }

//   onCustomerChange(event,parent_index){
//     //console.log(event);
//     if(event.value){
//       // if(event.value.certificationMapList?.length > 0 )
//       // {
//       //  this.addCertificationArrayControls(event.value.certificationMapList , parent_index)
//       // }
//       // if(event.value.licenceMapList?.length > 0){
//       //  this.addLicenseArrayControls(event.value.licenceMapList , parent_index)
//       // }
//       this.addCertificationArrayControls(event.value.certificationMapList , parent_index);
//       this.addLicenseArrayControls(event.value.licenceMapList , parent_index);

//     }
//   }

//   addCertificationArrayControls(controls, parent_index , fromEdit='') {
//   const nestedArray = this.New_ProfessionalForm.get(`SkillArray.${parent_index}.certificationMapList`) as FormArray;
//   let temp = nestedArray;
//          temp.clear();

//   if(controls?.length > 0)
//   {  
//      controls.forEach((res) =>{
//       let ctrl:FormGroup =   this.fb.group({
//         'certificateName': new FormControl(res.certificationName ? res.certificationName : ''),
//        // "certificateExpiryDate": new FormControl(res.certificateExpiryDate ? res.certificateExpiryDate : '' , res?.isRequired == true ? Validators.required : null),
//         "certificateFileName": new FormControl(res.certificateFileName ? res.certificateFileName : ''  , res?.isRequired == true ? Validators.required : null),
//         "certificationCode": new FormControl(res.certificationCode ? res.certificationCode : null),
//          });
        
//          /*'fromEdit' ensuring that the data is coming from  api (OR when performing edit operation)*/

//         if(res.issuedBy){
//           ctrl.addControl('issuedBy', new FormControl(fromEdit?res.issuedBy:'',[Validators.required]));
//         }
//         if(res.numberValue){
//           ctrl.addControl('numberValue', new FormControl(fromEdit?res.numberValue:'',[Validators.required]));
//         }
//         if(res.certificateExpiryDate || res.expiration){
//           ctrl.addControl('certificateExpiryDate', new FormControl(fromEdit?res.certificateExpiryDate:'',[Validators.required]));
//         }
     
//         temp.push(ctrl)
//       // nestedArray.push(ctrl);
//     });
//   }
//     nestedArray.controls = temp.controls;
//   }
  

//   addLicenseArrayControls(controls, index,fromEdit='') {
//     const nestedArray = this.New_ProfessionalForm.get(`SkillArray.${index}.licenceMapList`) as FormArray;
//     let temp = nestedArray;
//         temp.clear();

//         if(controls?.length > 0)
//         {  
//        controls.forEach((res) =>{
//         let ctrl:FormGroup =   this.fb.group({
//           'licenceName': new FormControl(res.licenceName ? res.licenceName : ''),
//           //"licenceExpiryDate": new FormControl(res.licenceExpiryDate ? res.licenceExpiryDate : '' , res?.isRequired == true ? Validators.required : null),
//           "licenceFileName": new FormControl(res.licenceFileName ? res.licenceFileName : '' , res?.isRequired == true ? Validators.required : null),
//           "licenceCode": new FormControl(res.licenceCode ? res.licenceCode : null )
//           });

//             /*'fromEdit' ensuring that the data is coming from  api (OR when performing edit operation)*/

//           if(res.issuedBy){
//             ctrl.addControl('issuedBy', new FormControl(fromEdit?res.issuedBy:'',[Validators.required]));
//           }
//           if(res.numberValue){
//             ctrl.addControl('numberValue', new FormControl(fromEdit?res.numberValue:'',[Validators.required]));
//           }
//           if(res.licenceExpiryDate || res.expiration){
//             ctrl.addControl('licenceExpiryDate', new FormControl(fromEdit?res.licenceExpiryDate:'',[Validators.required]));
//           }
//           temp.push(ctrl)
//           // nestedArray.push(ctrl);
//       });
//     }
   
//       nestedArray.controls = temp.controls;
//     }
 
//     OnCertificateFileChange(event:any, flag:string, parent_index:number, child_index:number){
//       const target = event.target;
//       if (event.target.files.length > 0) {
//         let allowedExtension = ['jpeg', 'jpg', 'png', 'pdf'];
//         let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
//          let index =  allowedExtension.findIndex((ele:any) => ele === fileExtension);

//          if(index == -1){
//           this.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') ;
//           this.common.errorMessage = true;
//           event.target.value = '';
//           return;
//          }

//          if(event.target.files[0].size >= 2000000)
//          {
//           this.common.message = 'file size should be less then 2MB';
//           this.common.errorMessage = true;
//           event.target.value = '';
//           return;
//          }

//            const formData = new FormData();
//             formData.append("file", event.target.files[0]);
          
//             this.common.uploadImage(formData, (res) => {
//                if(res.responsecode == 200){

//                 let nestedFormControl ;
//                  if(flag === 'certificate')
//                  {
//                    nestedFormControl = this.New_ProfessionalForm.get(`SkillArray.${parent_index}.certificationMapList.${child_index}`);
//                   nestedFormControl['controls']['certificateFileName'].setValue(res.filename)
//                  // formControl.controls.certificateFileName.setValue(res.filename);
//                 }

//                 if(flag === 'license')
//                 {
//                   nestedFormControl = this.New_ProfessionalForm.get(`SkillArray.${parent_index}.licenceMapList.${child_index}`);
//                   nestedFormControl['controls']['licenceFileName'].setValue(res.filename)
//                 }
//               }
//             });
 
//       }
//     }

//   // clickFile(fileID,index){
//   //   document.getElementById(fileID+index).click();
//   // }

//   saveProfTabData(){
//     // //console.log('onSubmit_3:', this.skillFormsArray.value);
//      this.is_FormSubmitted = true ;
//     if(this.New_ProfessionalForm.valid){

//       let workerSkillRequestList :any = [];

//       this.skillFormsArray.controls.forEach(element =>{
//         let orgWorkerSkillCode = element.value.orgWorkerSkillCode ? element.value.orgWorkerSkillCode : null ;
//          let skillcode = element.value.skill.skillCode;
//          let certificationList:any =[];
       
//          /* certificate list looping*/
//          if(element.value.certificationMapList.length > 0){
//           element.value.certificationMapList.forEach(certificate_element =>{
//            let a = {
//               "certificateExpiryDate": certificate_element.certificateExpiryDate ? this.datepipe.transform(certificate_element.certificateExpiryDate, 'yyyy-MM-dd') : certificate_element.certificateExpiryDate ,
//               "certificateFileName": certificate_element.certificateFileName,
//               "certificationCode": certificate_element.certificationCode,
//               "numberValue" : certificate_element?.numberValue ? certificate_element?.numberValue : '',
//               "issuedBy" : certificate_element?.issuedBy ? certificate_element?.issuedBy :''
//             }
//             certificationList.push(a);
//           });
//          }

//         /* license list looping*/
//         let licenseList : any = [] ;

//          if(element.value.licenceMapList.length > 0){
//           element.value.licenceMapList.forEach(license_element =>{
//            let a = {
//               "licenceExpiryDate": license_element.licenceExpiryDate ? this.datepipe.transform(license_element.licenceExpiryDate, 'yyyy-MM-dd') : license_element.licenceExpiryDate ,
//               "licenceFileName": license_element.licenceFileName,
//               "licenceCode": license_element.licenceCode,
//               "numberValue" : license_element?.numberValue ? license_element?.numberValue : '',
//               "issuedBy" : license_element?.issuedBy ? license_element?.issuedBy :''
//             }
//             licenseList.push(a);
//           });
//          }
        
//          /* creating workerSkillRequestList  array list*/
//          let workerSkillJSON = {
//             "orgWorkerSkillCode": orgWorkerSkillCode,
//             "organizationCode": this.common.local.get('orgCode'),
//             "skillCode": skillcode,
//             "workerCertificationMappingRequestList": certificationList,
//             "workerLicenceMappingRequestList": licenseList
//          };

//          workerSkillRequestList.push(workerSkillJSON);
//       });
      
//         /*creating final request body*/
//       let FinalJSON:any = {
//         "employmentTypeCode": this.New_ProfessionalForm.controls.employmentType.value,
//         "workerCode": this.workerCode,
//         "workerSkillRequestList": workerSkillRequestList
//       };
//       // //console.log('onSubmit_4:', workerSkillRequestList);
//       // return;

//       this.technicianService.common.progressLoader = true;
//       this.technicianService.Save_UpdateWorkerProfessionalDetails(FinalJSON).subscribe((res:any) =>{
//         this.technicianService.common.progressLoader = false;
            
//         if(res.responsecode == 200)
//           {
//             this.technicianService.common.message = res.message;
//             this.technicianService.common.successMessage = true;
//             this.is_FormSubmitted = false;
//           }
//           else{
//             this.technicianService.common.message = res.message;
//             this.technicianService.common.errorMessage = true;
//           }
//       })

//     }

//   }

//   showOption(element){
//   //console.log()
//   let value :boolean = true;

//  const index =  this.skillFormsArray.controls.findIndex(ele => ele.value?.skill?.skillCode === element?.skillCode );
 
//  if(index != -1) {  value = true ; }
//  else{ value = false ; }

//  return value ;

//   }


 // comment_code : professional_skills_001   commented till

  


  setActiveInactiveStaff(customerName: any, isActive:boolean, id: any){
    // console.log('setActiveInactiveStaff_1: ', customerName, this.workerCode, isActive);
    // console.log('setActiveInactiveStaff: ', id, this.isUserActive, isActive);

    let confirmMSG = "Are you sure want to "+ (isActive ? 'Active ' : 'Inactive ') +"staff "+ (customerName && this.technicianService.common.toTitleCase(customerName)) +" ?" ;

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {
      
         if(result && result.toLowerCase() === 'ok')
         {
              let data= {
                "isActiveStatus": isActive,
                "userCode": this.workerCode,
                "organizationCode" : this.common.local.get('orgCode')
              }
              // console.log('setActiveInactiveStaff_1: ', data);
              // return;
              this.technicianService.common.progressLoader = true;
              this.technicianService.setActiveInactiveStaff(data, (response) => {
                if (response.responsecode == 200) {
                  this.getUserPersonalData();
              this.technicianService.common.message = response.message;
              this.technicianService.common.progressLoader = false;
              this.technicianService.common.successMessage = true;
              } else {
              this.technicianService.common.message = response.message;
              this.technicianService.common.progressLoader = false;
              this.technicianService.common.errorMessage = true;
              this.getUserPersonalData();
              }

              })
         } else {
            if(id === 'userStatusActive' && isActive !== this.isUserActive){
              this.checkRadio('userStatusInactive');
            } 
            if(id === 'userStatusInactive' && isActive !== this.isUserActive){
              this.checkRadio('userStatusActive');
            } 
         }

    });

 }

  checkRadio(id: any) {
    const radioElement = document.getElementById(id) as HTMLInputElement;
    if (radioElement) {
      radioElement.checked = true;
    }
}


saveStaffLogo(filename ){
    
  if(filename && this.workerCode)
  {
    let JSON = {
      "workerCode": this.workerCode,
      "workerLogo": filename
    }
    this.technicianService.common.progressLoader = true;
    this.technicianService.saveStaffLogo(JSON).subscribe((response:any) =>{
      // console.log('Image_response: ', response);
      this.technicianService.common.progressLoader = false;
      if (response.responsecode == 200) {
        this.fileUrl = this.technicianService.service.getSuperAdmin() + '/downloadFile/' + response.data.workerLogo;
        this.technicianService.common.message = response.message;
         this.technicianService.common.successMessage = true;
         } 
         else
          {
        this.technicianService.common.message = response.message;
         this.technicianService.common.errorMessage = true;
        }
    })

  }

}

changePassword(workerCode:string){ 
  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
    width: '320px',
    data: { msg: 'Confirm you want to reset your password via email?' }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result != undefined) {
      this.technicianService.common.progressLoader = true;
  this.technicianService.sendForgetPasswordLinkByCode(workerCode)
  .subscribe((res:any) =>{
         
   this.technicianService.common.progressLoader = false;
    

    if(res.responsecode == 200)
    {
     // this.dialogRef.close('data saved');
      this.technicianService.common.message = res.message;
      this.technicianService.common.successMessage = true;
       
    }
    else{
      this.technicianService.common.message = res.message;
      this.technicianService.common.errorMessage = true;
    }

  });
    } 
  });

}

closeDialog(){
  this.dialogRef.close();
}


tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
   this.activeTab = tabChangeEvent?.tab?.textLabel; 
 }

 backToList(){
  this.redirectAsPer_LogedInUser();
}



getProfessionForm(event : FormGroup)
{
  
 this.New_ProfessionalForm = event ;
}


saveProfTabData()
{
       this.checkForFormStatus = 'mark_for_check' ;      
       this.is_FormSubmitted = true ;
      if(this.New_ProfessionalForm.valid)
        {
  
        let workerSkillRequestList :any = [];
  
        this.New_ProfessionalForm.get('SkillArray')["controls"].forEach(element =>
          {
          let orgWorkerSkillCode = element?.value?.orgWorkerSkillCode ? element.value.orgWorkerSkillCode : null ;
          //  let skillcode = element.value.skill.skillCode;
          let skillcode = element.value?.skill?.skillCode ? element.value?.skill?.skillCode :  null;
           let certificationList:any =[];
         
           /* certificate list looping*/
           if(element.value.certificationMapList.length > 0){
            element.value.certificationMapList.forEach(certificate_element =>{
             let a = {
                "certificateExpiryDate": certificate_element.certificateExpiryDate ? this.datepipe.transform(certificate_element.certificateExpiryDate, 'yyyy-MM-dd') : certificate_element.certificateExpiryDate ,
                "certificateFileName": certificate_element.certificateFileName,
                "certificationCode": certificate_element.certificationCode,
                "numberValue" : certificate_element?.numberValue ? certificate_element?.numberValue : '',
                "issuedBy" : certificate_element?.issuedBy ? certificate_element?.issuedBy :''
              }
              certificationList.push(a);
            });
           }
  
          /* license list looping*/
          let licenseList : any = [] ;
  
           if(element.value.licenceMapList.length > 0){
            element.value.licenceMapList.forEach(license_element =>{
             let a = {
                "licenceExpiryDate": license_element.licenceExpiryDate ? this.datepipe.transform(license_element.licenceExpiryDate, 'yyyy-MM-dd') : license_element.licenceExpiryDate ,
                "licenceFileName": license_element.licenceFileName,
                "licenceCode": license_element.licenceCode,
                "numberValue" : license_element?.numberValue ? license_element?.numberValue : '',
                "issuedBy" : license_element?.issuedBy ? license_element?.issuedBy :''
              }
              licenseList.push(a);
            });
           }
          
           /* creating workerSkillRequestList  array list*/
           let workerSkillJSON = {
              "orgWorkerSkillCode": orgWorkerSkillCode,
              "organizationCode": this.common.local.get('orgCode'),
              "skillCode": skillcode,
              "workerCertificationMappingRequestList": certificationList,
              "workerLicenceMappingRequestList": licenseList
           };
  
           workerSkillRequestList.push(workerSkillJSON);
        });
        
          /*creating final request body*/
        let FinalJSON:any = {
          "employmentTypeCode": this.New_ProfessionalForm.controls.employmentType.value,
          "workerCode": this.workerCode,
          "workerSkillRequestList": workerSkillRequestList
        };
        // //console.log('onSubmit_4:', workerSkillRequestList);
        // return;
  
        this.technicianService.common.progressLoader = true;
        this.technicianService.Save_UpdateWorkerProfessionalDetails(FinalJSON).subscribe((res:any) =>{
          this.technicianService.common.progressLoader = false;
              
          if(res.responsecode == 200)
            {
              this.technicianService.common.message = res.message;
              this.technicianService.common.successMessage = true;
              this.is_FormSubmitted = false;

              this.get_staffAllUploadedDocs();
            }
            else{
              this.technicianService.common.message = res.message;
              this.technicianService.common.errorMessage = true;
            }
        })
  
      }
  
    }

  

    get_staffAllUploadedDocs()
    {
      this.workersAllUploadedDocs = null ;
      
      this.technicianService.getAllStaffUploadedDocuments(
        this.common.local.get('orgCode'),
        this.workerCode
      )
      .subscribe((res:IApiResponse)=>{
        
       // console.log("hinhinhin", res);

        if(res.responsecode == 200)
        {
          this.workersAllUploadedDocs = res.data;
         }
        else
        {
          this.workersAllUploadedDocs = null;
        }
      })
    }


    
  convertFileToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  }


  viewFiles(fileUrl:string) 
  {
    this.common.progressLoader = true;
    const a = document.createElement('a');
    a.href = this.img_serverPath+fileUrl;
    a.download = fileUrl.split('.')[0];
  
     document.body.appendChild(a);
     a.click();
    document.body.removeChild(a);
    this.common.progressLoader = false;

    // this.technicianService.common.progressLoader = true;
    // this.convertFileToBase64(this.img_serverPath+fileUrl).then(base64Data => {
    //   this.technicianService.common.progressLoader = false;
    //   const windowFeatures = 'width=600,height=400,left=200,top=100';
    //   const newWindow = window.open('', '_blank', windowFeatures);
      
    //   if (newWindow) {
    //     newWindow.document.write(`
    //       <html>
    //         <head><title>File Viewer</title></head>
    //         <body>
    //           <iframe src="${base64Data}" width="100%" height="100%" style="border:none;"></iframe>
    //         </body>
    //       </html>
    //     `);
    //   }
    // });
  }

  handleFileDelete(event)
  {
  if(event)
    this.get_staffAllUploadedDocs();
  }

  
  formateFile(filename:string)
  {
  
   if(!filename) return

   let a =  filename.split(".")[0].split("-");
   let pop = a.pop();
 
   return `${a.join("-")}.${filename.split(".")[1]}`
 
  }


}
