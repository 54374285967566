import { Component, OnInit, Input, EventEmitter, ViewChild, TemplateRef, Output, ElementRef, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionStorageService } from "angular-web-storage";
import { NewJobService } from "./new-job.service";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonService } from "src/app/shared/common.service";
import { MatDialog } from "@angular/material/dialog";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { ActivatedRoute, Router } from "@angular/router";
import {  Subject, debounceTime, firstValueFrom, forkJoin,  takeUntil } from "rxjs";
import { DatePipe } from "@angular/common";
import { AddShiftComponent } from "src/app/dashboards/providers-dashboard/shift-segment/add-shift/add-shift.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { ConfirmLogoutComponent } from "src/app/common/confirm-logout/confirm-logout.component";
import { JobRateCardService } from "../../job-rate-card/job-rate-card.service";
import * as moment from "moment";
import { MatSelectChange } from "@angular/material/select";
import { NewJobCardService } from "src/app/components/new-job-card/new-job-card.service";
 import { BreakForm, IApiResponse, INotifyCriteria } from "src/app/shared/interface/core.interface";
 import { settings_keys } from "src/app/shared/enum/settings.enum";
import { NotifyCriteriaFormComponent } from "src/app/shared-components/notify-criteria-form/notify-criteria-form.component";


interface jobEditableData {
  "appointmentsProceduresCode": string | null,
  "organizationBaseProcedureCode": string | null,
  "endTime": string,
  "equipments": string,
  "numberOfWorker": number,
  "ratePerHours": number,
  "shiftMasterCode": string | null,
  "startTime": string,
  "shiftBreakList": any;
  "uniform": string
}

@Component({
  selector: "app-new-job",
  templateUrl: "./new-job.component.html",
  styleUrls: ["./new-job.component.scss"],
  providers: [DatePipe],
})

export class NewJobComponent implements OnInit {
  @Input() isShortcutModalOpen: boolean = false;
  options = { types: [], componentRestrictions: { country: ["in", "us"] } };
  newJobCreation_Form: FormGroup;
  newCustomer_Form: FormGroup;
  addNewJobSite_Form: FormGroup;
  isCustomerForm_Submitted: boolean = false;
  isJobSiteForm_Submitted: boolean = false;
  customerList: any = [];
  equipmentList: any = [];
  shiftList: any[] = [];
  shiftList_Filter: any[] = [];
  uniformList: any = [];
  procedureList: any = [];
  procedureList_filter: any = [];
  selectedShift: any;
  ddl_CustomerAddressList: any = [];
  allAdminList: any = [];
  allAdminList_Filter: any = [];
  selectedCustomerAdminCode: string;
  isForm_Submitted: boolean = false;
  dialogRef: any;
  Uniform_Name: string = "";
  Equipment_Name: string = "";
  new_JobSite: string = "";
  current_SelectedIndex: number = null;
  customerSeachInput: FormControl = new FormControl();
  uniformSeachInput: FormControl = new FormControl();
  equipmentSeachInput: FormControl = new FormControl();
  shiftSeachInput: FormControl = new FormControl();
  Job_ReqSeachInput: FormControl = new FormControl();
  siteSupervisorSeachInput: FormControl = new FormControl();

  customerList_Filter: any = [];
  uniformList_Filter: any = [];
  equipmentList_Filter: any = [];
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  routeLink: string;
  ordermaxDate: any;
  JobminDate: any;
  isJobDateLessThenJobminDate: boolean = false;
  @Input() selectedCustomerCode: any;
  @Input() selectedSiteCode: any;
  @Input() AddJobmodalData: any;
  @Input() EditJobmodalData: any;

  // public event: EventEmitter<any> = new EventEmitter();
  @Output() event: EventEmitter<any> = new EventEmitter();


  disabledFields: boolean = false;
  nonWorkingDays: any[] = [];
  WorkingDays: any[] = [];
  officeDay: any[] = [];

  /*
  @Input() is_RequestedJob
  @Input() is_RequestedJob_Data  
  when customer requested for new job AND admin want to create same job*/
  @Input() is_RequestedJob: boolean = false;
  @Input() is_RequestedJob_Data: any;
  disable_whenJobRequested: boolean = false;
 // isSpecial_InstructionValueChange: boolean = false;

  @ViewChild("shiftSelect_TemplateRef") shiftSelect_TemplateRef: TemplateRef<any>;

  allow_BtnDisable: boolean = false;
  private saved_specialInstruction: string = '';
  //private jobEditableData:jobEditableData;
  private deleted_appointmentsProceduresCode: string[] = [];
  private yearMonthDateFormat = 'YYYY-MM-DD';

  @ViewChild("UnAssignStaffPopUp") UnAssignStaffPopUp: TemplateRef<any>;
  private _count:number = 0 ;
  distributionList: any = [];
 // distributionList_Filter: any = [];
  selectedDistribution: any[] = [];
  protected filtered_StaffList: any[] = [];
  @ViewChild("distribution_sendNotificationPopUp") distribution_sendNotificationPopUp: TemplateRef<any>;
  //private notifyStaffWhenJobEdit:boolean = false;

  @ViewChild("btnDistribution_closePopUp") btnDistribution_closePopUp: ElementRef<HTMLButtonElement>;
   
  private jobEditData_fromAPI = {
    organizationBaseProcedureCode : '',
    numberOfWorker : 0,
    customerCode:'',
    siteAddressCode:'',
    appointmentsCode:'',
    appointmentsProceduresCode:'',
    shiftMasterCode:'',
    equipments: null,
    uniform: null,
    specification : '',
    customerAdminCode:null,
    ratePerHours:0,
    noteList: []
  } ;

  noteRequest :any;

  protected isDetectedFormChanges:boolean = false;
  break_arr_toggle = [];

  /**
   * var staff_notify_mode
   * hold the value like "Distribution List" OR  "Auto"
   * based on value set in system setting
   * this flag will ensure that the user should show "Distribution PopUp" or not
   * based on value "Distribution List" OR  "Auto" , can manually notify user (By Showing "Distribution PopUp")
   * OR system will automatically notfies the staff
   */
  private staff_notify_mode : string = '' ;

  /**
   *  var  is_notify
   * whether to send notification msg to staff or not
   */
  private is_notify : boolean = false ;

  private notifyCriteria : INotifyCriteria = {
    distance: null,
    distributionCodeList: [],
    numberOfNotify: null,
    selfSignUp: null,
    staffCodeList : []
  }

  constructor(
    private _translateSVC: TranslateService,
    private _sessionSVC: SessionStorageService,
    private _newJobSVC: NewJobService,
    private fb: FormBuilder,
    private _commonSVC: CommonService,
    private dialog: MatDialog,
    private router: Router,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private _jobRateCardSVC: JobRateCardService,
    private _newJobCardSVC : NewJobCardService,
    
  ) {
    this._translateSVC.setDefaultLang(this._sessionSVC.get("language"));
    this.initializeForm();
    this.getQueryParam();
    this.getNonWorkingDays();
    this.getworkingDays();
    this.getStaffNotifyMode();
   // this.checkSpecial_Instruction = debounce(this.checkSpecial_Instruction, 1000);
  }
 

  ngOnInit(): void {
    /* //console.log("NewJobComponent", this.AddJobmodalData);
     //console.log("NewJobComponent", this.EditJobmodalData);
    //console.log('isShortcutModalOpen: ', this.isShortcutModalOpen); */
    // this.initializeForm();
    this.initializeCustomerForm();
    this.initializeJobSiteForm();
    // this.getAllCustomerList();
    // this.getAllShiftMaster();
    // this.getAllProcedure();
    // this.getAllEquipmentMaster();
    // this.getAllUniformMaster();

    this.masterAPiForkCalling();

    this.initValueChange();
    
    //this.cd.detectChanges();
    // this.ordermaxDate = new Date();
    // this.JobminDate = new Date();

    this.ordermaxDate = moment().toDate();
    this.JobminDate = moment().toDate();

    this.getDistributionList();

    this._commonSVC.getCommonRefreshEvent().subscribe((noteRequest)=>{
     // console.log('ResultRefresh', noteRequest);
      this.noteRequest = noteRequest;
    });

    // this.newJobCreation_Form.get('Special_Instruction').valueChanges.subscribe(value => {
    //   this.checkSpecial_Instruction(value);
    // });

  }

// checkSpecial_Instruction(value: any) {
//   // console.log('Special_Instruction_ChangeSub:', value);
//   // console.log('Special_Instruction_ChangeFromAPI:', this.jobEditData_fromAPI.specification);
//   // console.log('Special_Instruction_ChangeSaved_spe:', this.saved_specialInstruction);

//   const isValueValid = value && value.trim();
//   const savedValueGet = this.saved_specialInstruction || this.jobEditData_fromAPI.specification;
//   const isDifferent = value !== savedValueGet;

//   if(isValueValid && isDifferent){
//     this.isSpecial_InstructionValueChange = true;
//     // console.log('Special_Instruction_Change_T:', this.isSpecial_InstructionValueChange);
//   } else {
//     this.isSpecial_InstructionValueChange = false;
//     // console.log('Special_Instruction_Change_F:', this.isSpecial_InstructionValueChange);
//   }
// }

  openAddShiftPopup(index: number) {
    this.dialogRef = this.dialog.open(AddShiftComponent, {
      width: "50%",
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe((result) => {

      if (result && result.responsecode == 200) {

        let shiftMasterCode = result.data.shiftMasterCode;
        this._commonSVC.progressLoader = true;
        this._newJobSVC.getAllShiftMaster(this._sessionSVC.get("orgCode")).subscribe((res: any) => {
          this._commonSVC.progressLoader = false;

          if (res.responsecode == 200) {

            let newShift = res.data.find(ele => ele.shiftMasterCode === shiftMasterCode)
            this.shiftList.push(newShift);
            this.shiftList_Filter.push(newShift);

            this.getJobData_Array.controls[index].get("Shift").setValue(newShift);

            // this control used as view only purpose
            this.getJobData_Array.controls[index].get("Shift_ViewOnly").setValue(newShift.shiftName);
            this.selectedShift = newShift;
          }
        });

      }

    });


  }

  getQueryParam() {
    this.route.queryParams.subscribe((params) => {
      if (params && params.pre_route) {
        this.routeLink = params.pre_route;
      } else {
        this.routeLink = "/dashboard/jobs";
      }
    });
  }

  goBack() { }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initValueChange() {
    this.customerSeachInput.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCustomer();
      });

    this.uniformSeachInput.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUnifom();
      });

    this.equipmentSeachInput.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEquipment();
      });

    this.shiftSeachInput.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterShift();
      });

    this.Job_ReqSeachInput.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterJob_Req();
      });

    this.siteSupervisorSeachInput.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSiteSupervisor();
      });

  }

  protected filterCustomer() {
    if (this.customerList.length == 0) {
      return;
    }
    let search = this.customerSeachInput.value.trim();

    this.customerList_Filter = this.customerList.filter((ele) =>
      ele.customerName.toLowerCase().includes(search.toLowerCase())
    );
  }

  protected filterUnifom() {
    if (this.uniformList.length == 0) {
      return;
    }
    let search = this.uniformSeachInput.value.trim();

    this.uniformList_Filter = this.uniformList.filter((ele) =>
      ele.uniformName.toLowerCase().includes(search.toLowerCase())
    );
  }

  protected filterEquipment() {
    if (this.equipmentList.length == 0) {
      return;
    }
    let search = this.equipmentSeachInput.value.trim();

    this.equipmentList_Filter = this.equipmentList.filter((ele) =>
      ele.equipmentName.toLowerCase().includes(search.toLowerCase())
    );
  }

  protected filterShift() {
    if (this.shiftList.length == 0) {
      return;
    }

    let search = this.shiftSeachInput.value.trim();

    this.shiftList_Filter = this.shiftList.filter((ele) =>
      ele.shiftName.toLowerCase().includes(search.toLowerCase())
    );
  }

  protected filterJob_Req() {
    if (this.procedureList.length == 0) {
      return;
    }
    let search = this.Job_ReqSeachInput.value.trim();

    this.procedureList_filter = this.procedureList.filter((ele) =>
      ele.procedureName.toLowerCase().includes(search.toLowerCase())
    );
  }


  protected filterSiteSupervisor() {
    if (this.allAdminList.length == 0) {
      return;
    }
    let search = this.siteSupervisorSeachInput.value.trim();

    this.allAdminList_Filter = this.allAdminList.filter((ele) =>
      ele.workerName.toLowerCase().includes(search.toLowerCase())
    );
  }



  initializeJobSiteForm() {
    this.addNewJobSite_Form = this.fb.group({
      JobSite_Name: new FormControl("", [Validators.required]),
      JobSite_Address: new FormControl("", [Validators.required]),
      SiteContact: new FormControl(""),
      SiteContactPhone: new FormControl("", [
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
    });
  }

  initializeCustomerForm() {
    this.newCustomer_Form = this.fb.group({
      CustomerName: new FormControl("", [Validators.required]),
      Fax: new FormControl(""),
      Mobile: new FormControl("", [
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
      Email: new FormControl("", [Validators.required, Validators.email]),
      PrimaryContact: new FormControl(""),
      PrimaryContact_Mobile: new FormControl("", [
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
    });
  }

  initializeForm() {
    this.newJobCreation_Form = this.fb.group({
      appointmentsCode: new FormControl(""),  // job Parent Table ID
      // appointmentsProceduresCode: new FormControl(""),

      Customer: new FormControl("", [Validators.required]),
      Job_Date: new FormControl("", [Validators.required]),
      Job_Site: new FormControl("", [Validators.required]),
      Job_ID: new FormControl(""),
      Site_Address: new FormControl(""),
      Site_Contact: new FormControl(""),
      Site_Contact_Ph: new FormControl("", Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      Order_Date: new FormControl(""),
      Site_Supervisor: new FormControl(""),
      Special_Instruction: new FormControl(""),
      JobData_Array: this.fb.array([]),
    });

    this.addInJobData_Array();
  }

 
  get getJobData_Array() {
    return this.newJobCreation_Form.get("JobData_Array") as FormArray;
  }

 
  addInJobData_Array() 
  {
    const newJob = this.fb.group({
      Shift: new FormControl(""),
      Shift_ViewOnly: new FormControl(""),
      Job_Req: new FormControl("", [Validators.required]),
      hold_JobReqSelectedData: new FormControl(""), // this form control is only used for internally in .ts file (not using in UI). To Hold the "Job_Req" selected option.

      Job_Qty: new FormControl("", [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]),
      ratePerHours: new FormControl("", [Validators.required, Validators.pattern(/^[0-9]*.?[0-9]+$/)]),
      Uniform: new FormControl(""),
      Equipment: new FormControl(""),
      appointmentsProceduresCode: new FormControl(""),  // Job Table ID
      JobTypeRateData: new FormControl(""), // this form control is only used for internally in .ts file (not using in UI)
      startTime: new FormControl("", [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)]), 
      endTime: new FormControl("", [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)]),
      breakForm: new FormControl([]),
    });

    this.getJobData_Array.push(newJob);

  }

  deleteFromJobData_Array(i: number) {

    this.toggleBreak(i);

    if (i === 0 && this.getJobData_Array.controls[i].get("appointmentsProceduresCode").value) {
      this.askBeforeDelete(i, (confirmed: boolean) => {
        if (confirmed) {
          this.getJobData_Array.removeAt(i);
        }
      });
    }
    else {
      this.getJobData_Array.removeAt(i);
    }

  }

   

  bindForm() {
    this._commonSVC.progressLoader = true;
    this._newJobSVC.getJobsByAppointmentsProceduresCode(
      this._sessionSVC.get("orgCode"),
      this.EditJobmodalData.appointmentsCode,
      this.EditJobmodalData.appointmentsProceduresCode,
      this.EditJobmodalData.jobDate
    )
      .subscribe((res: any) => {

        this._commonSVC.progressLoader = false;
        //console.log("getJobsByAppointmentsProceduresCode", res);

        if (res.responsecode == 200) {
          // this.jobEditableData =  setJobEditableData(res.data[0]) ;
          // console.log("this.jobEditableData", this.jobEditableData);
          this.jobEditData_fromAPI = res.data[0];
          this.jobEditData_fromAPI.specification = res.data[0].specification ? res.data[0].specification : '';
        
          this.disabledFields = true;

          if (res.data[0]?.appointmentsCode) {  // setting job parent Table ID
            this.newJobCreation_Form.controls.appointmentsCode.setValue(res.data[0].appointmentsCode);
          }

          // if(res.data[0]?.appointmentsProceduresCode){
          // this.newJobCreation_Form.controls.appointmentsProceduresCode.setValue(res.data[0].appointmentsProceduresCode);
          // }

          if (this.customerList && this.customerList?.length > 0) {
            this.newJobCreation_Form.controls.Customer.setValue(this.customerList.find(customer => customer.customerCode === res.data[0].customerCode));
          }

          //   if(res.data[0]?.jobDate){
          //  this.newJobCreation_Form.controls.Job_Date.setValue(
          //   res.data[0].jobDate ? this.datepipe.transform( res.data[0].jobDate, "yyyy-MM-dd" ) : res.data[0].jobDate 
          //   );
          //  }

          if (res.data[0]?.jobDate) {
            // this.newJobCreation_Form.controls.Job_Date.setValue( res.data[0].jobDate);
            this.newJobCreation_Form.controls.Job_Date.setValue(moment(res.data[0].jobDate).toDate());

          }

          const siteAddress: any[] = this.newJobCreation_Form.controls.Customer.value?.customerAddressResponseList || [];
          this.ddl_CustomerAddressList = siteAddress  //  binding list into jobsite dropdown
          this.newJobCreation_Form.controls.Job_Site.setValue(siteAddress.find(ele => ele.customerAddressCode === res.data[0].siteAddressCode));

          if (res.data[0]?.siteAddress) {
            this.newJobCreation_Form.controls.Site_Address.setValue(res.data[0].siteAddress);
          }
          if (res.data[0]?.siteContact) {
            this.newJobCreation_Form.controls.Site_Contact.setValue(res.data[0].siteContact);
          }
          if (res.data[0]?.siteContactPh) {
            this.newJobCreation_Form.controls.Site_Contact_Ph.setValue(res.data[0].siteContactPh);
          }

          this.saved_specialInstruction = this.newJobCreation_Form.controls.Job_Site.value?.specialInstructions;

          // if(res.data[0]?.orderDT){
          //   this.newJobCreation_Form.controls.Order_Date.setValue( 
          //      res.data[0].orderDT ? this.datepipe.transform( res.data[0].orderDT, "yyyy-MM-dd" ) : res.data[0].orderDT
          //     );
          //   }

          if (res.data[0]?.orderDT) {
            // this.newJobCreation_Form.controls.Order_Date.setValue(res.data[0].orderDT);
            this.newJobCreation_Form.controls.Order_Date.setValue(moment(res.data[0].orderDT).toDate());
          }

          if (res.data[0]?.jobId) {
            this.newJobCreation_Form.controls.Job_ID.setValue(res.data[0].jobId);
          }

          if (res.data[0]?.specification) {
            this.newJobCreation_Form.controls.Special_Instruction.setValue(res.data[0].specification ? res.data[0].specification : '');

            // overriding value if already added when creating new job
            this.saved_specialInstruction = res.data[0].specification ? res.data[0].specification : '';
          }

          if (res.data[0]?.customerAdminCode && this.allAdminList && this.allAdminList?.length > 0) {
            this.newJobCreation_Form.controls.Site_Supervisor.setValue(
              this.allAdminList.find(ele => ele.workerCode === res.data[0].customerAdminCode)
            );
          }

          if (res.data[0]?.customerAdminCode) {
            this.selectedCustomerAdminCode = res.data[0]?.customerAdminCode;            
          }

          //--------------------------- shift form array binding at index [0]----------------------------------------------  

          this.getJobData_Array.controls[0].get("appointmentsProceduresCode").setValue(res.data[0].appointmentsProceduresCode);

          // this.getAllJobTypeRateOfCustomer(0, res.data[0].customerCode, res.data[0].organizationBaseProcedureCode);

          // this.getJobData_Array.controls[0].get("Shift").setValue(
          //   this.shiftList.find(Shift => Shift.shiftMasterCode === res.data[0].shiftMasterCode)
          // );

          // this.selectedShift = this.shiftList.find(Shift => Shift.shiftMasterCode === res.data[0].shiftMasterCode);

          // // this control used as view only purpose
          // this.getJobData_Array.controls[0].get("Shift_ViewOnly").setValue(
          //   this.getJobData_Array.controls[0].get("Shift").value?.shiftName
          // );
          
          this.getJobData_Array.controls[0].get("Shift").setValue(res.data[0].shiftMasterCode);
           
          this.getJobData_Array.controls[0].get("startTime").setValue(res.data[0].startTime);
          this.getJobData_Array.controls[0].get("endTime").setValue(res.data[0].endTime);

          if(res.data[0].breakList && res.data[0].breakList.length > 0)
            {
              this.getJobData_Array.controls[0].get("breakForm").setValue(res.data[0].breakList);
              this.break_arr_toggle[0] = true  // toggle break form
            }

          this.getJobData_Array.controls[0].get("Job_Req").setValue(
            this.procedureList.find(ele => ele.organizationBaseProcedureCode === res.data[0].organizationBaseProcedureCode)
          );

          // just to hold "Job_Req" control's selected option
          this.getJobData_Array.controls[0].get("hold_JobReqSelectedData").setValue(
            this.procedureList.find(ele => ele.organizationBaseProcedureCode === res.data[0].organizationBaseProcedureCode)
          );

          this.getJobData_Array.controls[0].get("Job_Qty").setValue(res.data[0].numberOfWorker);

          if (res.data[0].uniform) {
            this.getJobData_Array.controls[0].get("Uniform").setValue(
              this.uniformList.find(ele => ele.uniformName === res.data[0].uniform)
            );
          }

          if (res.data[0].equipments) {
            this.getJobData_Array.controls[0].get("Equipment").setValue(
              this.equipmentList.find(ele => ele.equipmentName === res.data[0].equipments)
            );
          }

          this.getJobData_Array.controls[0].get("ratePerHours").setValue(res.data[0].ratePerHours);

          let organizationBaseProcedureCode = res.data[0].organizationBaseProcedureCode;
          let customerCode = this.newJobCreation_Form.controls.Customer.value?.customerCode;
          this.getAllJobTypeRateOfCustomer(0, customerCode, organizationBaseProcedureCode, true);
          
          
          // calling this method at the time of "EDIT RECORD" to get supervisor list
          this.getSiteSupervisorList_forUpdate(
            res.data[0].customerCode,
            res.data[0].appointmentsCode,
            res.data[0].appointmentsProceduresCode,
            res.data[0].jobDate
          );

          //---------------------------------------------------------------------------------------------------------------

        }

        // this.ngZone.runOutsideAngular(() => {
        //   this.newJobCreation_Form.valueChanges.pipe(takeUntil(this._onDestroy),debounceTime(600)).subscribe((res)=>{
        //      this.isDetectedFormChanges = this.checkForFormValueChanges;
        //   })
        // });
  

      });
  }


  getAllCustomerList() {
    this._commonSVC.progressLoader = true;
    this._newJobSVC
      .getAllCustomerDetails(this._sessionSVC.get("orgCode"))
      .subscribe((res: any) => {
        this._commonSVC.progressLoader = false;
        if (res.responsecode == 200) {
          this.customerList = res.data;
          this.customerList_Filter = this.customerList;
          //console.log("getAllCustomerList customerList", this.customerList);
          // //console.log("getAllCustomerList AddJobmodalData", this.AddJobmodalData);
          // //console.log("getAllCustomerList EditJobmodalData", this.EditJobmodalData);
          if (this.AddJobmodalData) {
            if (this.AddJobmodalData.selectedCustomerCode) {
              const customer = this.customerList.filter(
                (customer: any) =>
                  customer.customerCode ==
                  this.AddJobmodalData.selectedCustomerCode
              );
              if (customer.length > 0) {
                this.newJobCreation_Form.controls.Customer.setValue(
                  customer[0]
                );
              }
            } else {
              this.newJobCreation_Form.controls.Customer.setValue("");
            }

            this.ddl_CustomerAddressList =
              this.newJobCreation_Form.controls.Customer.value
                ?.customerAddressResponseList || [];

            //console.log("ddl_CustomerAddressList",this.ddl_CustomerAddressList);

            if (this.AddJobmodalData.selectedSiteCode) {
              const customerAddress = this.ddl_CustomerAddressList.filter(
                (customerAddress: any) =>
                  customerAddress.customerAddressCode ==
                  this.AddJobmodalData.selectedSiteCode
              );
              if (customerAddress.length > 0) {
                this.newJobCreation_Form.controls.Job_Site.setValue(
                  customerAddress[0]
                );
                this.onJobSiteChange();
              }
            } else {
              this.newJobCreation_Form.controls.Job_Site.setValue("");
            }
            if (this.AddJobmodalData.JobDate) {
              // const JobDate = this.datepipe.transform(
              //   this.AddJobmodalData.JobDate,
              //   "yyyy-MM-dd"
              // );
 
              // const JobDate  = moment(this.AddJobmodalData.JobDate).format(this.yearMonthDateFormat);
              const JobDate  = moment(this.AddJobmodalData.JobDate).toDate();
              this.newJobCreation_Form.controls.Job_Date.setValue(JobDate);
            } else {
              this.newJobCreation_Form.controls.Job_Date.setValue("");
            }
          }

          else if (this.EditJobmodalData) {
            this.bindForm();
          }

          else if (this.is_RequestedJob && this.is_RequestedJob_Data) {
            setTimeout(() => { // assuming that all the master api should get response before binding form
              this.fillFormAsPerCustomerRequested(this.is_RequestedJob_Data);
            }, 1000)

          }

        } else {
          this.newJobCreation_Form.controls.Customer.setValue("");
          this.newJobCreation_Form.controls.Job_Site.setValue("");
          this.newJobCreation_Form.controls.Job_Date.setValue("");
        }
      });
  }

  onCustomerChange() {
    //this.selectedCustomerCode = event.value.customerCode;
    this.ddl_CustomerAddressList =
      this.newJobCreation_Form.controls.Customer.value
        ?.customerAddressResponseList || [];
    //console.log("ddl_CustomerAddressList", this.ddl_CustomerAddressList);
    this.newJobCreation_Form.controls.Site_Address.setValue(
      this.selectedSiteCode
    );
    this.newJobCreation_Form.controls.Site_Address.setValue("");
    this.newJobCreation_Form.controls.Job_Site.setValue("");
    this.newJobCreation_Form.controls.Site_Contact.setValue("");
    this.newJobCreation_Form.controls.Site_Contact_Ph.setValue("");

    this.resetSpecialInstr();
 
    this.getSiteSupervisorList_ForSave(this.newJobCreation_Form.controls.Customer.value?.customerCode);

    //this.resetSiteSupervisorDDL();
  }

resetSpecialInstr()
{
 this.newJobCreation_Form.controls.Special_Instruction.setValue("");
 this.saved_specialInstruction = "" ;
}

  onJobSiteChange() {
    this.newJobCreation_Form.controls.Site_Address.setValue(
      this.newJobCreation_Form.controls.Job_Site.value?.location
    );
    this.newJobCreation_Form.controls.Site_Contact.setValue(
      this.newJobCreation_Form.controls.Job_Site.value?.siteContact
    );
    this.newJobCreation_Form.controls.Site_Contact_Ph.setValue(
      this.newJobCreation_Form.controls.Job_Site.value?.siteContactPhone
    );

    this.newJobCreation_Form.controls.Special_Instruction.setValue(
      this.newJobCreation_Form.controls.Job_Site.value?.specialInstructions
    );

    this.saved_specialInstruction = this.newJobCreation_Form.controls.Job_Site.value?.specialInstructions;
    // console.log("job site change", this.newJobCreation_Form.controls.Job_Site.value);

    //this.resetSiteSupervisorDDL();
  }

  getSiteSupervisorList_forUpdate(customerCode: string, appointmentsCode:string, appointmentsProceduresCode:string, date: string) {
    
    if(this._sessionSVC.get("orgCode") && customerCode && date)
      {
    this._newJobSVC
      .getSiteSupervisorList_forUpdate(
        this._sessionSVC.get("orgCode"),
         customerCode, 
         appointmentsCode,
         appointmentsProceduresCode,
         date
      )
      .subscribe((res: any) => {
        if (res.responsecode == 200) {
          this.allAdminList = res.data;
          this.allAdminList_Filter = res.data;
          if (this.selectedCustomerAdminCode && this.allAdminList && this.allAdminList?.length > 0) {
              this.newJobCreation_Form.controls.Site_Supervisor.setValue(this.allAdminList.find(ele => ele.workerCode === this.selectedCustomerAdminCode));
          }
        } 
        else
        {
          this.allAdminList = [];
          this.allAdminList_Filter = [];
          this.newJobCreation_Form.controls.Site_Supervisor.setValue('');
        }
      });
    }
  }


  
  getSiteSupervisorList_ForSave(customerCode:string) {
    
    if(this._sessionSVC.get("orgCode") && customerCode)
      {
    this._newJobSVC
      .getSiteSupervisorList_ForSave(this._sessionSVC.get("orgCode"), customerCode)
      .subscribe((res: any) => {
        if (res.responsecode == 200) 
          {
          this.allAdminList = res.data;
          this.allAdminList_Filter = res.data;
          } 
        else
        {
          this.allAdminList = [];
          this.allAdminList_Filter = [];
          this.newJobCreation_Form.controls.Site_Supervisor.setValue('');
        }
      });
    }
  }


  // resetSiteSupervisorDDL() {
  //   this.newJobCreation_Form.controls.Site_Supervisor.setValue("");
  // }

  getAllShiftMaster() {
    this._newJobSVC
      .getAllShiftMaster(this._sessionSVC.get("orgCode"))
      .subscribe((res: any) => {
        if (res.responsecode == 200) {
          this.shiftList = res.data;
          this.shiftList_Filter = res.data;
          ////console.log("shiftList", this.shiftList);
          // this.shiftList.sort((a, b) => {
          //   const timeA = this.parseTime(a.startTime);
          //   const timeB = this.parseTime(b.startTime);
          //   return timeA - timeB;
          // });

          // // Create an object to store the grouped data
          // const groupedData = {};

          // // Iterate through the shift data
          // for (const shift of this.shiftList) {
          //   const key = `${shift.startTime}-${shift.endTime}`;

          //   if (!groupedData[key]) {
          //     groupedData[key] = {};
          //   }

          //   for (const breakInfo of shift.shiftBreakResponseList) {
          //     const breakType = breakInfo.breakType;
          //     const paidStatus = breakInfo.paidStatus;

          //     if (!groupedData[key][breakType]) {
          //       groupedData[key][breakType] = {};
          //     }

          //     if (!groupedData[key][breakType][paidStatus]) {
          //       groupedData[key][breakType][paidStatus] = [];
          //     }

          //     groupedData[key][breakType][paidStatus].push(breakInfo);
          //   }
          // }
          // //console.log("groupedData", groupedData);
        }
      });
  }


  parseTime(timeStr) {
    const [time, period] = timeStr.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let hours24 = hours;
    if (period === "PM" && hours !== 12) {
      hours24 += 12;
    } else if (period === "AM" && hours === 12) {
      hours24 = 0;
    }

    return hours24 * 60 + minutes; // Convert to minutes
  }
  getAllEquipmentMaster() {
    this._newJobSVC
      .getAllEquipmentMaster(this._sessionSVC.get("orgCode"))
      .subscribe((res: any) => {
        if (res.responsecode == 200) {
          this.equipmentList = res.data;
          this.equipmentList_Filter = this.equipmentList;
        }
      });
  }

  getAllUniformMaster() {
    this._newJobSVC
      .getAllUniformMaster(this._sessionSVC.get("orgCode"))
      .subscribe((res: any) => {
        if (res.responsecode == 200) {
          this.uniformList = res.data;
          this.uniformList_Filter = this.uniformList;
        }
      });
  }

  getAllProcedure() {
    this._newJobSVC
      .getAllProcedure(this._sessionSVC.get("orgCode"))
      .subscribe((res: any) => {
        if (res.responsecode == 200) {
          this.procedureList = res.data;
          this.procedureList_filter = res.data;

          this.bindSupervisorDropDown();
        }
      });
  }


  bindSupervisorDropDown()
  {
    if (this.AddJobmodalData && this.AddJobmodalData.selectedCustomerCode && this.AddJobmodalData.organizationBaseProcedureCode) {
      const organizationBaseProcedureCode = this.procedureList.filter(
        (customerAddress: any) =>
          customerAddress.organizationBaseProcedureCode ==
          this.AddJobmodalData.organizationBaseProcedureCode
      );
      if (organizationBaseProcedureCode.length > 0) 
      {
        this.getJobData_Array.controls[0].get("Job_Req").setValue(organizationBaseProcedureCode[0]);

        if(this.AddJobmodalData.selectedCustomerCode)
        this.getAllJobTypeRateOfCustomer(0, this.AddJobmodalData.selectedCustomerCode, this.AddJobmodalData.organizationBaseProcedureCode);
        
      }
    }
  }

 
  
  AddUpdateJob() {
    this.isForm_Submitted = true;
    if (this.newJobCreation_Form.valid) 
      {

      // if(this.isSpecial_InstructionValueChange){
      //   this.specialInsOnBlur('AddUpdateJob');
      //   return true;
      // }

        const jobDataArray = this.getJobData_Array.controls.map((element: any) => ({
            shiftMasterCode: null,
            startTime: element.value.startTime,
            endTime: element.value.endTime,
            equipments: element.value.Equipment?.equipmentName,
            numberOfWorker: element.value.Job_Qty ? parseInt(element.value.Job_Qty) : element.value.Job_Qty,
            organizationBaseProcedureCode: element.value.Job_Req?.organizationBaseProcedureCode,
            uniform: element.value.Uniform?.uniformName,
            ratePerHours: element.value.ratePerHours,
            shiftBreakRequestList: element.value.breakForm.map((breakElement: BreakForm) => ({
                breakType: breakElement.breakType,
                duration: breakElement.duration ? Number(breakElement.duration) : null,
                paidStatus: breakElement.paidStatus
            }))
        }));

        const formatDate = (dateControl: any) => 
            dateControl.value ? moment(dateControl.value, this.yearMonthDateFormat).format(this.yearMonthDateFormat) : "";

        const jobDate = formatDate(this.newJobCreation_Form.controls.Job_Date);
        const orderDate = formatDate(this.newJobCreation_Form.controls.Order_Date);

        const finalData = {
            customerAddressCode: this.newJobCreation_Form.controls.Job_Site.value?.customerAddressCode,
            customerAdminCode: this.newJobCreation_Form.controls.Site_Supervisor.value?.workerCode || null,
            customerCode: this.newJobCreation_Form.controls.Customer.value?.customerCode,
            endDate: jobDate,
            startDate: jobDate,
            jobTypeWithJobData: jobDataArray,
            orderDT: orderDate,
            organizationCode: this._sessionSVC.get("orgCode"),
            siteContact: this.newJobCreation_Form.controls.Site_Contact.value,
            siteContactPh: this.newJobCreation_Form.controls.Site_Contact_Ph.value,
            specification: this.newJobCreation_Form.controls.Special_Instruction.value,
            jobId: this.newJobCreation_Form.controls.Job_ID.value,
            isDateOfMonth: false,
            daysList: [],
            repeatType: "day",
            repeatValue: 1,
             noteRequest: this._commonSVC.noteRequest,
            isNotify :  this.is_notify,
            
           // distributionCodeList: this.selectedDistribution.length > 0 
            //                       ? this.selectedDistribution 
            //                       : this.notifyCriteria.distributionCodeList,

            distributionCodeList : this.selectedDistributions ,

            distance: this.notifyCriteria.distance,
            numberOfNotify: this.notifyCriteria.numberOfNotify,
            selfSignUp: this.notifyCriteria.selfSignUp,

            staffCodeList :  this.notifyCriteria.staffCodeList && this.notifyCriteria.staffCodeList.length > 0
                             ? this.notifyCriteria.staffCodeList
                             : null    

        };

       // console.log("add job request", finalData);

        const handleResponse = (res: any) => {
            this._commonSVC.progressLoader = false;
            this.isForm_Submitted = false;
            if (res.responsecode === 200) {
                if (this.isShortcutModalOpen || this.AddJobmodalData || this.EditJobmodalData) {
                    this.closeBsPopUp();
                    this.dialog.closeAll();
                } else {
                    this.router.navigate([this.routeLink]);
                }
                this._commonSVC.successMessage = true;
                this._commonSVC.message = res.message;
                this.newJobCreation_Form.reset();
            } else {
                this._commonSVC.errorMessage = true;
                this._commonSVC.message = res.message;
            }
        };

        if (this.isJobDateLessThenJobminDate) {
            const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
                width: '320px',
                data: { msg: "Are you sure you want to create job with past date?" }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== undefined) {
                    // console.log("save job requset", finalData);
                    // return;
                    this._commonSVC.progressLoader = true;
                    this._newJobSVC.createNewJob(finalData).subscribe(handleResponse);
                }
            });
        } else {
            // console.log("save job requset", finalData);
            // return;
            this._commonSVC.progressLoader = true;
            this._newJobSVC.createNewJob(finalData).subscribe(handleResponse);
        }
    }
}

  saveAndNotifyJob()
  {
    if(this.selectedDistribution.length > 0)
    {

      if(this.newJobCreation_Form.controls.appointmentsCode.value)
      {
        // edit job
        this.saveEditedJob();
      }
      else
      {
        // add job
        this.AddUpdateJob();
      }

      
    }
  }

 
  // saveEditedJob()
  // {
  //   this.isForm_Submitted = true;

  //  if (this.newJobCreation_Form.valid) 
  //   {
  //     let appointmentsCode = this.newJobCreation_Form.controls.appointmentsCode.value;
  //     let appointmentsProceduresCode =  this.newJobCreation_Form.controls.appointmentsProceduresCode.value;

  //     let jobDate = this.newJobCreation_Form.controls.Job_Date.value || "";
  //     jobDate = jobDate ? this.datepipe.transform(jobDate, "yyyy-MM-dd") : "";

  //     let req_body = {
  //           "organizationCode": this._sessionSVC.get("orgCode"),
  //            "date": jobDate,
  //            "specification": this.newJobCreation_Form.controls.Special_Instruction.value,
  //            "shiftMasterCode": this.getJobData_Array.controls[0].get("Shift").value.shiftMasterCode,
  //           "startTime": this.getJobData_Array.controls[0].get("Shift").value.startTime,
  //           "endTime": this.getJobData_Array.controls[0].get("Shift").value.endTime,
  //           "equipments": this.getJobData_Array.controls[0].get("Equipment").value.equipmentName,  
  //           "uniform": this.getJobData_Array.controls[0].get("Uniform").value.uniformName,
  //           "numberOfWorker": this.getJobData_Array.controls[0].get("Job_Qty").value ,
  //           "ratePerHours" : this.getJobData_Array.controls[0].get("ratePerHours").value,
  //           "customerAdminCode" : this.newJobCreation_Form.controls.Site_Supervisor.value.workerCode,
  //          };

  //         //  console.log(req_body);  
  //         //  return;

  //          this._commonSVC.progressLoader = true;
  //          this._newJobSVC.EditJob(appointmentsCode, appointmentsProceduresCode, req_body).subscribe((res: any) => {
  //            this._commonSVC.progressLoader = false;
  //            this.isForm_Submitted = false;
  //            if (res.responsecode == 200) {

  //              if ( this.isShortcutModalOpen || this.AddJobmodalData ||  this.EditJobmodalData ) {
  //                // no navigate
  //                this.closeBsPopUp();
  //               // this.dialog.closeAll();
  //              } else {
  //               this.router.navigate([this.routeLink]);
  //              }
  //              this._commonSVC.successMessage = true;
  //              this._commonSVC.message = res.message;
  //              this.newJobCreation_Form.reset();
  //            } 
  //            else
  //             {

  //              this._commonSVC.errorMessage = true;
  //              this._commonSVC.message = res.message;
  //            }
  //          });


  //   }
  // }


  // Unifrom Add  starts
 
  openUniformPopup(template_ref: any, index: number) {
    this.current_SelectedIndex = index;
    this.dialogRef = this.dialog.open(template_ref, {
      width: "40%",
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.Uniform_Name = "";
      if (result && result.responsecode == 200) {
        this.uniformList.push(result.data);
        this.uniformList_Filter = this.uniformList;
        this.getJobData_Array.controls[this.current_SelectedIndex]
          .get("Uniform")
          .setValue(result.data);
      }
      // else {
      //   this.getAllUniformMaster();
      // }
    });
  }

  saveUniform() {
    if (this.Uniform_Name) {
      var data = {
        organizationCode: this._sessionSVC.get("orgCode"),
        uniformCode: null,
        uniformName: this.Uniform_Name,
      };

      this._newJobSVC.saveUniform(data).subscribe((res: any) => {
        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.dialogRef.close(res);
        } else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });
    }
  }
  // Unifrom Add  ends

  // Equipment Add  starts
  openEquipmentPopup(template_ref: any, index: number) {
    this.current_SelectedIndex = index;
    this.dialogRef = this.dialog.open(template_ref, {
      width: "40%",
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.Equipment_Name = "";

      if (result && result.responsecode == 200) {
        this.equipmentList.push(result.data);
        this.equipmentList_Filter = this.equipmentList;
        this.getJobData_Array.controls[this.current_SelectedIndex]
          .get("Equipment")
          .setValue(result.data);
      }
      // else {
      //   this.getAllEquipmentMaster();
      // }
    });
  }

  saveEquipment() {
    if (this.Equipment_Name) {
      var data = {
        equipmentCode: null,
        equipmentName: this.Equipment_Name,
        organizationCode: this._sessionSVC.get("orgCode"),
      };

      this._newJobSVC.saveEquipment(data).subscribe((res: any) => {
        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.dialogRef.close(res);
        } else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });
    }
  }
  // Equipment Add  ends

  closeBsPopUp() {
    this._commonSVC.resetNotesRequestForm();
    this.modalService.hide();
    // this.dialogRef.close();
    this.triggerEvent("close");
  }

  AddressDetails: any = {};
  handleAddressChange(address: Address) {
    this.AddressDetails = {
      customerApartmentNo: this.extractAddressComponent(address, "apartment"),
      customerAddress: this.extractAddressComponent(address, "route"),
      // customerApartmentNo: this.extractAddressComponent(
      //   address,
      //   "street_number"
      // ),
      customerCity: this.extractAddressComponent(address, "locality"),
      customerCountry: this.extractAddressComponent(address, "country"),
      customerLatitude: address.geometry.location.lat(),
      customerLongitude: address.geometry.location.lng(),
      customerState: this.extractAddressComponent(
        address,
        "administrative_area_level_1"
      ),
      location: address.formatted_address,
      zipCode: this.extractAddressComponent(address, "postal_code"),
    };
  }

  extractAddressComponent(place: Address, componentType: string): string {
    const component = place.address_components.find((comp) =>
      comp.types.some((type) => type === componentType)
    );

    return component ? component.long_name : "";
  }

  dateValueChange(event){
    // console.log('DateChange: ', this.JobminDate, event.value);
    // Convert the date strings to Date objects
    let dateObj1 = new Date(event.value).setHours(0, 0, 0, 0);
    let dateObj2 = new Date(this.JobminDate).setHours(0, 0, 0, 0);

    // console.log('DateChange_1:', dateObj1);
    // console.log('DateChange_2:', dateObj2);

    // Compare the dates
    if (dateObj1 > dateObj2) {
      this.isJobDateLessThenJobminDate = false;
      // console.log("date1 is greater than date2", this.isJobDateLessThenJobminDate);
    } else if (dateObj1 < dateObj2) {
      this.isJobDateLessThenJobminDate = true;
      // console.log("date1 is less than date2", this.isJobDateLessThenJobminDate);
    } else {
      this.isJobDateLessThenJobminDate = false;
      // console.log("date1 is equal to date2", this.isJobDateLessThenJobminDate);
    }
  }

  // add new job site  starts
  openJobSitePopup(template_ref: any) {
    if (this.newJobCreation_Form.controls.Customer.value?.customerCode) {
      this.dialogRef = this.dialog.open(template_ref, {
        width: "40%",
        disableClose: true,
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        //this.new_JobSite ="";
        this.addNewJobSite_Form.reset();
        this.isJobSiteForm_Submitted = false;
        if (result && result.responsecode == 200) {
          this.ddl_CustomerAddressList.push(result.data);
          this.newJobCreation_Form.controls.Job_Site.setValue(result.data);

          this.onJobSiteChange();
        }
      });
    } else {
      this._commonSVC.errorMessage = true;
      this._commonSVC.message = "Please select customer first";
    }
  }

  saveJobSite() {
    this.isJobSiteForm_Submitted = true;
    if (this.addNewJobSite_Form.valid) {
      var data = {
        customerAddress: this.AddressDetails.customerAddress,
        // "customerAddress": this.AddressDetails.location ,
        customerAddressCode: null,
        customerApartmentNo: this.AddressDetails.customerApartmentNo,
        customerCity: this.AddressDetails.customerCity,
        customerCode:
          this.newJobCreation_Form.controls.Customer.value?.customerCode, // selected customer code
        customerCountry: this.AddressDetails.customerCountry,
        customerLatitude: this.AddressDetails.customerLatitude,
        customerLongitude: this.AddressDetails.customerLongitude,
        customerState: this.AddressDetails.customerState,
        location: this.AddressDetails.location,
        zipCode: this.AddressDetails.zipCode,
        siteName: this.addNewJobSite_Form.controls.JobSite_Name.value || "",
        siteContact: this.addNewJobSite_Form.controls.SiteContact.value || "",
        siteContactPhone:
          this.addNewJobSite_Form.controls.SiteContactPhone.value || "",
        siteId: "",
      };

      this._newJobSVC.saveJobSite(data).subscribe((res: any) => {
        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.dialogRef.close(res);
        } else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });
    }
  }
  // add new job site  ends

  // Add new customer  starts
  openCustomerPopup(template_ref: any) {
    this.dialogRef = this.dialog.open(template_ref, {
      width: "40%",
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.newCustomer_Form.reset();
      this.isCustomerForm_Submitted = false;

      if (result && result.responsecode == 200) {
        //this.getAllCustomerList();
        this.customerList.push(result.data);
        this.customerList_Filter = this.customerList;
        this.newJobCreation_Form.controls.Customer.setValue(result.data);
        this.selectedCustomerCode = result.data.customerCode;
        this.onCustomerChange();
      }
    });
  }

  saveCustomer() {
    this.isCustomerForm_Submitted = true;

    if (this.newCustomer_Form.valid) {
      var data = {
        customerCode: null,
        organizationCode: this._sessionSVC.get("orgCode"),
        customerName: this.newCustomer_Form.controls.CustomerName.value,
        customerEmail: this.newCustomer_Form.controls.Email.value,
        mainPhone: this.newCustomer_Form.controls.Mobile.value,
        mainFax: this.newCustomer_Form.controls.Fax.value,
        primaryContact:
          this.newCustomer_Form.controls.PrimaryContact.value || "",
        primaryContactPhone:
          this.newCustomer_Form.controls.PrimaryContact_Mobile.value || "",
      };

      this._newJobSVC.saveNewCustomer(data).subscribe((res: any) => {
        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.dialogRef.close(res);
        } else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });
    }
  } // Add new customer ends


  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }


  getNonWorkingDays() {
    this._commonSVC.getSettingBySettingName("Specific.Non.Working.Days", this._sessionSVC.get("orgCode"), (response) => {
      // //console.log('getWebLogo', response);
      if (response.responsecode == 200) {
        if (response.settingValue) {
          let dates: [] = response.settingValue.split(",")
          dates.forEach((ele: any) => {
            if (ele) {
              this.nonWorkingDays.push(new Date(ele));
            }
          });

          //console.log("this.nonWorkingDays" , this.nonWorkingDays)

        }
      }
    })
  }


  getworkingDays() {
    this._commonSVC.getSettingNameByNameWithOperator("Working.Days.", this._sessionSVC.get("orgCode"))
      .subscribe((response: any) => {

        if (response.responsecode == 200) {
          this.officeDay = response.data.map((setting) => {
            return { ...setting, day: this._commonSVC.getDayByDayName(setting.settingName.split(".")[2]) };
          });
        }

      })

  }


  nonWorkingHourFilter = (d: Date): boolean => {
    if (d) {
      const time = d.getTime();
      const day = d.getDay();
      const disableDay: any[] = this.officeDay.filter(x => x.settingValue === 'false');
      // return (!this.nonWorkingDays.find(x=>x.getTime()==time) || this.officeDay.find(x=> (x.settingValue === 'false' && day !== x.day)));
      //console.log('SaveEditedJob_2', !disableDay.find(x=>x.day==day) && !this.nonWorkingDays.find(x=>x.getTime()==time));
      return !disableDay.find(x => x.day == day) && !this.nonWorkingDays.find(x => x.getTime() == time);
    }

  }


  fillFormAsPerCustomerRequested(data: any) {
    this._commonSVC.progressLoader = true;
    // filling for as per customer requested appoinment data;

    this.disable_whenJobRequested = true;
    // this.newJobCreation_Form.controls.appointmentsCode.setValue(res.data[0].appointmentsCode);
    // this.newJobCreation_Form.controls.appointmentsProceduresCode.setValue(res.data[0].appointmentsProceduresCode);

    this.newJobCreation_Form.controls.Customer.setValue(this.customerList.find(customer => customer.customerCode === data.customerCode));

    this.newJobCreation_Form.controls.Job_Date.setValue(
      data.startDTStr ? moment(data.startDTStr).toDate() : this.datepipe.transform(data.startDT, "yyyy-MM-dd")
    );

    const siteAddress: any[] = this.newJobCreation_Form.controls.Customer.value?.customerAddressResponseList || [];
    this.ddl_CustomerAddressList = siteAddress  //  binding list into jobsite dropdown

    if (siteAddress.length > 0) {
      this.newJobCreation_Form.controls.Job_Site.setValue(siteAddress.find(ele => ele.customerAddressCode === data.customerAddressCode));
      // this.newJobCreation_Form.controls.Site_Address.setValue( data.siteAddress);
      // this.newJobCreation_Form.controls.Site_Contact.setValue( data.siteContact );
      // this.newJobCreation_Form.controls.Site_Contact_Ph.setValue(  data.siteContactPh);
      this.onJobSiteChange();
    }


    this.newJobCreation_Form.controls.Order_Date.setValue(
      data.orderDTStr ? moment(data.orderDTStr).toDate() : this.datepipe.transform(data.orderDT, "yyyy-MM-dd")
    );

    this.newJobCreation_Form.controls.Job_ID.setValue(data.jobId);

    if(data.specification)
      {
   this.newJobCreation_Form.controls.Special_Instruction.setValue(data.specification);

   // overriding value if already added when creating new job
  this.saved_specialInstruction = data.specification ? data.specification : '';
      }

 
    this.getSiteSupervisorList_ForSave(data.customerCode);
 
    //--------------------------- shift form array binding ----------------------------------------------  


    if (data.appointmentsProceduresRequestResponseList.length > 0) {

      this.getJobData_Array.clear() // removing all control list;


      data.appointmentsProceduresRequestResponseList.forEach((element: any) => {
        this.addInJobData_Array()  // adding new controls list
      });

      // filling values in control array list
      data.appointmentsProceduresRequestResponseList.forEach((element: any, index: number) => {

        // this.getJobData_Array.controls[index].get("Shift").setValue(
        //   this.shiftList.find(Shift => Shift.shiftMasterCode === element.shiftMasterCode)
        // );

        // // this control used as view only purpose
        // this.getJobData_Array.controls[index].get("Shift_ViewOnly").setValue(
        //   this.getJobData_Array.controls[index].get("Shift").value?.shiftName
        // );

        this.getJobData_Array.controls[index].get("Shift").setValue(element.shiftMasterCode);
        this.getJobData_Array.controls[index].get("startTime").setValue(element.startTime);
        this.getJobData_Array.controls[index].get("endTime").setValue(element.endTime);

        if(element.breakList && element.breakList.length > 0)
          {
            this.getJobData_Array.controls[index].get("breakForm").setValue(element.breakList);
            this.break_arr_toggle[index] = true  // toggle break form
          }

        this.getJobData_Array.controls[index].get("Job_Req").setValue(
          this.procedureList.find(ele => ele.organizationBaseProcedureCode === element.organizationBaseProcedureCode)
        );

        // just to hold "Job_Req" control's selected option
        this.getJobData_Array.controls[index].get("hold_JobReqSelectedData").setValue(
          this.procedureList.find(ele => ele.organizationBaseProcedureCode === element.organizationBaseProcedureCode)
        );

        //this.onChangeJobReq(index);

        let organizationBaseProcedureCode = element.organizationBaseProcedureCode;
        let customerCode = this.newJobCreation_Form.controls.Customer.value?.customerCode;
        this.getAllJobTypeRateOfCustomer(index, customerCode, organizationBaseProcedureCode);

        this.getJobData_Array.controls[index].get("Job_Qty").setValue(element.numberOfWorker);

        if (element.uniform) {
          this.getJobData_Array.controls[index].get("Uniform").setValue(
            this.uniformList.find(ele => ele.uniformName === element.uniform)
          );
        }

        if (element.equipments) {
          this.getJobData_Array.controls[index].get("Equipment").setValue(
            this.equipmentList.find(ele => ele.equipmentName === element.equipments)
          );
        }

      });
    }
    //---------------------------------------------------------------------------------------------------------------
   
      this._commonSVC.progressLoader = false;
  }

  acceptRequestedJob() {

    this.isForm_Submitted = true;
    if (this.newJobCreation_Form.valid) {
      let array: any = [];

      if (this.getJobData_Array.controls.length > 0) {
        this.getJobData_Array.controls.forEach((element: any) => {

          let temp: any = {
            // shiftMasterCode: element.value.Shift?.shiftMasterCode,
            // startTime: element.value.Shift?.startTime,
            // endTime: element.value.Shift?.endTime,

            shiftMasterCode: element.value.Shift ? element.value.Shift : null,
            startTime: element.value.startTime,
            endTime: element.value.endTime,
            equipments: element.value.Equipment?.equipmentName,
            numberOfWorker: element.value.Job_Qty ? parseInt(element.value.Job_Qty) : element.value.Job_Qty,
            organizationBaseProcedureCode: element.value.Job_Req?.organizationBaseProcedureCode,
            uniform: element.value.Uniform?.uniformName,
            ratePerHours: element.value.ratePerHours,
            shiftBreakRequestList : element.value.breakForm.map((ele: BreakForm) => {
              return {
                "breakType": ele.breakType,
                "duration": ele.duration ? Number(ele.duration) :  null,
                "paidStatus": ele.paidStatus
              }
            })
          
          };

          array.push(temp);
        });
      }

      // let jobDate = this.newJobCreation_Form.controls.Job_Date.value || "";
      // jobDate = jobDate ? this.datepipe.transform(jobDate, "yyyy-MM-dd") : "";

      // let orderDate = this.newJobCreation_Form.controls.Order_Date.value || "";
      // orderDate = orderDate
      //   ? this.datepipe.transform(orderDate, "yyyy-MM-dd")
      //   : "";

      let jobDate = this.newJobCreation_Form.controls.Job_Date.value
      ? moment(this.newJobCreation_Form.controls.Job_Date.value, this.yearMonthDateFormat)
        .format(this.yearMonthDateFormat) : "";  
        
        let orderDate = this.newJobCreation_Form.controls.Order_Date.value
      ? moment(this.newJobCreation_Form.controls.Order_Date.value, this.yearMonthDateFormat)
        .format(this.yearMonthDateFormat) : "";   
        

      let final_Data: any = {
        customerAddressCode: this.newJobCreation_Form.controls.Job_Site.value?.customerAddressCode,
        // customerAdminCode: this.newJobCreation_Form.controls.Site_Supervisor.value  ?.customerAdminCode || null,
        customerAdminCode: this.newJobCreation_Form.controls.Site_Supervisor.value?.workerCode || null,
        customerCode: this.newJobCreation_Form.controls.Customer.value?.customerCode,
        endDate: jobDate,
        startDate: jobDate,
        jobTypeWithJobData: array,
        orderDT: orderDate,
        organizationCode: this._sessionSVC.get("orgCode"),
        siteContact: this.newJobCreation_Form.controls.Site_Contact.value,
        siteContactPh: this.newJobCreation_Form.controls.Site_Contact_Ph.value,
        specification: this.newJobCreation_Form.controls.Special_Instruction.value,
        jobId: this.newJobCreation_Form.controls.Job_ID.value,

        isDateOfMonth: false,
        daysList: [],
        repeatType: "day",
        repeatValue: 1,
      };

      this._commonSVC.progressLoader = true;

      let appointmentsRequestCode = this.is_RequestedJob_Data.appointmentsRequestCode;

      this._newJobSVC.createJobByCustomerRequest(appointmentsRequestCode, final_Data).subscribe((res: any) => {
        this._commonSVC.progressLoader = false;
        this.isForm_Submitted = false;

        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.newJobCreation_Form.reset();
          this._commonSVC.dialogRef.close('refresh');
        }
        else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;

        }
      });
    }
  }


  onChangeJobReq(event: MatSelectChange, index: number) {

    let organizationBaseProcedureCode = this.getJobData_Array.controls[index].get("Job_Req").value?.organizationBaseProcedureCode;
    let customerCode = this.newJobCreation_Form.controls.Customer.value?.customerCode;

    if (!this.newJobCreation_Form.controls.Customer.value?.customerCode) {
      this.getJobData_Array.controls[index].get("Job_Req").setValue('');
      this._commonSVC.errorMessage = true;
      this._commonSVC.message = "Please select customer";

      return;
    }

     else if (!this.newJobCreation_Form.controls.Job_Site.value?.customerAddressCode)
      {
         this.getJobData_Array.controls[index].get("Job_Req").setValue('');
        this._commonSVC.errorMessage = true;
        this._commonSVC.message = "Please select job site";

       return;
    }
      
    else if (index === 0 && this.getJobData_Array.controls[index].get("appointmentsProceduresCode").value) 
    {
      if(this._count == 0)
      {// asking for the confirmation based on "_count" flag;
      this.askBeforeChange(index, (confirmed: boolean) => {
        if (confirmed) {
          this._count += 1;
          this.getJobData_Array.controls[index].get("hold_JobReqSelectedData").setValue(event.source.value); // just holding selected option
          this.getAllJobTypeRateOfCustomer(index, customerCode, organizationBaseProcedureCode);
          this.fillWith_PrevAddedData_if(index);
        }
        else {
          this._count == 0;
          this.getJobData_Array.controls[index].get("Job_Req").setValue(
            this.getJobData_Array.controls[index].get("hold_JobReqSelectedData").value
          )
          //event.source.writeValue(this.getJobData_Array.controls[index].get("hold_JobReqSelectedData").value);
        }
      });
    }
 
    if(this._count > 0)
    {
      this.getJobData_Array.controls[index].get("hold_JobReqSelectedData").setValue(event.source.value); // just holding selected option
      this.getAllJobTypeRateOfCustomer(index, customerCode, organizationBaseProcedureCode);
      this.fillWith_PrevAddedData_if(index);
    }


    }

    else {
      this.getJobData_Array.controls[index].get("hold_JobReqSelectedData").setValue(event.source.value); // just holding selected option
      this.getAllJobTypeRateOfCustomer(index, customerCode, organizationBaseProcedureCode);
      this.fillWith_PrevAddedData_if(index);
    }



  }


  private getAllJobTypeRateOfCustomer(index: number, customerCode: string, organizationBaseProcedureCode: string, methodCalledWhenEdit?:boolean) {
    this._newJobSVC.getRatePerHour(organizationBaseProcedureCode, customerCode).subscribe((res: any) => {

      // binding value to form array control at specified index

      let regularRate = res?.data?.regularRate ? res?.data?.regularRate : ''

      if(methodCalledWhenEdit)
        {
          if(this.getJobData_Array.controls[index].get("ratePerHours").value)
            {
              regularRate = this.getJobData_Array.controls[index].get("ratePerHours").value
            }
        }

      this.getJobData_Array.controls[index].get("ratePerHours").setValue(regularRate);
      this.getJobData_Array.controls[index].get("JobTypeRateData").setValue(res?.data ? res?.data : '');

    });
  }


  openShiftPopUp(index: number) {
   // console.log('SelectedIndex', this.current_SelectedIndex)
    this.current_SelectedIndex = index;
    this.dialogRef = this.dialog.open(this.shiftSelect_TemplateRef, { disableClose: true, width: '600px',panelClass:'popup-pos' });

  }


  selectShift_btn(selected_shift: any) {
    this.getJobData_Array.controls[this.current_SelectedIndex].get("Shift").setValue(selected_shift);

    // this control used as view only purpose
    this.getJobData_Array.controls[this.current_SelectedIndex].get("Shift_ViewOnly").setValue(selected_shift.shiftName);
    this.selectedShift = selected_shift;
    this.dialogRef.close();
  }

  updateShift(data: any) {
    this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true, data: data });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllShiftMaster();
      }

    });
  }

  openAddShiftPopUp(): void {

    this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllShiftMaster();
      }

    });
  }



  rateHrOnBlur(index: number) {
    if (this.getJobData_Array.controls[index].get("ratePerHours").valid) {
      if (this.getJobData_Array.controls[index].get("JobTypeRateData").value &&
        this.getJobData_Array.controls[index].get("ratePerHours").value) {
        if (this.getJobData_Array.controls[index].get("JobTypeRateData").value?.regularRate !=
          parseFloat(this.getJobData_Array.controls[index].get("ratePerHours").value)) {
          const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
            width: '400px',
            disableClose: true,
            data: { msg: 'You have changed the Billing Rate for the Customer/Job Type. Do you want to save this for the future jobs?' }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result && result.toLowerCase() === 'ok') {
              const req: any[] = [{
                customerCode: this.getJobData_Array.controls[index].get("JobTypeRateData").value?.customerCode,
                customerJobTypeRateCode: this.getJobData_Array.controls[index].get("JobTypeRateData").value?.customerJobTypeRateCode,
                organizationBaseProcedureCode: this.getJobData_Array.controls[index].get("JobTypeRateData").value?.organizationBaseProcedureCode,

                overTimeRate: this.getJobData_Array.controls[index].get("JobTypeRateData").value?.overTimeRate ?
                  parseFloat(this.getJobData_Array.controls[index].get("JobTypeRateData").value?.overTimeRate) : 0,

                regularRate: this.getJobData_Array.controls[index].get("ratePerHours").value
                  ? parseFloat(this.getJobData_Array.controls[index].get("ratePerHours").value) : 0
              }]


              this.allow_BtnDisable = true;
              this._jobRateCardSVC.saveOrUpdateCustomerJobTypeRate(req, (response) => {
                this.allow_BtnDisable = false;
                if (response.responsecode === 200) {
                  this._commonSVC.message = response.message;
                  this._commonSVC.successMessage = true;

                  // updating the controls;  instead calling api for refresh data
                  let TempRateData: any = this.getJobData_Array.controls[index].get("JobTypeRateData").value;
                  TempRateData.regularRate = parseFloat(this.getJobData_Array.controls[index].get("ratePerHours").value);
                  //this.getJobData_Array.controls[index].get("JobTypeRateData").setValue(TempRateData)

                }

              })
            }
            else {
              // updating the controls; 
              // so that next time if user focuses on textbox and do not change text , then he wont get confirmation pop up again.

              let TempRateData: any = this.getJobData_Array.controls[index].get("JobTypeRateData").value;
              TempRateData.regularRate = parseFloat(this.getJobData_Array.controls[index].get("ratePerHours").value);

            }
          });
        }
      }
    }

  }

  // specialInsOnBlur(actionFrom = null) {

  specialInsOnBlur() {
    // debugger
    // if (this.newJobCreation_Form.controls.Special_Instruction.valid) {
      if (this.newJobCreation_Form.controls.Special_Instruction.value && 
        this.newJobCreation_Form.controls.Job_Site.value?.customerAddressCode
      ) {

      // if (this.saved_specialInstruction && this.newJobCreation_Form.controls.Special_Instruction.value.trim()) {

        if (this.saved_specialInstruction?.trim() !== this.newJobCreation_Form.controls.Special_Instruction.value.trim()) 
          {

        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
            width: '400px',
            disableClose: true,
            data: { msg: 'You have changed the Special Instructions for the Customer. Do you want to save this for the future jobs?' }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result && result.toLowerCase() === 'ok') {
              const req: any = {
                "customerAddressCode": this.newJobCreation_Form.controls.Job_Site.value.customerAddressCode,
                "specialInstructions": this.newJobCreation_Form.controls.Special_Instruction.value
              }

              // console.log('Sp_req: ', req);              
              // return;

              this.allow_BtnDisable = true;
              this._newJobSVC.saveOrUpdateCustomerSpecialInstruction(req).subscribe((response) => {

                this.allow_BtnDisable = false;
                if (response.responsecode === 200) 
                  {

                  // this.isSpecial_InstructionValueChange = false;

                  // if (typeof this[actionFrom] === 'function') {
                  //   this[actionFrom]();
                  // }

                  this._commonSVC.message = response.message;
                  this._commonSVC.successMessage = true;

                  // updating the controls;  instead calling api for refresh data
                  let tempVal = this.newJobCreation_Form.controls.Job_Site.value;
                  tempVal.specialInstructions = this.newJobCreation_Form.controls.Special_Instruction.value;

                  this.saved_specialInstruction = this.newJobCreation_Form.controls.Special_Instruction.value;
                } 
                else 
                {
                  
                  this._commonSVC.message = response.message;
                  this._commonSVC.errorMessage = true;

                  // this.isSpecial_InstructionValueChange = false;
                  // if (typeof this[actionFrom] === 'function') {
                  //   this[actionFrom]();
                  // }
                }

              })
            }
            // else {
            //   this.isSpecial_InstructionValueChange = false;
            //       if (typeof this[actionFrom] === 'function') {
            //         this[actionFrom]();
            //       }
            //   // updating the variable; (if user do not want to update).
            //   // so that next time if user focuses on textbox and do not change text , then he wont get confirmation pop up again.
            //   const savedValueGet = this.saved_specialInstruction || this.jobEditData_fromAPI.specification || this.newJobCreation_Form.controls.Special_Instruction.value;
            //   this.newJobCreation_Form.controls.Special_Instruction.setValue(savedValueGet);
            // }
          });
        }

     // }
    }
  }

  askBeforeDelete(index: number, callback: (confirmed: boolean) => void) {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      disableClose: true,
      data: { msg: 'Are you sure want to delete this shift?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.toLowerCase() === 'ok') {
        // this.jobEditableData = resetJobEditableData();
        this.deleted_appointmentsProceduresCode.push(this.getJobData_Array.controls[index].get("appointmentsProceduresCode").value);
        callback(true);
      } else {
        callback(false);
      }
    });
  }

  // updateAndNotifyJob()
  // {
  //   this.isForm_Submitted = true;

  //   if (this.newJobCreation_Form.valid) 
  //   {     
 
  //   if(this.getJobData_Array.controls.length > 1
  //      || this.getJobData_Array.controls[0].get("Job_Req").value?.organizationBaseProcedureCode !== this.jobEditData_fromAPI.organizationBaseProcedureCode
  //      )
       
  //      {
  //       this.is_notify = true ;
  //       if(this.staff_notify_mode && this.staff_notify_mode.toLowerCase() === 'auto')
  //         {
           
  //           this.saveEditedJob();
  //         }
  //         else
  //         {
  //           this.openDistributionPopUp();
  //         }

         

  //       //  if(this.isSpecial_InstructionValueChange){
  //       //   this.specialInsOnBlur('openDistributionPopUp');
  //       //   return true;
  //       // }
  //      }
  //      else
  //      {
  //       // if(this.isSpecial_InstructionValueChange){
  //       //   this.specialInsOnBlur('saveEditedJob');
  //       //   return true;
  //       // }

  //       this.is_notify = false ;
  //       this.saveEditedJob();
  //      }
       
  //     }
  // }
  

  async updateAndNotifyJob() {
    this.isForm_Submitted = true;

    if (this.newJobCreation_Form.valid) {
      this.is_notify = true;
      if (this.staff_notify_mode && this.staff_notify_mode.toLowerCase() === 'auto') 
        {
          // this.saveEditedJob();
          const res:INotifyCriteria | '' | null = await firstValueFrom(this.selectCriteria());

          if(res)
          { 
            this.notifyCriteria =  res ;
            this.saveEditedJob();
          }
      }

      else {
        this.openDistributionPopUp();
      }

    }

  }
  

  
  saveEditedJob() {
    this.isForm_Submitted = true;

    if (this.newJobCreation_Form.valid) {

      // if(this.isSpecial_InstructionValueChange){
      //   this.specialInsOnBlur('saveEditedJob');
      //   return true;
      // }

      let array: any = [];

      let editableJobData: jobEditableData = {
        "appointmentsProceduresCode": null,
        "organizationBaseProcedureCode": null,
        "endTime": "",
        "equipments": "",
        "numberOfWorker": 0,
        "ratePerHours": 0,
        "shiftMasterCode": null,
        "shiftBreakList": null,
        "startTime": "",
        "uniform": ""
      }

      if (this.getJobData_Array.controls.length > 0) {
        this.getJobData_Array.controls.forEach((element: any, index: number) => {

          if (index === 0 && element.value.appointmentsProceduresCode) {
           // console.log('EleData: ', this.jobEditData_fromAPI);
            editableJobData =
            {
              "appointmentsProceduresCode": element.value.appointmentsProceduresCode,
              "organizationBaseProcedureCode": element.value.Job_Req?.organizationBaseProcedureCode,
              "endTime": element.value.endTime,
              "equipments": element.value.Equipment?.equipmentName,
              "numberOfWorker": element.value.Job_Qty ? parseInt(element.value.Job_Qty) : element.value.Job_Qty,
              "ratePerHours": element.value.ratePerHours,
              "shiftMasterCode": this.jobEditData_fromAPI.shiftMasterCode,
              "startTime":  element.value.startTime,
              "uniform": element.value.Uniform?.uniformName,
              "shiftBreakList": element.value.breakForm,
            }
          }
          else {
            let temp: any = {
              // shiftMasterCode: this.jobEditData_fromAPI.shiftMasterCode,

              shiftMasterCode: element.value.Shift ? element.value.Shift : null,
               startTime: element.value.startTime,
              endTime: element.value.endTime,
              equipments: element.value.Equipment?.equipmentName,
              numberOfWorker: element.value.Job_Qty ? parseInt(element.value.Job_Qty) : element.value.Job_Qty,
              organizationBaseProcedureCode: element.value.Job_Req?.organizationBaseProcedureCode,
              uniform: element.value.Uniform?.uniformName,
              ratePerHours: element.value.ratePerHours,
              shiftBreakList: element.value.breakForm,
            };

            array.push(temp);
          }
        });
      }

      const finalJSON = {
        "organizationCode": this._sessionSVC.get("orgCode"),
        "editJobsRequestV1": editableJobData,
        "jobTypeWithJobData": array,
        "removeJobTypeList": this.deleted_appointmentsProceduresCode,
        "specification": this.newJobCreation_Form.controls.Special_Instruction.value,
        "customerAdminCode": this.newJobCreation_Form.controls.Site_Supervisor.value?.workerCode,
         "noteRequest": this._commonSVC.noteRequest,
        "isNotify" : this.is_notify,
        
        // "distributionCodeList": this.selectedDistribution.length > 0 
        //                           ? this.selectedDistribution 
        //                           : this.notifyCriteria.distributionCodeList,

        "distributionCodeList" : this.selectedDistributions,
        "distance": this.notifyCriteria.distance,
        "numberOfNotify": this.notifyCriteria.numberOfNotify,
        "selfSignUp": this.notifyCriteria.selfSignUp,
        
        "staffCodeList" :  this.notifyCriteria.staffCodeList && this.notifyCriteria.staffCodeList.length > 0
                             ? this.notifyCriteria.staffCodeList
                             : null  
      }

     // console.log("edit job", finalJSON)

      let appointmentsCode = this.newJobCreation_Form.controls.appointmentsCode.value;

      let jobDate = this.newJobCreation_Form.controls.Job_Date.value
        ? moment(this.newJobCreation_Form.controls.Job_Date.value, this.yearMonthDateFormat)
          .format(this.yearMonthDateFormat) : "";  
          // console.log('finalJSON', finalJSON);
          // return;  
      this._commonSVC.progressLoader = true;

       this._newJobSVC.v3_EditJob(appointmentsCode, jobDate, finalJSON).subscribe((res: any) => {
        
      this._commonSVC.progressLoader = false;
        this.isForm_Submitted = false;

        if (res.responsecode == 200) {

          if (this.isShortcutModalOpen || this.AddJobmodalData || this.EditJobmodalData) {
            // no navigate
            if(this.btnDistribution_closePopUp)
            {
              this.btnDistribution_closePopUp.nativeElement.click();
            }
             
            this.closeBsPopUp();
            // this.dialog.closeAll();
          } else {  this.router.navigate([this.routeLink]);  }

          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.newJobCreation_Form.reset();
          //this.notifyStaffWhenJobEdit = false;
        }
        else if (res.responsecode === 405) {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
          this.filtered_StaffList = res.data;

          const dialogRef = this.dialog.open(this.UnAssignStaffPopUp, { disableClose: true, width: '600px' });

          dialogRef.afterClosed().subscribe(result => {
            this.filtered_StaffList = [];
          });

        }
        else {

          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });


    }

  }

 
  askBeforeChange(index: number, callback: (confirmed: boolean) => void) {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      disableClose: true,
      data: { msg: 'Are you sure want to change job request?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.toLowerCase() === 'ok') {
        callback(true);
      } else {
        callback(false);
      }
    });
  }



  UnAssignJobs(btn_closePopUp: HTMLElement) {
    let unAssignStaff = [];

    this.filtered_StaffList.forEach((ele: any) => {
      if (ele.isChecked)
        unAssignStaff.push({
          "appointmentsDurationCode": ele.appointmentsDurationCode,
          "workerCode": ele.workerCode,
        })

    })

    if (unAssignStaff.length > 0) {
      this._commonSVC.progressLoader = true;
      this._newJobSVC.multipleJobUnAssignedByAdmin(this._sessionSVC.get("orgCode"), unAssignStaff).subscribe((res: any) => {
        this._commonSVC.progressLoader = false;

        if (res.responsecode === 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          btn_closePopUp.click();
        }
        else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }

      });
    }

  }


  getDistributionList() {
    let org_code = this._sessionSVC.get('orgCode');
    this._newJobCardSVC.getAllDistributionList(org_code).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.distributionList = res.data;
       // this.distributionList_Filter = this.distributionList;

        // this.distributionSeachInput.valueChanges
        //   .pipe(takeUntil(this._onDestroy))
        //   .subscribe(() => {
        //     this.filterDistribution();
        //   });
      }
    });
  }


  openDistributionPopUp()
  {
    this.isForm_Submitted = true;
    if (this.newJobCreation_Form.valid) 
    {
      // if(this.isSpecial_InstructionValueChange){
      //   this.specialInsOnBlur('openDistributionPopUp');
      //   return true;
      // }
 

    const dialogRef = this.dialog.open(this.distribution_sendNotificationPopUp, {
      width: '450px',
      disableClose: true,
      panelClass : 'popup-pos'
     });

    dialogRef.afterClosed().subscribe(result => {
      this.selectedDistribution = [];
      
    });

  }

  }



  /*
 // checkForFormValueChange() method will return true or false
 // based on form value changed (compare with previous value from api)
 // note* : comparing at the time of edit and only on enabled controls value changes
  */

  get checkForFormValueChanges() : boolean
  {
    let isValueChangesInForm : boolean = false;

    if(this.newJobCreation_Form.valid)
    {
   
    if(this.getJobData_Array.controls.length > 1)
    {
      isValueChangesInForm = true;
    }

    else if(
      (this.newJobCreation_Form.controls.Site_Supervisor.value && this.newJobCreation_Form.controls.Site_Supervisor.value?.workerCode !== this.jobEditData_fromAPI.customerAdminCode)
      || (this.newJobCreation_Form.controls.Special_Instruction.value.trim() && this.newJobCreation_Form.controls.Special_Instruction.value.trim() !== this.jobEditData_fromAPI.specification.trim())
    )
    {
      isValueChangesInForm = true;
    }

    else if(this.checkValueChangeForFirstShift_FromFormArray)
    {
      isValueChangesInForm = true;
    }
    else
    {
      isValueChangesInForm = false;
    }

  }
    return isValueChangesInForm ;
  }

 
 /*
 // checkValueChangeForFirstShift_FromFormArray() method will return true or false
 // it will compare first index form  (at 0 index) from shift form array
 // detect value changes and return true false accordingly
 // note* : it will compare only first index form (at 0 index) in the form array ( with previous saved data in DB)
  */
  get checkValueChangeForFirstShift_FromFormArray(): boolean
  {
    let isChangeFound:boolean = false;
    if(this.getJobData_Array.controls[0].get("Shift").value && this.getJobData_Array.controls[0].get("Shift").value !== this.jobEditData_fromAPI.shiftMasterCode
    || this.getJobData_Array.controls[0].get("Job_Req").value && this.getJobData_Array.controls[0].get("Job_Req").value?.organizationBaseProcedureCode !== this.jobEditData_fromAPI.organizationBaseProcedureCode
    || this.getJobData_Array.controls[0].get("Job_Qty").value && this.getJobData_Array.controls[0].get("Job_Qty").value  != this.jobEditData_fromAPI.numberOfWorker
    || this.getJobData_Array.controls[0].get("ratePerHours").value && this.getJobData_Array.controls[0].get("ratePerHours").value  != this.jobEditData_fromAPI.ratePerHours
    || this.getJobData_Array.controls[0].get("Equipment").value && this.getJobData_Array.controls[0].get("Equipment").value.equipmentName  !== this.jobEditData_fromAPI.equipments
    || this.getJobData_Array.controls[0].get("Uniform").value && this.getJobData_Array.controls[0].get("Uniform").value.uniformName  !== this.jobEditData_fromAPI.uniform
    )
    isChangeFound = true;
   

    return isChangeFound;
  }



   /*
 // fillWith_PrevAddedData_if() will get previously added "Equipment" and  "Uniform" Data
 // By ( job request (organizationBaseProcedureCode) , customer code, and customerAddressCode)
 
  */
fillWith_PrevAddedData_if(shiftFormIndex:number)
{
  let customerCode = this.newJobCreation_Form.controls.Customer.value?.customerCode; // selected customer code
  let customerAddressCode =  this.newJobCreation_Form.controls.Job_Site.value?.customerAddressCode; // selected customer Address code
  let orgBaseprocedureCode =  this.getJobData_Array.controls[shiftFormIndex].get("Job_Req").value?.organizationBaseProcedureCode

  if(customerCode && customerAddressCode && orgBaseprocedureCode)
    {
  this._newJobCardSVC.getUnifornEquipmentFromLastShift(customerCode, customerAddressCode, this._sessionSVC.get("orgCode") ,orgBaseprocedureCode).subscribe((res: any) => {
    if (res.responsecode == 200) 
      {
      //console.log("fillWith_PrevAddedData_if", res);
      if(res.data.uniform)
        {
          this.getJobData_Array.controls[shiftFormIndex].get("Uniform").setValue(
            this.uniformList.find(ele => ele.uniformName === res.data.uniform)
          );
        } else {
          this.getJobData_Array.controls[shiftFormIndex].get("Uniform").setValue(null);
        }

        if(res.data.equipments)
          {
            this.getJobData_Array.controls[shiftFormIndex].get("Equipment").setValue(
              this.equipmentList.find(ele => ele.equipmentName === res.data.equipments)
            );
          } else {
            this.getJobData_Array.controls[shiftFormIndex].get("Equipment").setValue(null);
          }
 
     }
  });
}

}
 



masterAPiForkCalling()
{
  this._commonSVC.progressLoader = true;

  forkJoin({
    customerList_Mst :  this._newJobSVC .getAllCustomerDetails(this._sessionSVC.get("orgCode")),
    shiftList_Mst : this._newJobSVC.getAllShiftMaster(this._sessionSVC.get("orgCode")),
    procedureList_Mst : this._newJobSVC.getAllProcedure(this._sessionSVC.get("orgCode")),
    equipementList_Mst : this._newJobSVC.getAllEquipmentMaster(this._sessionSVC.get("orgCode")),
    uniformList_Mst : this._newJobSVC.getAllUniformMaster(this._sessionSVC.get("orgCode"))
   }).subscribe(
    {
    next: ({ customerList_Mst, shiftList_Mst ,procedureList_Mst, equipementList_Mst, uniformList_Mst}) => {
     
      this._commonSVC.progressLoader = false;
      this.handleCustomerListResponse(customerList_Mst);
      this.handleShiftListResponse(shiftList_Mst);
      this.handleProcedureListResponse(procedureList_Mst);
      this.handleEquipmentListResponse(equipementList_Mst);
      this.handleUniformListResponse(uniformList_Mst);

    },
    error: (err) => 
      {
        this._commonSVC.progressLoader = false;

        // if any of above api gets errors
      console.error('Error occured in forked api calling...', err);
    }
  });
}


handleCustomerListResponse(res:any)
{
  if (res.responsecode == 200) {
    this.customerList = res.data;
    this.customerList_Filter = this.customerList;
    //console.log("getAllCustomerList customerList", this.customerList);
    // //console.log("getAllCustomerList AddJobmodalData", this.AddJobmodalData);
    // //console.log("getAllCustomerList EditJobmodalData", this.EditJobmodalData);
    if (this.AddJobmodalData) {
      if (this.AddJobmodalData.selectedCustomerCode) {
        const customer = this.customerList.filter(
          (customer: any) =>
            customer.customerCode ==
            this.AddJobmodalData.selectedCustomerCode
        );
        if (customer.length > 0) {
          this.newJobCreation_Form.controls.Customer.setValue(
            customer[0]
          );
        }
      } else {
        this.newJobCreation_Form.controls.Customer.setValue("");
      }

      this.ddl_CustomerAddressList =
        this.newJobCreation_Form.controls.Customer.value
          ?.customerAddressResponseList || [];

      //console.log("ddl_CustomerAddressList",this.ddl_CustomerAddressList);

      if (this.AddJobmodalData.selectedSiteCode) {
        const customerAddress = this.ddl_CustomerAddressList.filter(
          (customerAddress: any) =>
            customerAddress.customerAddressCode ==
            this.AddJobmodalData.selectedSiteCode
        );
        if (customerAddress.length > 0) {
          this.newJobCreation_Form.controls.Job_Site.setValue(
            customerAddress[0]
          );
          this.onJobSiteChange();
        }
      } else {
        this.newJobCreation_Form.controls.Job_Site.setValue("");
      }
      if (this.AddJobmodalData.JobDate) {
        // const JobDate = this.datepipe.transform(
        //   this.AddJobmodalData.JobDate,
        //   "yyyy-MM-dd"
        // );

        // const JobDate  = moment(this.AddJobmodalData.JobDate).format(this.yearMonthDateFormat);
        const JobDate  = moment(this.AddJobmodalData.JobDate).toDate();
        this.newJobCreation_Form.controls.Job_Date.setValue(JobDate);
      } else {
        this.newJobCreation_Form.controls.Job_Date.setValue("");
      }
    }

    else if (this.EditJobmodalData) {
      this.bindForm();
    }

    else if (this.is_RequestedJob && this.is_RequestedJob_Data) {
      setTimeout(() => { // assuming that all the master api should get response before binding form
        this.fillFormAsPerCustomerRequested(this.is_RequestedJob_Data);
      }, 1000)

    }

  } else {
    this.newJobCreation_Form.controls.Customer.setValue("");
    this.newJobCreation_Form.controls.Job_Site.setValue("");
    this.newJobCreation_Form.controls.Job_Date.setValue("");
  }
}

handleShiftListResponse(res:any)
{
  if (res.responsecode == 200)
     {
    this.shiftList = res.data;
    this.shiftList_Filter = res.data;
    }
}

handleProcedureListResponse(res:any)
{
  if (res.responsecode == 200) {
    this.procedureList = res.data;
    this.procedureList_filter = res.data;

    this.bindSupervisorDropDown();
  } 
}

handleEquipmentListResponse(res:any)
{
  if (res.responsecode == 200) {
    this.equipmentList = res.data;
    this.equipmentList_Filter = this.equipmentList;
  }
}

handleUniformListResponse(res:any)
{
  if (res.responsecode == 200) {
    this.uniformList = res.data;
    this.uniformList_Filter = this.uniformList;
  }
}


openBreakPopUp(index:number , tempRef : TemplateRef<any>)
{
  const dialogRef = this.dialog.open(tempRef, {
    width: "50%",
    disableClose: false,
    // panelClass : 'popup-pos'
  });

  dialogRef.afterClosed().subscribe((result) => { });
}


captureFormData(event:any, index:number)
{
  //.log("event",event);
  this.getJobData_Array.controls[index].get('breakForm').setValue(event);
 // this.toggleBreak(index);
}

handleNoBreak(event:any, index:number)
{
  this.toggleBreak(index);
  this.getJobData_Array.controls[index].get('breakForm').setValue([]);
}
 
toggleBreak(index: number): void {
  this.break_arr_toggle[index] = !this.break_arr_toggle[index];
}


getStaffNotifyMode() {
  this._commonSVC.getSettingNameByNameWithOperator(settings_keys.SCHEDULING_MODE, this._sessionSVC.get("orgCode"))
    .subscribe((response: IApiResponse) => {

      if(response.responsecode == 200)
      {
        this.staff_notify_mode = response.data[0]?.settingValue ;
      }
      
   });

}


async save_and_notify()
{

  this.isForm_Submitted = true;
    if (this.newJobCreation_Form.valid) 
    {

  this.is_notify = true ;

  if(this.staff_notify_mode && this.staff_notify_mode.toLowerCase() === 'auto')
    {
      //this.AddUpdateJob();
     const res:INotifyCriteria | '' | null = await firstValueFrom(this.selectCriteria());

     if(res)
     { 
       this.notifyCriteria =  res ;
       this.AddUpdateJob();
     }

    }
    else
    {
      this.openDistributionPopUp();
    }

  }
}

createNewJob()
{
  this.is_notify = false ;
  this.AddUpdateJob();

}

updateJob()
{
this.is_notify = false ;
this.saveEditedJob();
}

 

selectCriteria() {
  const dialogRef = this.dialog.open(NotifyCriteriaFormComponent, {
    width: "50%",
    disableClose: true,
     panelClass : 'popup-pos'
  });

  return dialogRef.afterClosed(); 
}


resetNotifyCriteria()
{
  this.notifyCriteria = {
    distance: null,
    distributionCodeList: [],
    numberOfNotify: null,
    selfSignUp: null,
    staffCodeList : []
  }
}


get selectedDistributions():string[] {
  if(this.selectedDistribution.length > 0 && this.notifyCriteria.distributionCodeList.length == 0)
    return this.selectedDistribution 
  else if(this.notifyCriteria.distributionCodeList.length > 0 && this.selectedDistribution.length == 0)
    return this.notifyCriteria.distributionCodeList
  else
   return []
 
}

 

}
