<div class="gig-loader" *ngIf="common?.progressLoader">
  <img src="assets/gig_loader.png" alt="">
</div>

<div class="success message" *ngIf="common?.loginsuccessMessage">
  <div class="messageBody" [style.width]="common?.success_warning_error_BodyWidth">
    <div class="message-logo">
      <a class="btn-close float-end" (click)="closeSuccess()"></a>
      <div class="mat-ico m-1"> <i class="material-icons success-logo"> done </i> </div>
      <p>Success!</p>
    </div>
    <div class="text-center success-msg ps-2 pe-2">
      <small>{{common?.loginmessage}}</small>
    </div>
    <button mat-raised-button class=" cursor-pointer mt-4 mb-4" (click)="closeSuccess()">Ok</button>
  </div>
</div>

<div class="success message" *ngIf="common?.warningMessage">
  <div class="messageBody" [style.width]="common?.success_warning_error_BodyWidth">
    <div class="message-logo">
      <a class="btn-close float-end" (click)="closeWarning()"></a>
      <div class="mat-ico m-1"> <i class="material-icons warning-logo"> report_problem </i> </div>
      <p>Alert!</p>
    </div>
    <div class="text-center success-msg ps-2 pe-2">
      <small>{{common?.loginmessage}}</small>
    </div>
    <button mat-raised-button class=" cursor-pointer mt-4 mb-4" (click)="closeWarning()">Ok</button>
  </div>
</div>

<div class="success message" *ngIf="common?.loginerrorMessage">
  <div class="err-msg-body" [style.width]="common?.success_warning_error_BodyWidth">
    <div class="message-logo">
      <a class="btn-close float-end" (click)="closeError()"></a>
      <div class="mat-ico m-1 text-center"> <i class="material-icons err-logo"> clear </i> </div>
      <p class="display-6">Error!</p>
    </div>
    <div class="text-center success-msg ps-2 pe-2">
      <small>{{common?.loginmessage}}</small>
    </div>
    <button mat-raised-button class=" cursor-pointer mt-4 mb-4" (click)="closeError()">Ok</button>
  </div>
</div>

<router-outlet></router-outlet>