import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment';
import { CommonService } from '../common.service';
import { DateFormat, DateFormatType } from '../enum/constant';

@Injectable({
  providedIn: 'root'
})

export class GigDateService {

 // private timeZone : string

  constructor(
    private sessionService : SessionStorageService,
    private commonService : CommonService
  ) { 

    //this.timeZone = this.sessionService.get("timeZone");
     
    // if(!this.sessionService.get("timeZone"))
    // {
    //   this.getOrganizationTimeZone();
    // }
    // else{
    //   this.timeZone = this.sessionService.get("timeZone");
    // }

  }

 
//  private getOrganizationTimeZone()
//   {
//     this.commonService.getTimeZoneByCode(
//       this.sessionService.get('orgCode')
//     ).subscribe((res: any) => {

//       if (res.responsecode == 200) {
//        this.sessionService.set('timeZone', res.settingValue);
//       // this.timeZone = res.settingValue ;
//       }
 
      
//     });
//   }


  getCurrentDate(input?:moment.MomentInput,  format:moment.MomentFormatSpecification = DateFormat.YYYY_MM_DD) : string
  {
    //return moment(input).tz(this.timeZone).format(`${format}`);

    // return moment
    //     .utc(moment(input), DateFormat.YYYY_MM_DD)
    //     .format(`${format}`);


    return moment(input ? input : new Date())
    .tz(this.timeZone)
    .format(`${format}`);

  }


  getCurrentDateTime(input?:moment.MomentInput, format:DateFormatType = DateFormat.YYYY_MM_DD) : string
  {
    //return moment(input).tz(this.timeZone).format(`${format} ${DateFormat.HH_MM_SS}`);

    // return moment
    //     .utc(input, DateFormat.YYYY_MM_DD)
    //     .format(`${format} ${DateFormat.HH_MM_SS}`);

    return moment(input ? input : new Date())
    .tz(this.timeZone)
    .format(`${format} ${DateFormat.HH_MM_SS}`);


  }

  getCurrentMoment(input?:moment.MomentInput) : moment.Moment
  {
    return moment(input ? input : new Date()).tz(this.timeZone);
  }

  

  get timeZone() : string {
    return this.sessionService.get("timeZone") 
           ? this.sessionService.get("timeZone")
           : moment.tz.guess()
  }

}
