import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
 import { ApiRootService } from 'src/app/shared/api-root.service';
 

@Injectable({
  providedIn: 'root'
})
export class NewJobCardService {

  constructor(
    public http: HttpClient,
    public _apiSVC: ApiRootService,
  ) { }


  getAllDistributionList(org_code:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getDistributionListByOrganizationCodeByPage/' + org_code+ '?page=0&limit=1000')
  }

  PublishJob(request_body) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/publishJobs' , request_body)
  }


  getEligibleStaffsByAppointmentsDurationCode(appoinmentDurationCode:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleTechnicianWithoutPreferredHoursForAppointmentByAppointmentsDurationCode/'+ appoinmentDurationCode)
  }

  assignJob(appoinmentDurationCode:string , workerCode : string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobAssignedByAdmin/'+ appoinmentDurationCode +'/' + workerCode)
  }

  getDecliedAndLateListByOrganizationCode(organizationCode:string, status:string)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getDecliedAndLateListByOrganizationCode/'+ organizationCode +'/' + status)
  }

  // /adminGetEligibleStaff/{appointmentsCode}/{appointmentsProceduresCode}/{date}/{staffName}

  getEligibleStaffs(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaff/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date )
  }

  getEligibleStaffsSearch(appointmentsCode:string, appointmentsProceduresCode:string, date:string, staffName: any) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaff/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date +'/' +staffName )
  }

  jobAssignedByAdminJobCode(appointmentsCode:string, appointmentsProceduresCode:string, date:string, workerCode:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobAssignedByAdminJobCode/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date +'/' +workerCode )
  }

  getNotifiedUserList(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/v2/getNotifiedUserList/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date)
  }
 

  jobAssignedToManyStaffByAdmin(payload:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/jobAssignedToManyStaffByAdmin', payload)
  }

  saveClinicianCheckinDetail(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveClinicianCheckinDetailsAdminDashboard' , request_body)
  }
 
  adminGetEligibleStaffForNotify(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaffForNotify/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date )
  }

  createNewJob(request_body:any){
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/v2/createJob' ,request_body);
 
  }

  UnAssignStaff(appointmentsDurationCode:any)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobUnAssignedByAdmin/'+appointmentsDurationCode);
 
  }
 
  deleteJob(appointmentsCode:string, date:string, appointmentsProcedureCode:string,  organizationCode:string)
  {
    return this.http.delete(this._apiSVC.getHealthCareUrl() + `/deleteJob/${appointmentsCode}/${date}/${appointmentsProcedureCode}/${organizationCode}`);
 
  }
  
  getUnifornEquipmentFromLastShift(customerCode:string, customerAddressCode:string, organizationCode:string, organizationBaseProcedureCode:string)
  {
    return this.http.get(`${this._apiSVC.getHealthCareUrl()}/getUnifornEquipmentFromLastShift/${customerCode}/${customerAddressCode}/${organizationCode}/${organizationBaseProcedureCode}`);
  }


  getAllScheduledStaff(org_code:string, appointmentsCode:string, appointmentsProcedureCode:string, date:string, page:number, limit:number) {
    return this.http.get(`${this._apiSVC.getHealthCareUrl()}/v2/getAllScheduledStaffs/${org_code}/${appointmentsCode}/${appointmentsProcedureCode}/${date}?page=${page}&limit=${limit}`)
  }


  v3_getAllScheduledStaff(org_code:string, appointmentsCode:string, appointmentsProcedureCode:string, date:string) {
    return this.http.get(`${this._apiSVC.getHealthCareUrl()}/v3/getAllScheduledStaffs/${org_code}/${appointmentsCode}/${appointmentsProcedureCode}/${date}`)
  }


  reNotifyStaff(org_code:string, appointmentsNotifiedCode:string) {
    return this.http.get(`${this._apiSVC.getHealthCareUrl()}/reNotifyJobToStaff/${org_code}/${appointmentsNotifiedCode}`)
  }

  system_notify_all_staff(request_body:any){
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/sendSystemGeneratedNotifyToStaff' ,request_body);
 
  }


} 
