<div class="row mb-55 mt-0">
      <div class="row" >
    <div class="col-md-4 header-name pt-3">
      {{ 'main.labels.Job Site' | translate }}
    </div>
    <div class="col-md-4">
        <div class="w-100 p-0 m-0" *ngIf="isShortcutModalOpen">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
              <mat-label>Select Customer</mat-label>
              <mat-select placeholder="Select" (selectionChange)="changeCustomer($event)">
                <mat-option *ngFor="let customer of customerList" [value]="customer?.customerCode">
                  {{customer?.customerName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
           
          </div>
    </div>
    <div class="col-md-2 ms-auto p-2 text-end float-end">
      <div class="d-flex text-end float-end" *ngIf="!isShortcutModalOpen">
        <button mat-button (click)="cancelPopup()">
          {{ "Technicians.labels.Cancel" | translate }}
        </button>
      </div>
    </div>
  </div>

    <div class="col-md-5 col-sm-12 location-form">
      <div class="w-100">
        <form
        [formGroup]="siteAddress_Form"
        autocomplete="off">
        <mat-accordion>
      <ng-container
            formArrayName="addressList"
            *ngFor="let t of addressList.controls; let i = index; let isFirst = first" 
          >
            <mat-expansion-panel [expanded]="isFirst">
              <!-- <mat-expansion-panel-header
                (click)="
                  t.get('customerLatitude').value &&
                    t.get('customerLongitude').value &&
                    initialize(
                      i,
                      t.get('customerLatitude').value,
                      t.get('customerLongitude').value,
                      t.get('location').value
                    )
                "
              > -->

              <mat-expansion-panel-header
              (click)="initialize(
                    i,
                    t.get('customerLatitude').value,
                    t.get('customerLongitude').value,
                    t.get('location').value
                  )" 
                  >

                <mat-panel-title>
                  {{
                    t.get("siteName").value
                      ? t.get("siteName").value
                      : 'Site '+(i + 1)
                  }} 
                  {{(t.get("customerLatitude").value && t.get("customerLongitude").value) ? '('+t.get("customerLatitude").value + ', '+t.get("customerLongitude").value + ')'  : ''}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="w-100 pb-4">
                <div class="w-100" [formGroupName]="i">

                  <div class="col-12">
                    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width" >
                      <mat-label>Site Name</mat-label>
                      <input formControlName="siteName" matInput class="form-control" />
                    </mat-form-field>
                    <span class="help-block err"
                      *ngIf=" !t.get('siteName').valid &&
                        (isSiteForm_submitted || t.get('siteName').touched) " >Site Name is required</span >
                  </div>

                   
                    <div class="col-12">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="example-full-width p-0"
                  >
                    <!-- <mat-label>{{
                      "Technicians.labels.Location" | translate
                    }}</mat-label> -->
                    <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
                    <textarea
                      class="form-control"
                      matInput
                      placeholder="Enter {{ 'main.labels.Job Site' | translate }}"
                      formControlName="location"
                      ngx-google-places-autocomplete
                      [options]="options"
                      #placesRef="ngx-places"
                      (onAddressChange)="
                        handleAddressChange($event, i) " >
                      </textarea>
                  </mat-form-field>
                  <mat-error
                    class="err"
                    *ngIf="
                      t.get('location').errors?.required &&
                      (isSiteForm_submitted || t.get('location').touched)
                    "
                  >
                    <!-- {{
                      "Customers.validators.Location is required"
                        | translate
                    }} -->
                    {{ 'main.labels.Job Site' | translate }} is required
                    </mat-error
                  >
                  <mat-error
                    class="err"
                    *ngIf="
                      t.get('location').errors?.invalid &&
                      (isSiteForm_submitted || t.get('location').touched)
                    "
                  >
                    {{
                      "Customers.validators.Please enter valid address"
                        | translate
                    }}</mat-error
                  >
               </div>
               
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{
                          "Technicians.labels.Apt/Unit no"
                            | translate
                        }}</mat-label>
                        <input
                          formControlName="customerApartmentNo"
                          matInput
                          class="form-control"
                          placeholder=""
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          !t.get('customerApartmentNo').valid &&
                          (isSiteForm_submitted ||
                            t.get('customerApartmentNo').touched)
                        "
                        >{{
                          "Technicians.Validators.Apartment/Unit no is required"
                            | translate
                        }}</span
                      >
                    </div>
                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{
                          "Technicians.labels.Street Address"
                            | translate
                        }}</mat-label>
                        <input
                          formControlName="customerAddress"
                          matInput
                          class="form-control"
                          placeholder=""
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          !t.get('customerAddress').valid &&
                          (isSiteForm_submitted ||
                            t.get('customerAddress').touched)
                        "
                        >{{
                          "Customers.validators.Street address is required"
                            | translate
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="row">

                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{
                          "Technicians.labels.City" | translate
                        }}</mat-label>
                        <input
                          matInput
                          class="form-control"
                          minlength="4"
                          maxlength="50"
                          formControlName="customerCity"
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCity').errors?.required &&
                          (isSiteForm_submitted ||
                            t.get('customerCity').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.City is required"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCity').errors?.invalid &&
                          (isSiteForm_submitted ||
                            t.get('customerCity').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.City is required"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block"
                        *ngIf="
                          t.get('customerCity').errors?.minlength &&
                          (isSiteForm_submitted ||
                            t.get('customerCity').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.City minlength is 4"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block"
                        *ngIf="
                          t.get('customerCity').errors?.maxlength &&
                          (isSiteForm_submitted ||
                            t.get('customerCity').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.City maxlength is 50"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block"
                        *ngIf="
                          t.get('customerCity').errors?.pattern &&
                          (isSiteForm_submitted ||
                            t.get('customerCity').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.City must be letter or space and must
              have more than 4 characters" | translate
                        }}.
                      </span>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>State</mat-label>
                        <input
                          matInput
                          class="form-control"
                          minlength="2"
                          maxlength="50"
                          formControlName="customerState"
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerState').errors?.required &&
                          (isSiteForm_submitted ||
                            t.get('customerState').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.State is required"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerState').errors?.invalid &&
                          (isSiteForm_submitted ||
                            t.get('customerState').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.State is required"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerState').errors
                            ?.minlength &&
                          (isSiteForm_submitted ||
                            t.get('customerState').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.State minlength is 2"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerState').errors
                            ?.maxlength &&
                          (isSiteForm_submitted ||
                            t.get('customerState').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.State minlength is 50"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerState').errors?.pattern &&
                          (isSiteForm_submitted ||
                            t.get('customerState').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.State pattern must be letter and must
            have more than 2 characters" | translate
                        }}.
                      </span>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{
                          "Technicians.labels.Country" | translate
                        }}</mat-label>
                        <input
                          matInput
                          class="form-control"
                          minlength="2"
                          maxlength="50"
                          formControlName="customerCountry"
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCountry').errors
                            ?.required &&
                          (isSiteForm_submitted ||
                            t.get('customerCountry').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Country is required"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCountry').errors
                            ?.invalid &&
                          (isSiteForm_submitted ||
                            t.get('customerCountry').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Country is required"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCountry').errors
                            ?.minlength &&
                          (isSiteForm_submitted ||
                            t.get('customerCountry').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Country minlength is 2"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCountry').errors
                            ?.maxlength &&
                          (isSiteForm_submitted ||
                            t.get('customerCountry').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Country minlength is 50"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('customerCountry').errors
                            ?.pattern &&
                          (isSiteForm_submitted ||
                            t.get('customerCountry').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Postcode pattern must be letter or
              number or space and must have more than 4 characters"
                            | translate
                        }}.
                      </span>
                    </div>
                     
                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{
                          "Technicians.labels.Postcode" | translate
                        }}</mat-label>
                        <input
                          matInput
                          class="form-control"
                          minlength="5"
                          maxlength="10"
                          formControlName="zipCode"
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('zipCode').errors?.required &&
                          (isSiteForm_submitted || t.get('zipCode').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Postcode is required"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('zipCode').errors?.invalid &&
                          (isSiteForm_submitted || t.get('zipCode').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Postcode is required"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('zipCode').errors?.minlength &&
                          (isSiteForm_submitted || t.get('zipCode').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Postcode minlength is 5"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('zipCode').errors?.maxlength &&
                          (isSiteForm_submitted || t.get('zipCode').touched)
                        "
                      >
                        {{
                          "Technicians.Validators.Postcode maxlength is 10"
                            | translate
                        }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('zipCode').errors?.pattern &&
                          (isSiteForm_submitted || t.get('zipCode').touched)
                        "
                      >
                      {{ 'Technicians.Validators.Post code pattern must be number and hyphen (-) and must have more than 5 characters' | translate }}.
                      </span>
                    </div>
                    
                    <!-- <div class="col-md-12">
                      <mat-form-field  appearance="outline"  floatLabel="always"  class="example-full-width" >
                        <mat-label>Clock-In Geofence Radius(Meters)</mat-label>
                        <input  matInput class="form-control"  formControlName="siteRadius" (input)="geoFenceRadiousChange($event, i)"/>
                      </mat-form-field>
             
                    </div> -->

                    <div class="col-md-12 w-100">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Clock-In Mode</mat-label>
                        <mat-select formControlName="clockinMode" placeholder="select clock-in mode">
                          <mat-option value="">Select Clock-In Mode</mat-option>
                            <mat-option value="Location">Location </mat-option>
                            <mat-option value="Location with PIN">Location with PIN</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>

                    <div class="col-12 d-flex w-100">
                      <div class="w-85">
                        <!-- <input   matInput class="form-control"  formControlName="siteRadius" (input)="geoFenceRadiousChange($event, i, 'clockin')"/> -->
                       <mat-form-field appearance="outline" floatLabel="always">
                       <mat-label class="label">
                        Clock-In Geofence Radius ({{radius_unit_label}})
                      </mat-label>
                        
                        <input matInput numbersOnly type="number" class="form-control"  formControlName="siteRadius" (input)="clockINInputChange($event, i)"/>

                       <!-- <mat-slider class="w-75 slider-clockIn"
                        min="0" [max]="t.get('setClockInRadiusMaxValue').value" 
                        step="1" discrete >
                          <input matSliderThumb (dragEnd)="clockInDragEnd($event,i)" formControlName="siteRadius">
                        </mat-slider> -->

                        <!-- <span class="w-25  meter-lbl">
                          <label>Max Value</label>
                          <input type="number" class="w-100" formControlName="setClockInRadiusMaxValue" (input)="sliderSetMaxValue(i,'for clockin')">
                        </span> -->
                        <!-- <span class="w-25 meter-lbl">{{t.get('siteRadius').value ? t.get('siteRadius').value : 0}} / 5000</span> -->
                        </mat-form-field>

                     <span  class="help-block err" 
                       *ngIf="t.get('siteRadius').errors?.required && (isSiteForm_submitted || t.get('siteRadius').touched)">
                      Please set Clock-In radius
                    </span>
                    </div>

                    <div class="col-md-1"><span class="label-clockIn"></span></div>

                    </div>
                    

                    <div class="col-md-12 d-flex w-100"> 
                       <div class="w-85">
                      <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label class="label">Clock-Out Geofence Radius ({{radius_unit_label}})</mat-label>
                        
                        <input matInput numbersOnly type="number" class="form-control"  formControlName="siteRadiusClockout" (input)="clockOutInputChange($event, i)"/>
                        
                       <!-- <mat-slider class="w-75 slider-clockOut"
                        min="0" [max]="t.get('setClockOutRadiusMaxValue').value" 
                         step="1" discrete >
                        <input matSliderThumb (dragEnd)="clockOutDragEnd($event,i)" formControlName="siteRadiusClockout">
                      </mat-slider>
                      <span class="w-25 meter-lbl">
                        <label>Max Value</label>
                        <input type="number" class="w-100" formControlName="setClockOutRadiusMaxValue" (input)="sliderSetMaxValue(i,'for clockout')">
                      </span> -->
                    <!-- <span class="w-25 meter-lbl">{{t.get('siteRadiusClockout').value ? t.get('siteRadiusClockout').value : 0}} / 6000</span> -->
                    </mat-form-field>
                   <span  class="help-block err mt-2" 
                   *ngIf="t.get('siteRadiusClockout').errors?.required && (isSiteForm_submitted || t.get('siteRadiusClockout').touched)">
                  Please set Clock-Out radius
                </span>

                   <span  class="help-block err mt-2" 
                   *ngIf="t.get('siteRadiusClockout').errors?.radius && (isSiteForm_submitted || t.get('siteRadiusClockout').touched)">
                Should be longer than Clock-In geofence radius
                </span>

                      <!-- <mat-form-field  appearance="outline"  floatLabel="always"  class="example-full-width" >
                        <mat-label>Clock-Out Geofence Radius(Meters)</mat-label>
                        <input   matInput class="form-control"  formControlName="siteRadiusClockout" (input)="geoFenceRadiousChange($event, i, 'clockout')"/>
                      </mat-form-field> -->
                      <!-- <span  class="help-block err" 
                       *ngIf="t.get('siteRadiusClockout').errors?.radius && (isSiteForm_submitted || t.get('siteRadiusClockout').touched)">
                    Should be bigger than clock-in geofence radius
                    </span> -->

                    </div>

                    <div class="col-md-1"><span class="label-clockOut"></span></div>
                    </div>
 
                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{"Technicians.labels.Job Site Contact" | translate}}</mat-label>
                        <input
                          matInput
                          class="form-control"
                          minlength="2"
                          maxlength="50"
                          formControlName="siteContact"
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('siteContact').errors
                            ?.minlength &&
                          (isSiteForm_submitted ||
                            t.get('siteContact').touched)
                        "
                      >
                        {{"Technicians.Validators.Job Site Contact minlength is 2" | translate}}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('siteContact').errors
                            ?.maxlength &&
                          (isSiteForm_submitted ||
                            t.get('siteContact').touched)
                        "
                      >
                        {{"Technicians.Validators.Job Site Contact maxlength is 50" | translate }}
                      </span>
                    </div>
 

                    <div class="col-md-6">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width"
                      >
                        <mat-label>{{"Technicians.labels.Job Site Contact Ph" | translate}}</mat-label>
                        <input
                          matInput
                          class="form-control"
                          minlength="10"
                          maxlength="10"
                          formControlName="siteContactPhone"
                        />
                      </mat-form-field>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('siteContactPhone').errors
                            ?.minlength &&
                          (isSiteForm_submitted ||
                            t.get('siteContactPhone').touched)
                        "
                      >{{
                        "Technicians.Validators.Job Site Contact Ph minlength is 10"
                          | translate
                      }}
                      </span>
                      <span
                        class="help-block err"
                        *ngIf="
                          t.get('siteContactPhone').errors
                            ?.maxlength &&
                          (isSiteForm_submitted ||
                            t.get('siteContactPhone').touched)
                        "
                      >{{ 'main.labels.Job Site' | translate }} Contact Ph maxlength is 10
                      </span>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field  appearance="outline" floatLabel="always">
                        <mat-label>Special Instructions</mat-label>
                        <textarea matInput formControlName="specialInstructions" ></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="text-end">
                    <span class="headerNoteTitleBtn pe-3">
                      <span class="ps-4 cursor-pointer" (click)="allLocationList[i]?.noteList ? service.common.addUpdateNotes(allLocationList[i].noteList) : service.common.addUpdateNotes()">
                        <span class="notebtnContainer" *ngIf="t.get('customerAddressCode').value && allLocationList[i]?.noteList?.length > 0"><img class="p-1 notebtnIc" src="assets/images/note-icon-active.svg" /><span class="ps-1 noteBtnTxtActive">Note</span></span>
                        <span class="notebtnContainer" *ngIf="!t.get('customerAddressCode').value || allLocationList[i]?.noteList?.length === 0"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" /><span class="ps-1 noteBtnTxtInactive">Note</span></span>
                      </span>
                    </span>
                    <button  (click)="saveSiteAddress(i)" class="btn btn-success  me-2" > 
                    {{t.get('customerAddressCode').value ? 'Update' : 'Save'}}</button>
                    <button *ngIf="addressList?.controls?.length > 1" (click)="removeAddress(i)" class="btn btn-danger">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </form>
     
      </div>
      <div class="w-100 pt-3">
        <div
          class="btn btn-secondary float-end"
          (click)="!service.common.isOrganizationSuspend ? addAddress(null) : service.common.isOrganizationSuspendAlert()"
        >
          + Add {{ 'main.labels.Job Site' | translate }}
        </div>
      </div>
    </div>

    <div class="col-md-7 col-sm-12">
      <div class="border">
        <div
          id="map"
          #mapDis
          style="overflow: visible; height: 495px"
        ></div>
      </div>
    </div>
  </div>