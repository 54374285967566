import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-time-input-select',
  templateUrl: './time-input-dropdown.component.html',
  styleUrls: ['./time-input-dropdown.component.scss']
})
export class TimeInputSelect implements OnInit {

  @Input() startTime: FormControl = new FormControl();
  @Input() endTime: FormControl = new FormControl();

  startTime_lst : string [] = this._commonSVC.time12Hrs;
  endTime_lst   : string [] = this._commonSVC.time12Hrs;

  startTime_Filter: any = [] = this._commonSVC.time12Hrs;
  endTime_Filter: any = [] = this._commonSVC.time12Hrs;
  //endTime_TimeList: any = [] = this._commonSVC.time12Hrs;

  constructor(
    private _commonSVC : CommonService
  ){}


  ngOnInit(): void {
     this.subscribeOrgStartTime();
  }


  startTimeKeyUp_ForFilter(value: any) {
    this.startTime_Filter = this.startTime_lst.filter(ele => ele.toLowerCase().includes(value.toLowerCase()));
  }

  endTimeKeyUp_ForFilter(value: any) {
    this.endTime_Filter = this.endTime_lst.filter(ele => ele.toLowerCase().includes(value.toLowerCase()));
  }


  subscribeOrgStartTime()
  {
   this._commonSVC.org_startTime_Obs$.subscribe((res:string)=>{

    if(res)
    {
      const time_lst:string[] =  this._commonSVC.setEndTimeArray(res);
      if(time_lst.length > 0)
      {
        this.startTime_lst = [...time_lst];
        this.startTime_Filter = [...time_lst]
      }
    }
  });
  }

}
