<!-- <div *ngIf="date && time" class="w-auto d-flex"> -->
<div *ngIf="timer$ | async as timer" class="current-date-time-field primary-color">
  <div class="d-flex gap-2">
    <img src="assets/images/data-icon.svg">
    <div>{{timer.date }}&nbsp;&nbsp;</div>
  </div>

  <div class="d-flex">
    <div><img src="assets/images/time-icon.svg">&nbsp;&nbsp;</div>
    <div class="minW">{{timer.time}}</div>
  </div>
</div>