<div class="st-jobTypeDts-main-wrapper">

  <ng-template [ngIf]="shjobTypeDtsInput">
  <div class="w-100 d-flex">

      <div class="col jobDateCard">
          <div class="card-jobdate" [style.color]="shjobTypeDtsInput.jobTypeColor === '#212529' ? '#929292' : shjobTypeDtsInput.jobTypeColor">
            <span>{{shjobTypeDtsInput?.week_day}}</span>
            <span class="month-day">{{shjobTypeDtsInput?.month_day}}</span>
            <!-- <span>{{shjobTypeDtsInput?.month_name}}</span>               -->
          </div>
      </div>

      <ng-template [ngIf]="shjobTypeDtsInput?.customerName">
      <div class="col ps-3 boxSpace">
         <small>Customer Name </small><br>
              <p class="m-0">
                <span>
                  <profile-avatar-with-name [userDetail]="shjobTypeDtsInput" [isRowNameWithLogo]="false" [popoverShowFor]="'customer'" [isProfileButtonEnable]="true" [isChatButtonEnable]="false"></profile-avatar-with-name>
               </span>
              </p>
      </div>
    </ng-template>

      <div class="col boxSpace">
         <small>{{ 'main.labels.Job Site' | translate }} </small><br>
          <p class="m-0" [title]="shjobTypeDtsInput.siteAddress ? shjobTypeDtsInput.siteAddress : shjobTypeDtsInput.siteName"> {{shjobTypeDtsInput?.siteName}}</p>
       </div>

      <div class="col boxSpace">
        <div class="d-flex">
          <div class="col p-0 m_width_50">
            <div class="w-100">
              <img class="img_jobtype"
                [src]="shjobTypeDtsInput.jobTypeIcon ? img_serverPath+shjobTypeDtsInput.jobTypeIcon : 'assets/common_images/profile.jpg'"
                onerror="this.src='assets/common_images/profile.jpg';">
            </div>
          </div>
           <div class="col ps-2">
             <small>Job Type </small><br>
              <p class="m-0">{{shjobTypeDtsInput?.jobTypeName}}</p>
           </div>
        </div>
      </div>

      <ng-template [ngIf]="shjobTypeDtsInput.shift">
        <div class="col boxSpace">
             <small>Shift </small><br>
            <p class="m-0">{{shjobTypeDtsInput?.shift}}</p>
         </div>
      </ng-template>

      <ng-template [ngIf]="userType != 'Customer'"> 
      <ng-template [ngIf]="shjobTypeDtsInput.rateCard">
        <div class="col boxSpace">
            <small>Billing Rate/Hr </small><br>
            <p class="m-0"> $&nbsp;{{shjobTypeDtsInput?.rateCard}}</p>
         </div>
      </ng-template>
      </ng-template>
  
    </div>
  </ng-template>

  <!-- <ng-content select="[shiftDetails]"></ng-content> -->

</div>