import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { Component, ViewChild, Input, OnInit, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { SessionStorageService } from 'angular-web-storage';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ConfirmLogoutComponent } from '../confirm-logout/confirm-logout.component';
import { TranslateService } from '@ngx-translate/core';
 import { ChatComponent } from 'src/app/dashboards/providers-dashboard/chat/chat.component';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { LoginService } from 'src/app/authentication/login/login.service';
//import { ValidateRouteService } from 'src/app/shared/services/validate-route.service';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { SOCKET_TOPICS } from 'src/app/shared/enum/socket-topics.enum';
 

@Component({
  selector: 'app-my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.scss']
})
export class MyNavComponent implements OnInit, OnChanges, OnDestroy {

  private isMobile:boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => {
        // //console.log('isHandset: ', result);
        if(result){
          result.matches = false;
          return result.matches;
        }
        })
    );

  screenWidth: number;
  name;
  img;
  imageShow;
  userType;
  @Input() toggleMenuVal: boolean;
  @Input() isMobileMenuOpen: boolean;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  isActiveMenu: any;
  accordionExpand = {};
  userCode:string;
  MenuListDataAndPagePermission: any;
  baseURL: any;
  public show: boolean = false;
  public buttonName: any = 'Show';
  private $destroyer = new Subject<void>();

  expiredDocsCount : number | null = null ;

  menuFor : string = 'admin'; 

  @Output() evt_mobileCloseMenu = new EventEmitter();

  private routerSubscription: Subscription;
  
  constructor(
    private breakpointObserver: BreakpointObserver, 
    public router: Router,
     public auth: AuthService,
     public session: SessionStorageService,
      public service: ApiRootService, 
     public dialog: MatDialog,
      public common: CommonService,
    private translate: TranslateService,
     public http: HttpClient,
    private loginService: LoginService,
    private _commonApiService : CommonApiService
    //private _validateRouteSVC : ValidateRouteService
    
  ) {
    this.translate.setDefaultLang(this.session.get('language'));
    // //console.log(this.common.organizationStatus);
    this.baseURL = service.getSuperAdmin() + "/downloadFile/";
    // this.baseURL = "assets/images/";
  }
 

  ngOnInit() {

    // this.getMenus();

    this.MenuListDataAndPagePermission = this.session.get('MenuListDataAndPagePermission');

    if (this.session.get('log_type') == 'Worker' || this.session.get('log_type') == 'Admin') {

      this.name = this.session.get('username');
    } else {
      this.name = this.session.get('orgName');
    }
    this.auth.common.globalImage = this.session.get('image');
  //  this.img = this.auth.common.globalImage;
    if (this.auth.common.globalImage != null) {
     // this.imageShow = this.service.getSuperAdmin() + '/downloadFile/' + this.img;
      this.auth.common.globalImageUrl = this.service.getSuperAdmin() + '/downloadFile/' + this.auth.common.globalImage;

    }

    this.userType = this.session.get('user_type');

    this.userCode = this.session.get('userCode');

   

    // //console.log('globalImageurl' ,this.auth.common.globalImageUrl);     

/* Name:Shailesh
      use: for getting active menus */
    try {
      this.getGlobalSuperAdminSettingBySettingName('Simulator.Menu', (response) => {
      // //console.log(response);
      if (response.responsecode == 200) {
        this.isActiveMenu = response.data.settingValue;
      } else {
        this.isActiveMenu = "false";
      }
      // //console.log(this.isActiveMenu);
    }) 
    } catch (error) {
      //console.log(error)
    }  
    // //console.log('MenuListDataAndPagePermission: ', this.MenuListDataAndPagePermission);
   this.subscribe_rolePermissionChanged_socket();
   this.subscribe_organizationSuspendStatus_socket();
   this.getStaffExpiredDocx();
   this.subscribeRouterNavigationStart();

 
  this.breakpointObserver.observe(Breakpoints.Handset).subscribe(res => this.isMobile = res.matches);

  this.menuFor = this.isMenuFor();

  }
  
  chatOpen()
  {
 
    const dialogRef = this.dialog.open(ChatComponent, {
      // width: '66%',
      panelClass: 'chat-popup', 
    });

  }
  
  /* Name:Shailesh
      use: for getting global setting */
      
  getGlobalSuperAdminSettingBySettingName(name, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getGlobalSuperAdminSettingBySettingName/' + name).subscribe((data) => {
      callback(data);
    })
  }

  ngOnChanges(event) {
    ////console.log(event);
   // this.sidenav.toggle();
  }

  /* Name:Shailesh
      use: to logout from application */
  logout() {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '320px',
      data: { msg: 'Are you sure you want to logout?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.auth.logout();
      }
    });
  }

  logoClicked()
  {
    if(this.session.get('user_type') === 'Staff')
    {
      this.router.navigate(['/dashboard-technician'])
    }
    else
    {
      let isPermissionForDashboar_View  = this.common.getBtnEventPermissions("Dashboard");

        if(isPermissionForDashboar_View.viewPermission === true)
        {
            if(this.session.get('user_type') === 'Customer')
            {
            this.router.navigate(['/dashboard-customer'])
            }
            else{
              this.router.navigate(['/dashboard'])
             }

        }
    }
  }




  subscribe_rolePermissionChanged_socket()
  {
    let topic;
//console.log("topic permission subsss .....")
    if(this.session.get("user_type") !== "Staff" && this.session.get("user_type") !== "Customer" )
      {
        topic = "PagePermissionSocketForOrg_" + this.session.get("userCode") ;
      }
      else{
        if(this.session.get("user_type") === "Customer")
          {
            topic = "PagePermissionSocketForCustomer_"+ this.session.get("userCode") ;
          }
      }

     // console.log("topic permission subsss .....", topic)
    
      if(topic)
        {
          this.common.webSocketSubcribeTopic(topic)
          .pipe(takeUntil(this.$destroyer))
          .subscribe((res: any) => {
             console.log("role permission socket for Admin, socket.body)", res.body);
            if (res.body) {
              this.getMenus();
            }
          });
        }

   
  }


  subscribe_organizationSuspendStatus_socket()
  {
    let topic;
//console.log("topic permission subsss .....")
    if(this.session.get("user_type") !== "Staff" && this.session.get("user_type") !== "Customer" )
      {
        topic = "OrganizationSuspendStatus_" + this.session.get("orgCode") ;
      }
      else{
        if(this.session.get("user_type") === "Customer")
          {
            // topic = "OrganizationSuspendStatus_"+ this.session.get("userCode") ;
          }
      }

     // console.log("topic permission subsss .....", topic)
    
      if(topic)
        {
          this.common.webSocketSubcribeTopic(topic)
          .pipe(takeUntil(this.$destroyer))
          .subscribe((res: any) => {
            //  console.log("Organization Suspend Status: ", res.body);
            if (res.body && (res.body === true || res.body === 'true')) {
              this.common.isOrganizationSuspend = true;
              this.session.set('isOrganizationSuspend', true);
            } else {
              this.common.isOrganizationSuspend = false;
              this.session.set('isOrganizationSuspend', false);
            }
          });
        }

   
  }

  
  
  ngOnDestroy(): void {
    this.$destroyer.next();
    this.$destroyer.complete();
  }

 // call this fuction only when getting socket data for permission changed
  getMenus()
  {
    this.loginService.getAllPagePermissionDetailsByUserCode(this.userCode, (res: IPagePermissionResponse) => {
      if(res.responsecode === 200)
        {
          this.session.set('MenuListDataAndPagePermission', res.data);
         
          this.MenuListDataAndPagePermission = res.data ;
          this.common.setPermission(res);  // EMMIT VALUES TO ALL SUBSCRIBER


          /*
          start from here
           this code checking whether user have permsission to view current active page
          */

          // this._validateRouteSVC.resetRouteCheck(); // RESETTING VALID ROUTE STATE
          // this._validateRouteSVC.setValidRoutes();  // SETTING AUTHORISED ROUTES AGAIN
           
          // setTimeout(()=>{

          //   // if do not have ""view"" access to current active page OR url  
          //   if(this._validateRouteSVC.has_viewPermission(this.router.url.split("?")[0]))
          //     {
          //       this.MenuListDataAndPagePermission = res.data ;
          //       this.common.setPermission(res);  // EMMIT VALUES TO ALL SUBSCRIBER
          //     }
          //     else{
                
          //       this.common.loginmessage = "You do not have permission to view this page";
          //       this.common.loginerrorMessage = true;
          //       this.auth.logout();
          //     }
          // },1000)

          /* Ends here  */

         }
    });
  }


  getStaffExpiredDocx()
  {
    if(this.session.get("user_type") !== "Staff" && this.session.get("user_type") !== "Customer" )
      {
        this.staff_getExpiredDocCounts();
        this.socket_StaffExpiredDocCounts();
      }
  }
 
  staff_getExpiredDocCounts()
  {
     this._commonApiService.getExpiredDocCountsByOrgCode(this.session.get("orgCode")).subscribe((res:any)=>{
        // console.log("expired docs count", res);
         this.expiredDocsCount = res?.count || null ;
       })
   }
  
  socket_StaffExpiredDocCounts()
  {
   
    this.common.webSocketSubcribeTopic(`${SOCKET_TOPICS.SKILL_CERTIFICATE_EXPIRED_COUNT}${this.session.get('orgCode')}`)
    .pipe(takeUntil(this.$destroyer))
    .subscribe((res:any) =>
    {
       if(res?.body)  this.staff_getExpiredDocCounts();
     
    });
  }


  closeMobMenu()
  {
    this.evt_mobileCloseMenu.emit(true);
  }

/**
 * closing the side menu on mobile view, if user navigates from sidemenu
 */
  subscribeRouterNavigationStart()
  {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if(this.isMobile && this.isMobileMenuOpen)
        {
          this.closeMobMenu();
        }
      }
  })
}

isMenuFor() : string 
{
  const userType : string = this.userType ? this.userType : this.session.get('user_type');

  if(this.session.get("isNewStaff") && (this.session.get("isNewStaff") == 'true' || this.session.get("isNewStaff") == true))
  {
    return 'newStaff';
  }
  else if (userType == 'Staff' && !this.session.get("isNewStaff"))
  {
    return 'Staff'
  }
  else if(userType == 'Customer')
  {
    return 'Customer'
  }
  else
  {
    return 'Admin'
  }

}

}





