
<div class="p-0 notification-header">
          
    <span *ngIf="notificationsList.count" class="position-absolute translate-middle badge rounded-pill bg-danger ng-star-inserted">{{notificationsList.count}}</span>
    <i [matMenuTriggerFor]="beforeMenu"  class="material-icons cursor-pointer mt-1 me-1" title="Notifications">
      notifications_none
    </i>
    <!-- <span *ngIf="_commonSVC.notificationList?.count" class="notify-badge">{{_commonSVC.notificationList?.count}}</span> -->
    <mat-menu #beforeMenu="matMenu" xPosition="before" class="dropdown-width">
    <div class="container notification-body">
      <div class="row">
        <div class="row">
         <div class="col-lg-7 dialog-header">
          Notification {{notificationsList.count ? '('+notificationsList.count+')' : ''}}
         </div>
         <div class="col-lg-5 text-end">
          <a *ngIf="notificationsList.data.length > 0" mat-flat-button (click)="navigate()">View All</a>
         </div>
        </div>
       <ng-template [ngIf]="notificationsList.data.length > 0" [ngIfElse]="noNotification">
         <ul>
          <li *ngFor="let notification of notificationsList.data">
           <div class="row">

            <div class="row">
              <div class="col-lg-7">
                <span>
                  {{notification.shortMessage}}
                </span>
              </div>
              <div class="col-lg-5 text-end">
             <small>{{notification.notificationDate}}</small>
             </div>
            </div>

            <div class="col-lg-12">
              <small>
                {{notification.description}}
              </small>
            </div>
           </div>
          </li>
         </ul>
       </ng-template>

       <ng-template #noNotification>
      <div class="row">
        <div class="text-center text-danger">
          
          <mat-icon>notifications_off</mat-icon>
          <br>
          <span>No notifications are present.</span>
        </div>
      </div>
       </ng-template>

      </div>
    </div>
   </mat-menu>
  </div>