import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, takeUntil } from 'rxjs';
import { CommonApiService } from 'src/app/shared/services/common-apiService';

@Component({
  selector: 'staff-list-multi-select',
  templateUrl: './staff-list-multi-select.component.html',
  styleUrls: ['./staff-list-multi-select.component.scss']
})

export class StaffListMultiSelectComponent {

 
  @Input() selected_staff_code : string[] = [] ; // use this code to show selected staff in dropdown
  @Input() disabled : boolean = false ;
  @Input() placeholder : string = 'Select Staff';
 

  @Output() selectionChanged  = new EventEmitter() ;

/*
 * @Input() staffList:any[] = []; 
 * using this variable as staff list array list ,
 * To avoid multiple api calling when using this component for form array or other iteration part
 */
  @Input() staffList:any[] = []; 


  @Input() ctrl_staff : FormControl = new FormControl(); ;
 
  staffSeachInput: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();

   staffList_Filter:any[] =[];

   @Input() autoFocusDropDown : boolean = false ;
   //@ViewChild("staffList") staffList 
  // @ViewChild("staffListRef") staffListSelectRef: MatSelect;

   constructor(
    private common_api_service : CommonApiService,
    private session_service : SessionStorageService
   ){  }

 

  ngOnInit(): void {

    if(this.staffList.length > 0)
    {
      // this stafflist will come from parent
      this.staffList_Filter = [...this.staffList] ;
       this.bindDropDown();
       this.formValueChangedSubs();
    }
 
    else
    {
      this.getAllStaffMemberByOrgCode();
    }

    if(this.disabled)
    {
    this.ctrl_staff.disable();
    }
     
  }


  bindDropDown()
  {
    if(this.selected_staff_code.length > 0 && this.staffList.length >0 && this.staffList_Filter.length > 0)
      {
 
        const selected_value:string[] = this.staffList_Filter.filter(ele=> this.selected_staff_code.includes(ele.staffCode));
 
        if(selected_value.length > 0)
          this.ctrl_staff.setValue(selected_value) ;

      }

  }

   getAllStaffMemberByOrgCode()
   {
     this.common_api_service.get_all_staff_by_org_code(this.session_service.get('orgCode')).subscribe((response:any) =>{
         
           if (response.responsecode == 200) 
             {
              this.staffList = response.data;
              this.staffList_Filter = response.data;
              this.bindDropDown();
              this.formValueChangedSubs();
             } 
       });
   }


   formValueChangedSubs()
   {
     this.staffSeachInput.valueChanges
     .pipe(takeUntil(this._onDestroy))
     .subscribe(() => {
       this.filterCustomer();
     });
   }
 
   private filterCustomer() {
     if (this.staffList.length == 0) {
       return;
     }
     let search = this.staffSeachInput.value.trim();
 
     this.staffList_Filter = this.staffList.filter((ele) =>
       ele.name.toLowerCase().includes(search.toLowerCase())
     );
   }

   onSelectionChanged(event:MatSelectChange)
   {
    // if(event.value && event.value?.length > 0)
    // {
      //const staffCode = event.value.map(ele=> ele.staffCode) || [] ;
      this.selectionChanged.emit(event.value);
   // }
    
   }

  ngOnDestroy(): void {
   this._onDestroy.next();
   this._onDestroy.complete();
  }



}

 
