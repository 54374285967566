import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, takeUntil } from 'rxjs';
import { DistributionService } from 'src/app/components/distribution-list/distribution-service';
import { CommonService } from 'src/app/shared/common.service';
import { IApiResponse, INotifyCriteria } from 'src/app/shared/interface/core.interface';

@Component({
  selector: 'app-notify-criteria-form',
  templateUrl: './notify-criteria-form.component.html',
  styleUrls: ['./notify-criteria-form.component.scss']
})
export class NotifyCriteriaFormComponent implements OnInit, OnDestroy {

  notifyCriteriaForm : FormGroup;
  distributionList:any[] = [] ;
  filteredDistributions :any [] = [] ;
  distributionSeachInput: FormControl = new FormControl();
  private _onDestroy = new Subject<string>();
  //selectedStaffCodes:string[] = [];

constructor(
 public dialogRef: MatDialogRef<NotifyCriteriaFormComponent>,
 private sessionService : SessionStorageService,
 private distributionService :DistributionService,
 private _commonSVC : CommonService,
@Inject(MAT_DIALOG_DATA) public formControlsValue: INotifyCriteria,
){}

ngOnInit(): void {
  this.InitializeForm();
  this.getAllDistributionList();
  this.setFormValues();
}


 InitializeForm = ()=>{
  this.notifyCriteriaForm = new FormGroup({
    notify_radius : new FormControl(null,[Validators.pattern(this._commonSVC.numberDecimal)]),
    distribution : new FormControl([]),
    to_be_notify : new FormControl(null,[Validators.pattern(this._commonSVC.onlyDigits)]),
    self_signup : new FormControl(null),
    ctrl_staff : new FormControl([])
  })
}


closeModal()
{
  this.dialogRef.close("");
}


save()
{
  if(this.notifyCriteriaForm.invalid) return ;

  const data:INotifyCriteria = {
    distance: this.notifyCriteriaForm.controls.notify_radius.value
              ? parseInt(this.notifyCriteriaForm.controls.notify_radius.value)
              : null,

    numberOfNotify: this.notifyCriteriaForm.controls.to_be_notify.value
                    ? parseInt(this.notifyCriteriaForm.controls.to_be_notify.value)
                    : null,
   
    distributionCodeList: this.notifyCriteriaForm.controls.distribution.value,
    selfSignUp: this.notifyCriteriaForm.controls.self_signup.value,
    staffCodeList : this.notifyCriteriaForm.controls.ctrl_staff.value?.length > 0
                  ? this.notifyCriteriaForm.controls.ctrl_staff.value
                  : null
  }

  this.dialogRef.close(data);
}


  getAllDistributionList() {
    this.distributionService.getListDataByPage(
      this.sessionService.get('orgCode'),
      0,
      1000,
      (response: IApiResponse) => {
        if (response.responsecode == 200)
        {
          this.distributionList = response.data;
          this.filteredDistributions = response.data;
           this.distributionSeachInput.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                      this.filterDistribution();
                    });
        }
         else
        {
          this.distributionList = [];
          this.filteredDistributions = [];
        }
      
      });
  }


  protected filterDistribution() {
    if (this.distributionList.length == 0) {
      return;
    }
    let search = this.distributionSeachInput.value.trim();
    this.filteredDistributions = this.distributionList.filter(ele => ele.distributionName.toLowerCase().includes(search.toLowerCase()));
  }

 ngOnDestroy(): void {
   this._onDestroy.next('');
   this._onDestroy.complete();
 }

 getSelectedStaff(event:any)
 {
   this.notifyCriteriaForm.controls.ctrl_staff.setValue(event);

   const distributionList:string[] = this.notifyCriteriaForm.controls.distribution.value && this.notifyCriteriaForm.controls.distribution.value?.length > 0
                    ? this.notifyCriteriaForm.controls.distribution.value
                    : []

                    if(distributionList.length > 0)
                    {
                      this.notifyCriteriaForm.controls.distribution.setValue([]);
                    }
 }


 setFormValues()
 {
 
  if(this.formControlsValue)
  {
    this.notifyCriteriaForm.patchValue({
      notify_radius : this.formControlsValue.distance,
      distribution : this.formControlsValue.distributionCodeList,
      to_be_notify : this.formControlsValue.numberOfNotify,
      self_signup : null,
      ctrl_staff :  this.formControlsValue.staffCodeList?.length > 0 ? this.formControlsValue.staffCodeList : []
    });

   // this.selectedStaffCodes = this.formControlsValue.staffCodeList?.length > 0 ? this.formControlsValue.staffCodeList : [];

  }
 }


 onDistributionChanged(event:MatSelectChange)
 {
  const staffList:string[] = this.notifyCriteriaForm.controls.ctrl_staff.value && this.notifyCriteriaForm.controls.ctrl_staff.value?.length > 0
                    ? this.notifyCriteriaForm.controls.ctrl_staff.value
                    : []

                    if(staffList.length > 0)
                    {
                      this.notifyCriteriaForm.controls.ctrl_staff.setValue([]);
                     // this.selectedStaffCodes = [];
                    }

 }

 

}
